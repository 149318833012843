import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
    TableHead,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button
} from "@mui/material";
import {AddRounded, Visibility} from "@mui/icons-material";

import api from "~/api";
import {MainPage, Navbar, LoadingBar} from "~/components";
import {useDialog} from "~/providers/dialog";
import {formatDateTime} from "~/utils/date";

const GroupList = () => {
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const showDialog = useDialog();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await api.groups.list();
                setGroups(data);
            } catch (e) {
                showDialog("Não foi possível obter os grupos.", e);
            }
            setLoading(false);
        };
        fetchUsers().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <MainPage>
        {
            loading ? <LoadingBar/> :
                <>
                    <Navbar title="Grupos">
                        <Button component={Link} to={"/groups/new"} variant={"outlined"} color={"white"}>
                            <AddRounded sx={{mr: 1}}/>
                            Novo grupo
                        </Button>
                    </Navbar>
                    <TableContainer>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Data de criação</TableCell>
                                    <TableCell align="right">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groups.map((user) => (
                                    <TableRow
                                        key={user.uuid}
                                        sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                    >
                                        <TableCell>{user.name}</TableCell>
                                        <TableCell>{formatDateTime(user.createdAt)}</TableCell>
                                        <TableCell align="right">
                                            <Button startIcon={<Visibility/>} component={Link} to={`/groups/${user.uuid}`} variant="outlined">Editar</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
        }</MainPage>;
};
export default GroupList;