// ProtectedRoute.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "~/providers/user/useUser"; // Import your custom hook

const ProtectedRoute = ({ element, children }) => {
    const [user] = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate("/login");
        }
    }, [user, navigate]);

    return user ? (element || children) : null;
};




export default ProtectedRoute;