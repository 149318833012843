import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import lodash from "lodash";
import { Box, Button, CircularProgress } from "@mui/material";

import api from "~/api";
import { Navbar, MainPage, LoadingBar } from "~/components";
import { useDialog, useToast } from "~/providers/dialog";
import FormViewer from "./FormViewer";
import { Download } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";

const FormsEdit = () => {
    const { uuid } = useParams();
    const [form, setForm] = useState(null);
    const [formLocal, setFormLocal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const showDialog = useDialog();
    const showToast = useToast();

    useEffect(() => {
        if (uuid) {
            const loadData = async () => {
                setLoading(true);
                try {
                    const data = await api.forms.detail(uuid);
                    setForm(data);
                } catch (e) {
                    showDialog("Não foi possível carregar os formulários.", e);
                }
                setLoading(false);
            };
            loadData().then();
        }
    }, [uuid]);

    useEffect(() => {
        if (form) {
            setFormLocal(form);
        }
    }, [form]);
    useEffect(() => {
        if (form && formLocal) {
            setHasChanges(!lodash.isEqual(form, formLocal));
        }
    }, [form, formLocal]);

    const handleChange = (form) => {
        setFormLocal(form);
    };

    const checkForm = (items, parentLabel, itemsWithError) => {
        for (const item of items) {
            if (item.label === null || item.label.trim() === "") {
                itemsWithError.push(
                    ` ${
                        item.type === "page"
                            ? "Nome da página é obrigatório"
                            : `${parentLabel}: Nome do campo é obrigatório`
                    }`
                );
            }
            if (item.min > item.max) {
                itemsWithError.push(
                    `${parentLabel}: Mínimo não pode ser maior que o máximo`
                );
            }

            if (item.items && item.items.length > 0) {
                checkForm(item.items, item.label, itemsWithError);
            }
        }
        return itemsWithError;
    };

    const handleSave = async () => {
        if (hasChanges) {
            const checkedForms = await checkForm(formLocal.items, null, []);
            if (checkedForms.length > 0) {
                const errors = checkedForms.join("\n");
                showDialog("Não foi possível salvar o formulário.", errors);
                return;
            }
            setSaveLoading(true);
            try {
                const data = await api.forms.update(uuid, formLocal);
                setForm(data);
                showToast("Formulário atualizado com sucesso!");
            } catch (e) {
                showDialog("Não foi possível salvar o formulário.", e);
            }
            setSaveLoading(false);
        }
    };

    const handleExport = async () => {
        let uuidMap = {};

        function clearedForm(obj) {
            if (obj instanceof Array) {
                return obj.map((item) => clearedForm(item));
            } else if (obj instanceof Object) {
                const newObj = {};
                Object.keys(obj).forEach((key) => {
                    if (key.toLowerCase().endsWith("uuid")) {
                        if (!uuidMap[obj[key]]) {
                            uuidMap[obj[key]] = uuidv4();
                        }
                        newObj[key] = uuidMap[obj[key]];
                    } else if (
                        key !== "createdAt" &&
            key !== "updatedAt" &&
            key !== "deletedAt"
                    ) {
                        newObj[key] = clearedForm(obj[key]);
                    }
                });
                return newObj;
            }
            return obj;
        }

        try {
            let clearForm = clearedForm(form);
            clearForm = {
                ...clearForm,
                voxit_version: "1.0",
            };

            const blob = new Blob([JSON.stringify(clearForm)], {
                type: "application/json",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = `${formLocal.title}.voxit.json`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <MainPage>
            {loading && <LoadingBar />}
            {!loading && formLocal && (
                <>
                    <Navbar
                        title={formLocal.title}
                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }}
                    >
                        <Button
                            ml={{ ml: 1 }}
                            variant={"contained"}
                            color={"info"}
                            onClick={handleExport}
                        >
              Exportar
                            <Download sx={{ ml: 1 }} />
                        </Button>
                        <Button
                            sx={{ ml: 2 }}
                            disabled={!hasChanges}
                            onClick={handleSave}
                            variant={"contained"}
                            color={"info"}
                        >
              Salvar{" "}
                            {saveLoading ? (
                                <CircularProgress sx={{ color: "#FFFFFF", ml: 2 }} size={30} />
                            ) : (
                                ""
                            )}
                        </Button>
                    </Navbar>
                    <Box sx={{ display: "flex", height: "100%" }}>
                        <FormViewer form={formLocal} onChange={handleChange} />
                    </Box>
                </>
            )}
        </MainPage>
    );
};

export default FormsEdit;
