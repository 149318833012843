import React from "react";
import {FormControl, TextField} from "@mui/material";
import InputMask from "react-input-mask";

export function RenderHour({ onChange, param, parameters}) {
    const startsWithTwo = (parameters[param.key] || "")[0] === "2";
    const mask = startsWithTwo ? "23:59" : "29:59";
    return <FormControl fullWidth>
        <InputMask
            onChange={onChange}
            value={parameters[param.key] || ""}
            formatChars={{
                "9": "[0-9]",
                "5": "[0-5]",
                "3": "[0-3]",
                "2": "[0-2]"
            }}
            mask={mask}>
            {() => <TextField
                name={param.key}
                multiline={param.type === "multiline_text"}
                minRows={5}
                label={param.label}
                size={"small"}
                type="text"/>}
        </InputMask>
    </FormControl>;
}
