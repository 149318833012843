import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from "@mui/material";

import {AddRounded, Visibility} from "@mui/icons-material";

import api from "~/api";
import {Navbar, LoadingBar, MainPage} from "~/components";
import {useDialog} from "~/providers/dialog";
import {formatDateTime} from "~/utils/date";

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const showDialog = useDialog();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await api.users.list();
                setUsers(data);
            } catch (e) {
                showDialog("Não foi possível obter os usuários.", e);
            }
            setLoading(false);
        };
        fetchUsers().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <MainPage>
        {
            loading ? <LoadingBar/> :
                <>
                    <Navbar title="Usuários">
                        <Button component={Link} to={"/users/new"} variant={"outlined"} color={"white"}>
                            <AddRounded sx={{mr: 1}}/>
                            Novo usuário
                        </Button>
                    </Navbar>
                    <TableContainer>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>E-mail</TableCell>
                                    <TableCell>Último acesso</TableCell>
                                    <TableCell>Data de criação</TableCell>
                                    <TableCell align="right">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user) => (
                                    <TableRow
                                        key={user.uuid}
                                        sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                    >
                                        <TableCell>{user.firstName} {user.lastName}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{formatDateTime(user.lastLoginAt, " - ")}</TableCell>
                                        <TableCell>{formatDateTime(user.createdAt)}</TableCell>
                                        <TableCell align="right">
                                            <Button startIcon={<Visibility/>} component={Link} to={`/users/${user.uuid}`} variant="outlined">Editar</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
        }</MainPage>;
};
export default UserList;