import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from "@mui/material";

import {Visibility} from "@mui/icons-material";

import api from "~/api";
import {Navbar, LoadingBar, MainPage} from "~/components";
import {useDialog} from "~/providers/dialog";
import {formatDateTime} from "~/utils/date";

const PendingTasks = () => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const showDialog = useDialog();

    useEffect(() => {
        const fetchTasks = async () => {
            setLoading(true);
            try {
                const data = await api.pendingTasks.list();
                setTasks(data);
            } catch (e) {
                showDialog("Não foi possível carregar as tarefas pendentes.", e);
            }
            setLoading(false);
        };
        fetchTasks().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return <MainPage>
        {
            !loading ? <>
                <Navbar title="Tarefas Pendentes"/>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Workflow</TableCell>
                                <TableCell>Tarefa</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell align="right">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                tasks.map(task =>
                                    <TableRow
                                        key={task.uuid}
                                        sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                        <TableCell>{task.workflowExecutionStep.workflowStep.workflow.title}</TableCell>
                                        <TableCell>{task.name}</TableCell>
                                        <TableCell>{formatDateTime(task.createdAt)}</TableCell>
                                        <TableCell align="right">
                                            <Button
                                                startIcon={<Visibility/>}
                                                component={Link}
                                                to={`/workflows/${task.workflowExecutionStep.workflowStep.workflow.uuid}/executions/${task.workflowExecutionStep.execution.uuid}/steps/${task.workflowExecutionStep.uuid}`}
                                                variant="outlined">Visualizar</Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </> : <LoadingBar/>
        }
    </MainPage>;
};
export default PendingTasks;