import BabelNode from "./Nodes/BabelNode";
import BabelEdge from "./Nodes/BabelEdge";
import ReactFlow, {Background, Controls, MiniMap, useOnSelectionChange, useReactFlow, useUpdateNodeInternals} from "reactflow";
import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import "reactflow/dist/base.css";
import MultiDestinationNode from "./Nodes/MultiDestinationNode";
import NoSourceNode from "./Nodes/NoSourceNode";

const nodeTypes = {
    default: BabelNode,
    multi_destination: MultiDestinationNode,
    no_source: NoSourceNode,
};
const edgeTypes = {
    default: BabelEdge
};

const ReactFlowWrapper = forwardRef(({nodes, edges, onNodesChange, onEdgesChange, onConnect, onSelectionChange, onDrop, onDragOver, onInit}, ref) => {
    const {fitView} = useReactFlow();
    const updateNodeInternals = useUpdateNodeInternals();

    useEffect(() => {
        fitView({duration: 400});
    }, [nodes, fitView]);

    useOnSelectionChange({
        onChange: onSelectionChange
    });
    useImperativeHandle(ref, () => ({
        updateNodeInternals(nodeId) {
            updateNodeInternals(nodeId);
        }
    }));

    return <ReactFlow
        nodes={nodes}
        proOptions={{hideAttribution: true}}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onConnect={onConnect}
        onInit={onInit}
        onDrop={onDrop}
        onDragOver={onDragOver}
        fitView>
        <MiniMap pannable={true}/>
        <Controls showInteractive={false}/>
        <Background/>
    </ReactFlow>;
});

export default ReactFlowWrapper;