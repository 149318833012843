import React from "react";
import {ListItem, Tooltip} from "@mui/material";
import {InfoRounded} from "@mui/icons-material";


import {RenderText} from "./renderText";
import {RenderSelect} from "./renderSelect";
import {RenderButtonWithResult} from "./renderButtonWithResult";
import {RenderFormMap} from "./renderFormMap";
import {RenderList} from "./renderList";
import {RenderHour} from "./renderHour";

export function RenderParam({workflow, step, param, parameters, onChange, onFocus, nameForKeyword}) {
    function getTooltip(tooltip) {
        if (!tooltip) {
            return null;
        }
        return tooltip.split("\n").map((line, index) => <span key={index}>{line}<br/></span>);
    }

    return <ListItem>

        {(param.type === "text" || param.type === "number" || param.type === "multiline_text") &&
            <RenderText nameForKeyword={nameForKeyword} param={param} parameters={parameters} onChange={onChange} onFocus={onFocus}/>}

        {param.type === "hour" &&
            <RenderHour param={param} parameters={parameters} onChange={onChange}/>}

        {(param.type === "button" || param.type === "button_with_result") &&
            <RenderButtonWithResult step={step} param={param} parameters={parameters} onChange={onChange} showResult={param.type === "button_with_result"}/>}

        {(param.type === "select" || param.type === "select_multiple") &&
            <RenderSelect parameters={parameters} onChange={onChange} onFocus={onFocus} param={param}/>}

        {param.type === "form_fields" &&
            <RenderFormMap nameForKeyword={nameForKeyword} workflow={workflow} step={step} parameters={parameters} onChange={onChange} onFocus={onFocus} param={param}/>}

        {(param.type === "list" || param.type === "destinations_list") &&
            <RenderList nameForKeyword={nameForKeyword}  workflow={workflow} parameters={parameters} step={step} onChange={onChange} onFocus={onFocus} param={param}/>}

        {param.tooltip &&
            <Tooltip title={getTooltip(param.tooltip)} disableHoverListener={false}><InfoRounded color={"primary"} sx={{m: 1}}/></Tooltip>}
    </ListItem>;
}