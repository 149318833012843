import React, {useEffect, useState} from "react";
import {
    Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Container, Divider, Stack, TextField
} from "@mui/material";
import api from "~/api";
import {MainPage, Navbar} from "~/components";
import {useDialog, useToast} from "~/providers/dialog";
import {useNavigate, useParams} from "react-router-dom";
import lodash from "lodash";

const CredentialsEdit = () => {
    const {uuid} = useParams();

    const [types, setTypes] = useState(null);
    const [credential, setCredential] = useState(null);
    const [credentialLocalCopy, setCredentialLocalCopy] = useState(null);

    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const showDialog = useDialog();
    const showToast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        async function loadTypes() {
            setLoading(true);
            try {
                const types = await api.credentials.types();
                setTypes(types);
            } catch (e) {
                showDialog("Não foi possível carregar as informações.", e);
            }
            setLoading(false);
        }

        loadTypes().then();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function loadCredential() {
            setLoading(true);
            try {
                const credential = await api.credentials.detail(uuid);
                setCredential(credential);
                setCredentialLocalCopy({...credential});
            } catch (e) {
                showDialog("Não foi possível carregar as informações.", e);
            }
            setLoading(false);

        }
        loadCredential().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const handleSave = async (e) => {
        e.preventDefault();
        if (loadingSave) {
            return;
        }
        setLoadingSave(true);
        try {
            await api.credentials.update(credential.uuid, credentialLocalCopy);
            navigate("/credentials");
            showToast("Credencial atualizada com sucesso!");
        } catch (e) {
            showDialog("Não foi possível atualizar a credencial.", e);
        }
        setLoadingSave(false);

    };

    const handleChange = (e) => {
        setCredentialLocalCopy((credentialLocalCopy) => {
            return {
                ...credentialLocalCopy,
                [e.target.name]: e.target.value
            };
        });
    };
    const handleChangeCredentials = (e) => {
        setCredentialLocalCopy((credentialLocalCopy) => {
            return {
                ...credentialLocalCopy,
                credentials: {
                    ...credentialLocalCopy.credentials,
                    [e.target.name]: e.target.value
                }
            };
        });
    };

    const type = (types && credential) ? types.find((type) => type.type === credential.type) : null;
    const saveDisabled = lodash.isEqual(credentialLocalCopy, credential);
    return <MainPage>
        <Navbar title="Editar Credencial"/>
        <Box
            component="main"
            sx={{
                flexGrow: 1, py: 8
            }}
        >
            <Container maxWidth="lg">
                <Stack spacing={3}>
                    <Card>
                        <CardHeader
                            title="Informações da credencial"
                        />
                        <Divider/>
                        {loading && <CircularProgress sx={{ml: 1, flexShrink: 0, margin: "0 auto"}} size={30}/>}
                        {type &&
                            <form onSubmit={handleSave}>
                                <CardContent>
                                    <Stack
                                        spacing={3}
                                        sx={{maxWidth: 400}}
                                    >
                                        {loading && <CircularProgress sx={{ml: 1, flexShrink: 0}} size={30}/>}
                                        <TextField fullWidth label={"Tipo de credencial"} disabled={true} value={credentialLocalCopy.type}/>
                                        <TextField fullWidth label={"Nome"} name={"name"} onChange={handleChange} required value={credentialLocalCopy.name}/>
                                        {type.attributes.map((field) => <TextField
                                            key={field.name}
                                            fullWidth
                                            label={field.label}
                                            name={field.name}
                                            onChange={handleChangeCredentials}
                                            required
                                            value={credentialLocalCopy.credentials[field.name] || ""}/>)}
                                    </Stack>
                                </CardContent>
                                <Divider/>
                                <CardActions sx={{justifyContent: "flex-end"}}>
                                    <Button disabled={loadingSave || saveDisabled} variant="contained" type="submit">
                                        {!loadingSave && "Salvar"}
                                        {loadingSave && <>
                                            Salvando...
                                            <CircularProgress size={20} sx={{ml: 2}}/>
                                        </>}
                                    </Button>
                                </CardActions>
                            </form>}
                    </Card>
                </Stack>
            </Container>
        </Box>
    </MainPage>;
};

export default CredentialsEdit;