import {Box, Button, Typography} from "@mui/material";
import React, {useEffect, useId, useRef, useState} from "react";
import api from "~/api";
import {useDialog} from "~/providers/dialog";
import {isEqual} from "lodash";
import {useFileUploadAtom} from "../../../../../providers/fileupload.atom";

export function RenderFile({item, infos, onChange}) {
    const [fileUpload, setFileUpload] = useFileUploadAtom();


    const inputId = useId();
    const fileInputRef = useRef();

    const [files, setFiles] = useState([]);
    const showDialog = useDialog();

    useEffect(() => {
        if (infos) {
            const infosFiles = infos[item.uuid + "_raw"] || [];
            if (!isEqual(infosFiles, files)) {
                setFiles(infosFiles);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infos, item.uuid]);

    useEffect(() => {
        const infosFiles = infos[item.uuid + "_raw"] || [];
        const foundFiles = infosFiles.map((file) => {
            const found = fileUpload.find((uploadedFile) => uploadedFile.name === file.name);
            if (found) {
                return found;
            } else {
                return file;
            }
        });
        setFiles(foundFiles);
        if (!isEqual(foundFiles, infosFiles)) {
            onChange({
                target: {
                    name: item.uuid + "_raw",
                    value: foundFiles,
                }
            });
        }
    }, [infos, item.uuid, fileUpload]);

    async function readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                let buffer = reader.result;
                if (buffer) {
                    if (typeof buffer === "string") {
                        const textEncoder = new TextEncoder();
                        buffer = textEncoder.encode(buffer).buffer;
                    }
                    resolve(buffer);
                } else {
                    reject();
                }
            };
            reader.readAsArrayBuffer(file);
        });
    }

    const handleChange = async (event) => {
        const files = event.target.files;
        const fileStatus = [];
        for (const file of files) {
            const uploadedFile = fileUpload.find((uploadedFile) => uploadedFile.name === file.name);
            if (uploadedFile) {
                fileStatus.push({
                    status: "uploaded",
                    file: uploadedFile.url,
                    name: file.name,
                    type: file.type,
                });
            } else {
                const data = await readFile(file);
                fileStatus.push({
                    status: "not_uploaded",
                    file: data,
                    name: file.name,
                    type: file.type,
                });
            }
        }


        const uploaded = fileStatus.filter((file) => file.status === "uploaded").map((file) => file.url);

        onChange({
            target: {
                name: item.uuid + "_raw",
                value: fileStatus,
            }
        });
        onChange({
            target: {
                name: item.uuid,
                value: uploaded,
            }
        });

        const pendingUpload = fileStatus.filter((file) => file.status === "not_uploaded");
        setFileUpload(fileUpload => [...fileUpload, ...pendingUpload]);

    };

    const handleClear = () => {
        setFiles([]);
        fileInputRef.current.value = "";
    };

    function statusToText(status) {
        if (status === "not_uploaded") {
            return "Aguardando envio";
        } else if (status === "uploading") {
            return "Enviando...";
        } else if (status === "uploaded") {
            return "Enviado";
        } else if (status === "error") {
            return "Erro ao enviar";
        }
        return "-";
    }

    return <div>
        <input
            type={"file"}
            ref={fileInputRef}
            id={inputId}
            name={item.uuid}
            onChange={handleChange}
            hidden
            multiple
            accept={"image/*"}
        />
        <span>{item.label}:<br/></span>
        {(!files || files.length === 0) &&
            <label htmlFor={inputId}>
                <Button variant={"outlined"} sx={{mt: 1}} component={"span"}>Selecionar arquivo</Button>
            </label>
        }
        {files?.length > 0 && <Box sx={{mt: 1}}>
            <Button variant={"outlined"} color={"warning"} component={"span"} onClick={handleClear}>Remover arquivo{files.length > 1 ? "s" : ""}</Button>
            <Typography sx={{mt: 1}}>Arquivo(s) selecionado(s):</Typography>
            {files.map((file, index) => <p key={index}>- {file.name} - {statusToText(file.status)}</p>)}
        </Box>}
    </div>;
}
