import React, {useEffect, useState} from "react";
import {Button, CardActions, CardContent, CircularProgress, Divider, Stack, Tab, Tabs} from "@mui/material";
import Grid from "@mui/material/Grid";

import {useDialog} from "~/providers/dialog";
import {RenderItem} from "./components";
import api from "~/api";

const RenderForm = ({form, onSave}) => {

    const [page, setPage] = useState(0);
    const [pageItem, setPageItem] = useState(null);
    const [infos, setInfos] = useState(null);
    const [internalLoading, setInternalLoading] = useState(false);
    const showDialog = useDialog();

    useEffect(() => {
        const mountFields = (item) => {
            let def = {};
            if (item.type !== "page") {
                def[item.uuid] = item.default || "";
            }
            if (item.items) {
                for (const field of item.items) {
                    def = {
                        ...def,
                        ...mountFields(field)
                    };
                }
            }
            return def;
        };

        if (form) {
            let itemDef = {};
            for (const field of form.items) {
                itemDef = {
                    ...itemDef,
                    [field.uuid]: mountFields(field)
                };
            }
            setInfos({...itemDef});
            setPageItem(form.items[0]);
        }
    }, [form]);

    const handleChange = (event, callback) => {
        console.log(event, callback);
        const fixedCallback = callback instanceof Function ? callback : undefined;
        setInfos(infos => {
            const currentItem = infos[pageItem.uuid] || {};
            return {
                ...infos,
                [pageItem.uuid]: {
                    ...currentItem,
                    [event.target.name]: fixedCallback ? fixedCallback(currentItem[event.target.name]) : event.target.value  
                }
            };
        });
    };

    const changeTab = (event, index) => {
        setPage(index);
        setPageItem(form.items[index]);
    };

    function checkNonAnswered(infos) {
        const checkFields = (item, infos, parentLabel) => {
            let errors = [];
            const label = parentLabel ? `${parentLabel} - ${item.label}` : item.label;
            if (item.items) {
                const itemInfos = infos[item.uuid];
                if (itemInfos instanceof Array) {
                    itemInfos.forEach((itemInfo, index) => {
                        for (const field of item.items) {
                            let error = checkFields(field, itemInfo, `${label} ${index + 1}`);
                            if (error) {
                                errors = [
                                    ...errors,
                                    ...error
                                ];
                            }
                        }
                    });
                } else if (item.type !== "repeater") {
                    for (const field of item.items) {
                        let error = checkFields(field, itemInfos, label);
                        if (error) {
                            errors = [
                                ...errors,
                                ...error
                            ];
                        }
                    }
                }
            }

            if (item.type === "page" ||
                item.type === "section" ||
                !item.required ||
                (item.required && infos[item.uuid] && infos[item.uuid].length > 0)) {
                return errors;
            }

            return [...errors, label];
        };

        let errors = [];
        for (const field of form.items) {
            let validate = checkFields(field, infos, parent.label);
            errors = [
                ...errors,
                ...validate
            ];
        }
        if (errors.length === 0) {
            return false;
        } else {
            return errors;
        }
    }

    const handleSave = async () => {
        /*
            Check required before posting
         */
        let formInfos = structuredClone(infos);

        function deleteRaw(object) {
            for (const key of Object.keys(object)) {
                if (key.endsWith("_raw")) {
                    delete object[key];
                } else if (object[key] instanceof Array) {
                    object[key].forEach(obj => {
                        if (obj instanceof Object) {
                            deleteRaw(obj);
                        }
                    });
                } else if (object[key] instanceof Object) {
                    deleteRaw(object[key]);
                }
            }
        }

        function checkPendingUpload(object) {
            let pending = false;
            for (const key of Object.keys(object)) {
                if (key.endsWith("_raw") && object[key] instanceof Array && object[key].some(file => file.status && file.status !== "uploaded")) {
                    pending = true;
                } else if (object[key] instanceof Array) {
                    object[key].forEach(obj => {
                        if (obj instanceof Object) {
                            pending = pending || checkPendingUpload(obj);
                        }
                    });
                } else if (object[key] instanceof Object) {
                    pending = pending || checkPendingUpload(object[key]);
                }
            }
            return pending;
        }

        async function checkSignatureUpload(object) {
            let success = true;
            for (const key of Object.keys(object)) {
                if (key.endsWith("_signature_raw")) {
                    const uuid = key.replace("_signature_raw", "");
                    const file = object[uuid];
                    if (file && typeof file !== "string") {
                        try {
                            const {url, getUrl} = await api.storage.getUrl("image/png");
                            await api.storage.upload(url, file, "image/png");
                            object[uuid] = getUrl;
                        } catch (e) {
                            showDialog("Não foi possível salvar a assintura.", e);
                            return false;
                        }
                    }
                } else if (object[key] instanceof Array) {
                    for (const obj of object[key]) {
                        if (obj instanceof Object) {
                            success = success && await checkSignatureUpload(obj);
                        }
                    }
                } else if (object[key] instanceof Object) {
                    success = success && await checkSignatureUpload(object[key]);
                }
            }
            return success;
        }


        if (checkPendingUpload(formInfos)) {
            showDialog("Atenção!", "Aguarde finalizar o upload dos arquivos.");
        } else {
            setInternalLoading(true);
            const success = await checkSignatureUpload(formInfos);
            if (success) {
                const nonAnsweredFields = checkNonAnswered(formInfos);
                if (!nonAnsweredFields) {
                    deleteRaw(formInfos);
                    const formJson = {
                        answers: formInfos
                    };

                    await onSave(formJson);
                } else {
                    showDialog("Atenção!", "Os campos abaixo não estão preenchidos: \n\n" + nonAnsweredFields.map(text => `- ${text}`).join("\n"));
                }
            }
            setInternalLoading(false);
        }
    };

    return <>
        <CardContent>
            <Stack spacing={3}>
                <Tabs value={page} onChange={changeTab}>
                    {form.items.map((item, index) => <Tab key={item.uuid} value={index} label={item.label}/>)}
                </Tabs>

                <CardContent>
                    <Grid container spacing={3}>
                        {infos && form.items[page].items.map(item => <RenderItem key={item.uuid} item={item} infos={infos[pageItem.uuid]} onChange={handleChange}/>)}
                    </Grid>
                </CardContent>
            </Stack>
        </CardContent>
        <Divider/>
        <CardActions sx={{justifyContent: page > 0 ? "space-between" : "flex-end"}}>
            {page > 0 && <Button variant="outlined" onClick={(e) => {
                changeTab(e, page - 1);
            }}>
                Voltar
            </Button>}
            {page < form.items.length - 1 && <Button variant="outlined" onClick={(e) => {
                changeTab(e, page + 1);
            }}>
                Próximo
            </Button>}
            {page === form.items.length - 1 && <Button variant="contained" onClick={handleSave}>
                Enviar
                {internalLoading && <CircularProgress style={{marginLeft: "10px"}} size={20}/>}
            </Button>}
        </CardActions>
    </>;
};
export default RenderForm;