import React from "react";
import {getBezierPath} from "reactflow";

import styles from "./BabelEdge.module.css";

export default function BabelEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style,
    markerEnd,
    selected
}) {

    const [edgePath] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    return (
        <>
            <path id={id} style={style} className={`${styles.edgePath} ${selected ? styles.edgePathSelected : ""}`} d={edgePath} markerEnd={markerEnd}/>
        </>
    );
}
