import React from "react";
import {AppBar, Button, Toolbar, Typography} from "@mui/material";
import "./navbar.css";
import {FlexBox} from "../index";
import {ArrowBack} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

const Navbar = ({title, subtitle, sx, backUrl, children}) => {
    const drawerWidth = 320;
    const navigate = useNavigate();


    function handleBack() {
        navigate(backUrl);
    }

    function renderTitle() {
        if (subtitle) {
            return <FlexBox>
                <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1}}>
                    {title}
                </Typography>
                <Typography variant="subtitle1" noWrap component="div" sx={{flexGrow: 1}}>
                    {subtitle}
                </Typography>
            </FlexBox>;
        } else {
            return <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1}}>
                {title}
            </Typography>;

        }
    }

    return <>
        <AppBar position="fixed" sx={{width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, ...sx}}>
            <Toolbar>
                {backUrl &&
                    <Button color={"inherit"} sx={{minWidth: 0}} onClick={handleBack}>
                        <ArrowBack/>
                    </Button>
                }
                {renderTitle()}
                {children}
            </Toolbar>
        </AppBar>
    </>;
};

export default Navbar;