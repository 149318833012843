import axios from "axios";

const storage = (apiClient, handleError) => {
    return {
        upload: async (url, uploadData, type) => {
            try {
                const result = await axios.put(url, uploadData, {
                    headers: {
                        "x-ms-blob-type": "BlockBlob",
                        "content-type": type
                    }
                });
                return result.data;
            } catch (error) {
                handleError(error);
            }
        },
        getUrl: async (type) => {
            try {
                const {data} = await apiClient.get(`/v1/storage?type=${type}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
    };
};
export default storage;