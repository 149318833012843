import React from "react";
import {Handle, Position} from "reactflow";

import styles from "./BabelNode.module.css";
import {hasIconFromName, iconFromName} from "~/components/Icons";

const MultiDestinationNode = ({data, selected}) => {
    return <div className={`${styles.node} ${selected ? styles.node_selected : ""}`}>
        {hasIconFromName(data.typeIcon) && <div className={styles.avatar}>{iconFromName(data.typeIcon)}</div>}
        {data.label}
        <Handle className={`${styles.handle} ${styles.handleTop}`} type="target" position={Position.Top} isConnectable={true}/>
        {
            data.destinations?.map((destination, index) => {
                const position = (index + 1) / (data.destinations.length + 1);
                return <Handle
                    key={destination.uuid}
                    className={`${styles.handle} ${styles.handleBottom}`}
                    style={{left: `${position * 100}%`}}
                    type="source"
                    id={destination.uuid}
                    position={Position.Bottom}
                    isConnectable={true}>
                    <span className={styles.handleName}>{destination.name}</span>
                </Handle>;
            })
        }
    </div>;
};
export default MultiDestinationNode;