import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";
import {defaultFieldSx} from "./index";
export function RenderSelect({item, infos, onChange}) {
    return <FormControl sx={defaultFieldSx}>
        <InputLabel>{item.label} {item.required ? "*" : ""}</InputLabel>
        <Select
            label={item.label}
            name={item.uuid}
            value={infos[item.uuid] || ""}
            onChange={item.readonly ? null : onChange}>
            {item.options.map(option => <MenuItem key={option.uuid} value={option.value || option.description}>{option.description}</MenuItem>)}
        </Select>
    </FormControl>;
}
