import {FormControl} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import React from "react";
import {defaultFieldSx} from "./index";

export function RenderDate({item, infos, onChange}) {
    const handleChange = (event) => {
        onChange({
            target: {
                name: item.uuid,
                value: event.format("DD/MM/YYYY")
            }
        });
    };
    return <FormControl sx={defaultFieldSx}>
        <DatePicker
            label={`${item.label} ${item.required ? "*" : ""}`}
            name={item.uuid}
            required={item.required}
            onChange={handleChange}
            value={infos[item.uuid]?.length > 0 ? moment(infos[item.uuid], "DD/MM/YYYY") : null}/>
    </FormControl>;
}
