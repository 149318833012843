import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    Box, Card, CardContent,
    CardHeader,
    Divider,
    ImageList,
    ImageListItem,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";

import api from "~/api";
import {Navbar, MainPage, LoadingBar} from "~/components";
import {useDialog} from "~/providers/dialog";
import {colorForStatus, orderWorkflowSteps, translateStatus} from "~/utils/workflow";
import {formatDateTime} from "~/utils/date";

const tabsStyle = {
    "& .MuiTabs-indicator": {
        backgroundColor: "rgba(0, 0, 0, 0.6)"
    },
    flexShrink: 0,
    mb: 5
};

const tabStyle = {
    color: "rgba(0, 0, 0, 0.6)",
    "&.Mui-selected": {
        color: "rgba(0, 0, 0, 0.8)",
    }
};


const WorkflowExecutionDetail = () => {
    const {workflowUuid, executionUuid} = useParams();
    const [loading, setLoading] = useState(true);
    const [workflow, setWorkflow] = useState(null);
    const [execution, setExecution] = useState(null);
    const [steps, setSteps] = useState(null);
    const [selectedStep, setSelectedStep] = useState(null);

    const showDialog = useDialog();

    useEffect(() => {
        if (workflow) {
            let orderedStepsCache = orderWorkflowSteps(workflow.steps);
            setSteps(orderedStepsCache);
            setSelectedStep(orderedStepsCache[0].uuid);
        }
    }, [workflow]);

    useEffect(() => {
        if (!workflowUuid || !executionUuid) {
            return;
        }

        const loadExecution = async () => {
            setLoading(true);
            try {
                const execution = await api.workflows.executionDetail(workflowUuid, executionUuid);
                setExecution(execution);
                if (execution.version) {
                    setWorkflow(execution.version.definition);
                } else {
                    const workflow = await api.workflows.detail(workflowUuid);
                    setWorkflow(workflow);
                }
            } catch (e) {
                showDialog("Não foi possível carregar o workflow.", e);
            }
            setLoading(false);
        };
        loadExecution().then();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflowUuid, executionUuid]);

    const handleChangeStep = (_, value) => {
        setSelectedStep(value);
    };

    const findExecutionStep = (step) => {
        if (step) {
            return execution?.steps?.find(eStep => eStep.workflowStep.uuid === step.uuid);
        }
    };

    const step = steps?.find(step => step.uuid === selectedStep);
    const executed = findExecutionStep(step);

    const checkIsObject = (obj) => {
        return (obj instanceof Object);
    };

    function renderProperties(properties, answers) {
        if (!properties) {
            return null;
        }

        const reorderedProperties = Object.keys(properties).sort((a, b) => properties[a].$order - properties[b].$order);

        return <>
            {
                reorderedProperties.map(propertyName => {
                    const property = properties[propertyName];
                    const answer = answers[propertyName];
                    const propertyLabel = property.label || propertyName;
                    if (property.type === "object") {
                        if (typeof answer === "object") {
                            return <Card key={property["$id"]} sx={{mt: 1, p: 2}}>
                                <Typography variant={"h5"}>{propertyLabel}:</Typography>
                                {renderProperties(property.properties, answer)}
                            </Card>;
                        }
                    } else if(property.$formType === "multiselect") {
                        const multiSelectItem = (answer instanceof Array) && answer.map((item) => item);
                        return <TextField
                            key={property["$id"]}
                            fullWidth={true}
                            label={propertyLabel}
                            disabled={true}
                            sx={{mt: 2}}
                            type="text"
                            value={multiSelectItem}
                        />
                    } else if (property.type === "array") {
                        return <Box key={property["$id"]} sx={{p: 1}}>
                            <Typography variant={"h6"}>{propertyLabel}:</Typography>
                            {(answer instanceof Array) && answer.map((item, i) =>
                                <Card key={`${property["$id"]}_${i}`} sx={{m: 1, p: 2}}>
                                    {renderProperties(property.items.properties, item)}
                                </Card>
                            )}
                        </Box>;
                    } else if (property.type === "image" || property.type === "file") {
                        return <Box key={property["$id"]} sx={{p: 1}}>
                            <Typography variant={"h6"}>{propertyLabel}:</Typography>
                            <ImageList variant="quilted" cols={3} rowHeight={250}>
                                {
                                    answer ? [answer].flat().map((item, i) =>
                                        (typeof item === "string" &&
                                            <a href={item} target="_blank" key={i} rel="noreferrer">
                                                <ImageListItem aria-modal key={i}>
                                                    {item && item.startsWith("http") ?
                                                        <img style={{objectFit: "contain"}} alt={"Arquivo anexado"} srcSet={item} src={item} loading="lazy"/>
                                                        :
                                                        <img style={{objectFit: "contain"}} alt={"Arquivo anexado"} src={`data:image/jpeg;base64, ${item}`}/>
                                                    }
                                                </ImageListItem>
                                            </a>)
                                    ) : <span>Não preenchido</span>
                                }
                            </ImageList>
                        </Box>;
                    } else {
                        let value = answer;
                        if (property.type === "boolean") {
                            value = answer ? "Sim" : "Não";
                        } else if (property.format === "date-time") {
                            value = formatDateTime(answer);
                        } else {
                            value = checkIsObject(answer) ? JSON.stringify(answer, null, "\t") : answer;
                        }

                        return <TextField
                            key={property["$id"]}
                            fullWidth={true}
                            disabled={true}
                            label={propertyLabel}
                            multiline={checkIsObject(answer)}
                            minRows={checkIsObject(answer) ? 5 : 1}
                            sx={{mt: 2}}
                            type="text"
                            value={value}
                        />;
                    }
                })
            }
        </>;
    }


    return <MainPage>
        {
            loading ? <LoadingBar/>
                :
                <>
                    <Navbar title={workflow.title} sx={{zIndex: (theme) => theme.zIndex.drawer + 100}}  backUrl={`/workflows/${workflowUuid}`}>
                    </Navbar>
                    <Box sx={{display: "flex", height: "100%", flexDirection: "column"}}>
                        <Tabs value={selectedStep} variant={"fullWidth"} onChange={handleChangeStep} sx={tabsStyle}>
                            {steps?.map(step =>
                                <Tab
                                    sx={{...tabStyle, backgroundColor: colorForStatus(findExecutionStep(step)?.status)}}
                                    key={step.uuid}
                                    value={step.uuid}
                                    label={step.name}/>)}
                        </Tabs>
                        {step &&
                            <Card>
                                <CardHeader title={step.name}/>
                                <Divider/>
                                <CardContent>
                                    <Stack spacing={3}>
                                        <Typography>
                                            Situação: {executed ? `${translateStatus(executed.status)} em ${formatDateTime(executed.updatedAt)}` : "Não iniciado"}
                                        </Typography>

                                        {executed?.status === "error" &&
                                            <TextField
                                                fullWidth
                                                label="Erro"
                                                type="text"
                                                disabled={true}
                                                multiline={true}
                                                minRows={5}
                                                value={executed.output.error}
                                            />}
                                        {executed?.status === "finished" && executed.schema && renderProperties(executed.schema.properties, executed.output)}
                                        {executed?.status === "finished" && !executed.schema &&
                                            Object.keys(executed.output).map(key =>
                                                <TextField
                                                    key={key}
                                                    fullWidth
                                                    label={key}
                                                    disabled={true}
                                                    multiline={checkIsObject(executed.output[key])}
                                                    minRows={checkIsObject(executed.output[key]) ? 5 : 1}
                                                    type="text"
                                                    value={checkIsObject(executed.output[key]) ? JSON.stringify(executed.output[key], null, "\t") : executed.output[key]}
                                                />)
                                        }

                                    </Stack>
                                </CardContent>
                            </Card>
                        }
                    </Box>
                </>
        }
    </MainPage>;
};

export default WorkflowExecutionDetail;

