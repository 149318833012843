import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Divider,
    Stack,
    TextField
} from "@mui/material";

import api from "~/api";
import {MainPage, Navbar} from "~/components";
import {useDialog, useToast} from "~/providers/dialog";

const CompanyEdit = () => {
    const {uuid} = useParams();

    const [infos, setInfos] = useState({
        name: "",
        email: "",
        legal_document: ""
    });


    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const showDialog = useDialog();
    const showToast = useToast();

    const navigate = useNavigate();

    useEffect(() => {
        const fetchInfos = async () => {
            setLoading(true);
            try {
                const data = await api.companies.detail(uuid);
                setInfos({
                    name: data.name,
                    email: data.email,
                    legal_document: data.legal_document,
                });
            } catch (e) {
                showDialog("Não foi possível carregar as informações.", e);
            }
            setLoading(false);
        };
        fetchInfos().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setInfos({
            ...infos,
            [name]: value
        });
    };

    const handleSave = (e) => {
        e.preventDefault();
        if (loadingSave) {
            return;
        }

        if (!infos.name || infos.name.length === 0) {
            showDialog("Atenção", "O nome não foi preenchida.");
        } else {
            (async () => {
                setLoadingSave(true);
                try {
                    await api.companies.update(uuid, infos);
                    navigate("/companies");
                    showToast("Empresa salva com sucesso!");
                } catch (e) {
                    showDialog("Não foi possível salvar.", e);
                }
                setLoadingSave(false);
            })();
        }
    };

    return <MainPage>
        <Navbar title="Editar Empresa"/>
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="lg">
                <Stack spacing={3}>
                    <form onSubmit={handleSave}>
                        <Card>
                            <CardHeader title="Informações da empresa"/>
                            <Divider/>
                            <CardContent>
                                <Stack
                                    spacing={3}
                                    sx={{maxWidth: 400}}>
                                    {loading && <CircularProgress sx={{ml: 1, flexShrink: 0}} size={30}/>}

                                    <TextField
                                        fullWidth
                                        label="Nome"
                                        name="name"
                                        type="text"
                                        required
                                        onChange={handleChange}
                                        value={infos.name}
                                    />

                                    <TextField
                                        fullWidth
                                        label="Email"
                                        name="email"
                                        type="text"
                                        required
                                        onChange={handleChange}
                                        value={infos.email}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Documento"
                                        name="legal_document"
                                        type="text"
                                        onChange={handleChange}
                                        value={infos.legal_document}
                                    />


                                </Stack>
                            </CardContent>
                            <Divider/>
                            <CardActions sx={{justifyContent: "flex-end"}}>
                                <Button disabled={loadingSave} variant="contained" type="submit">
                                    {!loadingSave && "Salvar"}
                                    {loadingSave && <>
                                        Salvando...
                                        <CircularProgress size={20} sx={{ml: 2}}/>
                                    </>}
                                </Button>
                            </CardActions>
                        </Card>
                    </form>

                </Stack>
            </Container>
        </Box>
    </MainPage>;
};

export default CompanyEdit;