import React, {useCallback, useEffect, useRef, useState} from "react";
import {v4 as uuidv4} from "uuid";
import lodash from "lodash";

import {Box, Button, CircularProgress, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Switch, Typography} from "@mui/material";
import {useParams} from "react-router-dom";

import api from "~/api";
import {Navbar, MainPage, LoadingBar} from "~/components";
import {useDialog} from "~/providers/dialog";

import WorkflowViewer from "./WorkflowViewer";
import WorkflowToolbox from "./WorkflowToolbox";
import "./workflowDetail.css";
import {orderWorkflowSteps} from "~/utils/workflow";
import * as Icons from "@mui/icons-material";

const WorkflowFlow = () => {
    const {uuid} = useParams();
    const [workflow, setWorkflow] = useState(null);
    const [workflowLocalCopy, setWorkflowLocalCopy] = useState(null);
    const [definition, setDefinition] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false);
    const [selectedStep, setSelectedStep] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [errors, setErrors] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [flowEnabled, setFlowEnabled] = useState(false);
    const [isSavingEnabled, setIsSavingEnabled] = useState(false);

    const [isExporting, setIsExporting] = useState(false);

    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);

    const showDialog = useDialog();
    const workflowViewer = useRef(null);

    const fetchWorkflow = useCallback(() => {
        (async () => {
            setLoading(true);
            try {
                const data = await api.workflows.flow(uuid);
                setWorkflow(data);
                setWorkflowLocalCopy({...data});
                setFlowEnabled(data.enabled);

                const definitionData = await api.workflows.parameters(uuid);
                setDefinition(definitionData);

                setLoading(false);
            } catch (e) {
                showDialog("Erro ao carregar workflow", e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    useEffect(() => {
        fetchWorkflow();
    }, [fetchWorkflow, uuid]);

    useEffect(() => {
        setHasChanges(!lodash.isEqual(workflow, workflowLocalCopy));

        if (workflowLocalCopy) {
            const steps = workflowLocalCopy.steps;
            //Check links
            let uuidWithLinks = steps.map(step => step.nextStepsLinks.map(link => link.destinationUuid)).flat(10);
            const stepsWithoutLinks = steps.filter((step) => uuidWithLinks.indexOf(step.uuid) === -1);
            if (stepsWithoutLinks.length > 1 && steps.length > 1) {
                setErrors("Há etapas sem conexão com o fluxo.");
            } else if (stepsWithoutLinks.length === 0 && steps.length > 1) {
                setErrors("O fluxo está sem final.");
            } else {
                setErrors(null);
            }
        }
    }, [workflowLocalCopy, workflow]);


    const handleStepClicked = (step) => {
        setSelectedStep(currentStep => {
            if (!step || (currentStep && step.uuid === currentStep.uuid)) {
                return null;
            } else {
                return step;
            }
        });
    };

    const updateWorkflowLocalCopy = (newWorkflow) => {
        if (!lodash.isEqual(newWorkflow, workflowLocalCopy)) {
            setUndoStack((undoStack) => [...undoStack, workflowLocalCopy]);
            setWorkflowLocalCopy(newWorkflow);
            setRedoStack([]);
        }
    };

    const handleUndo = () => {
        let newUndoStack = [...undoStack];
        const newLocalCopy = newUndoStack.pop();
        setRedoStack((redoStack) => [...redoStack, workflowLocalCopy]);
        setUndoStack(newUndoStack);
        setWorkflowLocalCopy(newLocalCopy);

        if (selectedStep) {
            const newSelectedStep = newLocalCopy.steps.find(nstep => nstep.uuid === selectedStep.uuid);
            setSelectedStep(newSelectedStep);
        }
        workflowViewer.current?.updateAllWorkflow(newLocalCopy, selectedStep?.uuid);

    };

    const handleRedo = () => {
        let newRedoStack = [...redoStack];
        const newLocalCopy = newRedoStack.pop();

        setUndoStack((undoStack) => [...undoStack, workflowLocalCopy]);
        setRedoStack(newRedoStack);
        setWorkflowLocalCopy(newLocalCopy);

        if (selectedStep) {
            const newSelectedStep = newLocalCopy.steps.find(nstep => nstep.uuid === selectedStep.uuid);
            setSelectedStep(newSelectedStep);
        }
        workflowViewer.current?.updateAllWorkflow(newLocalCopy, selectedStep?.uuid);
    };

    const handleAddStep = (step, position) => {
        const newWorkflow = {...workflowLocalCopy};
        const newStep = {
            type: step.type,
            name: "Novo passo " + step.name,
            uuid: uuidv4(),
            nextStepsLinks: [],
            parameters: {}
        };
        newWorkflow.steps.push(newStep);
        updateWorkflowLocalCopy(newWorkflow);
        workflowViewer.current?.addStep(newStep, position);
    };

    const handleStepUpdated = (newStep) => {
        const steps = [...workflowLocalCopy.steps];
        const index = steps.findIndex(s => s.uuid === newStep.uuid);
        steps[index] = {...newStep};
        updateWorkflowLocalCopy({
            ...workflowLocalCopy,
            steps: steps
        });
        setSelectedStep(newStep);
        workflowViewer.current?.updateStep(newStep);
    };

    const handleStepRemoved = (step) => {
        const steps = [...workflowLocalCopy.steps];
        const index = steps.findIndex(s => s.uuid === step.uuid);
        steps.splice(index, 1);
        updateWorkflowLocalCopy({
            ...workflowLocalCopy,
            steps: steps
        });
    };


    const handleSaveWorkflow = async () => {
        if (!saveLoading) {
            /*
                CHECK INTERPOLATION
             */
            let interpolationErrors = [];
            const steps = orderWorkflowSteps(workflowLocalCopy.steps);
            const stepsUuid = steps.map(step => step.uuid);
            let stepsName = {};
            steps.forEach(step => stepsName[step.uuid] = step.name);

            for (const step of steps) {
                const currentStepIndex = stepsUuid.indexOf(step.uuid);
                for (const key of Object.keys(step.parameters)) {
                    const parameter = step.parameters[key];
                    if (typeof parameter === "string") {
                        let matches = parameter.matchAll(/({{.[^{}]*?}})/g);
                        let match = matches.next();
                        while (!match.done) {
                            const matchedValue = match.value[0].replace(/(^{{)|(}}$)/g, "");
                            const stepUuid = matchedValue.split("|")[0];
                            const infoStepUuid = stepsUuid.indexOf(stepUuid);
                            if (infoStepUuid === -1 || infoStepUuid >= currentStepIndex) {
                                const stepName = stepsName[stepUuid] || stepUuid;
                                const variableName = matchedValue.replace(stepUuid, stepName).replaceAll("|", " - ");
                                if (variableName !== "SCRIPT") {
                                    interpolationErrors.push("Etapa: " + step.name + " - Variável: " + variableName);
                                }
                            }
                            match = matches.next();
                        }

                    }
                }
            }

            if (interpolationErrors.length > 0) {
                showDialog("Atenção!", "Você está utilizando variáveis de etapas posteriores nas etapas:\n" + interpolationErrors.join("\n"));
            } else if (errors) {
                showDialog("Verifique as informações", errors);
            } else {
                setSaveLoading(true);
                try {
                    const data = await api.workflows.update(uuid, workflowLocalCopy);
                    setWorkflow({...data});
                    setWorkflowLocalCopy({...data});
                } catch (e) {
                    showDialog("Erro ao salvar workflow", e);
                }
                setSaveLoading(false);
            }
        }
    };

    const handleShowMenu = (event) => {
        setAnchorEl(event.currentTarget);

    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleChangeEnabled = () => {
        setFlowEnabled(!flowEnabled);
    };

    const handleExport = async () => {
        setIsExporting(true);
        try {
            const exportedWorkflow = await api.workflows.export(uuid);
            const blob = new Blob([JSON.stringify(exportedWorkflow)], {type: "application/json"});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = `${workflow.title}.voxit.json`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (e) {
            showDialog("Erro ao exportar workflow", e);
        }
        setIsExporting(false);
    };

    useEffect(() => {
        if (workflowLocalCopy && flowEnabled !== null && workflowLocalCopy?.enabled !== flowEnabled) {
            const newToken = setTimeout(async () => {
                setIsSavingEnabled(true);
                await api.workflows.patch(uuid, {enabled: flowEnabled});
                setWorkflowLocalCopy((workflowLocalCopy) => {
                    return {
                        ...workflowLocalCopy,
                        enabled: flowEnabled
                    };
                });
                setWorkflow((workflow) => {
                    return {
                        ...workflow,
                        enabled: flowEnabled
                    };
                });
                await new Promise(resolve => setTimeout(resolve, 500));

                setIsSavingEnabled(false);
            }, 700);
            return () => {
                clearTimeout(newToken);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowEnabled, workflowLocalCopy?.enabled]);

    return <MainPage>
        {loading ? <LoadingBar/>
            :
            <>
                <Navbar title={workflowLocalCopy.title} sx={{zIndex: (theme) => theme.zIndex.drawer + 100}} backUrl={`/workflows/${uuid}`}>
                    {errors}
                    <Button onClick={handleUndo} disabled={undoStack.length === 0} sx={{display: "flex", flexDirection: "column"}} color={"white"}>
                        <Icons.UndoRounded/>
                        <Typography variant={"caption"}>DESFAZER</Typography>
                    </Button>
                    <Button onClick={handleRedo} disabled={redoStack.length === 0} sx={{display: "flex", flexDirection: "column"}} color={"white"}>
                        <Icons.RedoRounded/>
                        <Typography variant={"caption"}>REFAZER</Typography>
                    </Button>
                    <Button sx={{ml: 1}} disabled={!hasChanges} onClick={handleSaveWorkflow} variant={"contained"} color={"info"}>
                        Publicar {saveLoading ? <CircularProgress sx={{color: "#FFFFFF", ml: 2}} size={15}/> : ""}
                    </Button>
                    <IconButton
                        size="large"
                        aria-label="display more actions"
                        edge="end"
                        color="inherit"
                        onClick={handleShowMenu}
                    >
                        <Icons.MoreVert/>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={handleChangeEnabled}>
                            <ListItemIcon>
                                <Icons.AccountTree fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Fluxo ativo</ListItemText>
                            {isSavingEnabled && <CircularProgress size={"2rem"} sx={{ml: 2, mr: 2, mt: 0.5, mb: 0.5}}/>}
                            {!isSavingEnabled && <Switch checked={flowEnabled} onChange={handleChangeEnabled}/>}
                        </MenuItem>
                        <MenuItem onClick={handleExport}>
                            <ListItemIcon>
                                <Icons.Download fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Exportar</ListItemText>
                            {isExporting && <CircularProgress size={"2rem"} sx={{ml: 2, mr: 2, mt: 0.5, mb: 0.5}}/>}
                        </MenuItem>
                    </Menu>
                </Navbar>
                <Box sx={{display: "flex", height: "100%"}}>
                    <WorkflowViewer
                        ref={workflowViewer}
                        workflow={workflowLocalCopy}
                        definition={definition}
                        onStepClicked={handleStepClicked}
                        onStepUpdated={handleStepUpdated}
                        onStepRemoved={handleStepRemoved}
                        onAddStep={handleAddStep}/>

                    <WorkflowToolbox
                        selectedStep={selectedStep}
                        workflow={workflowLocalCopy}
                        definition={definition}
                        onAddStep={handleAddStep}
                        onStepUpdated={handleStepUpdated}
                        reloadWorkflow={fetchWorkflow}
                        hasChanges={hasChanges}/>
                </Box>
            </>
        }
    </MainPage>;
};
export default WorkflowFlow;