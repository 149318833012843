import React from "react";
import {Handle, Position} from "reactflow";

import styles from "./BabelNode.module.css";
import {hasIconFromName, iconFromName} from "~/components/Icons";

const BabelNode = ({data, selected}) => {
    return <div className={`${styles.node} ${selected ? styles.node_selected : ""}`}>
        {hasIconFromName(data.typeIcon) && <div className={styles.avatar}>{iconFromName(data.typeIcon)}</div>}
        {data.label}
        <Handle className={`${styles.handle} ${styles.handleTop}`} type="target" position={Position.Top} isConnectable={true}/>
        <Handle className={`${styles.handle} ${styles.handleBottom}`} type="source" position={Position.Bottom} isConnectable={true}/>
    </div>;
};
export default BabelNode;