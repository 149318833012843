import React from "react";
import {Box, CircularProgress, LinearProgress, Typography} from "@mui/material";
import {Navbar} from "~/components";

export const LoadingBar = () => {
    return <div>
        <Navbar/>
        <LinearProgress/>
        <Typography variant="h6" mt={2} align={"center"}>Carregando...</Typography>
    </div>;
};

export const LoadingCentered = () => {
    return <Box sx={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <CircularProgress/>
    </Box>;
};
