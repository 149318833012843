import React, {useState} from "react";
import {Button, Collapse, Divider, List, ListItem, ListItemButton, ListItemText, Typography} from "@mui/material";
import {ElectricBolt, ExpandLess, ExpandMore} from "@mui/icons-material";

import {RenderParam} from "./renderParam";
import {v4 as uuidv4} from "uuid";
import {useConfirm} from "material-ui-confirm";
import {RenderText} from "./renderText";

export function RenderList({workflow, step, param, parameters, onChange, onFocus, nameForKeyword}) {
    const [openedItems, setOpenedItems] = useState([]);
    const confirm = useConfirm();
    const [isScript, setIsScript] = useState(typeof parameters[param.key] === "string");

    async function confirmAsync(options) {
        return new Promise((resolve) => {
            confirm(options).then(() => {
                resolve(true);
            }).catch(() => {
                resolve(false);
            });
        });
    }

    const handleOpenItem = (uuid) => {
        if (openedItems.indexOf(uuid) === -1) {
            setOpenedItems([...openedItems, uuid]);
        } else {
            setOpenedItems(openedItems.filter((item) => item !== uuid));
        }
    };

    const handleNewItem = () => {
        let item = {
            uuid: uuidv4()
        };
        for (const subitem of param.subitems) {
            item[subitem.key] = "";
        }

        const items = parameters[param.key] || [];
        const newItems = [...items, item];
        onChange({
            target: {
                name: param.key,
                value: newItems
            }
        });
        setOpenedItems([...openedItems, item.uuid]);

        if (param.type === "destinations_list") {
            const destinations = parameters["destinations"] || [];
            const newDestinations = [...destinations, {uuid: item.uuid, name: item.name}];
            onChange({
                target: {
                    name: "destinations",
                    value: newDestinations
                }
            });
        }
    };

    const handleFocus = (uuid, item) => {
        if (item) {
            const keyPath = item.keyPath || item.key;

            onFocus({
                ...item,
                keyPath: `${param.key}[].${uuid}.${keyPath}`,
            });
        } else {
            onFocus(null);
        }
    };

    const handleRemoveItem = async (item) => {
        const success = await confirmAsync({
            description: "Tem certeza que deseja remover este item?",
            confirmationText: "Excluir",
            cancellationText: "Cancelar",
            confirmationButtonProps: {color: "error"},
        });
        if (success) {
            const items = parameters[param.key] || [];
            const newItems = items.filter((it) => it.uuid !== item.uuid);
            onChange({
                target: {
                    name: param.key,
                    value: newItems
                }
            });
            setOpenedItems(openedItems.filter((it) => it !== item.uuid));

            if (param.type === "destinations_list") {
                const destinations = parameters["destinations"] || [];
                const newDestinations = destinations.filter((it) => it.uuid !== item.uuid);
                onChange({
                    target: {
                        name: "destinations",
                        value: newDestinations
                    }
                });
            }
        }
    };

    const handleSubitemChange = (itemUuid, event) => {
        const {name, value} = event.target;
        const newItems = items.map((item) => {
            if (item.uuid === itemUuid) {
                return {
                    ...item,
                    [name]: value
                };
            }
            return item;
        });
        onChange({
            target: {
                name: param.key,
                value: newItems
            }
        });
        if (param.type === "destinations_list" && name === "name") {
            const destinations = parameters["destinations"] || [];
            const newDestinations = destinations.map((destination) => {
                if (destination.uuid === itemUuid) {
                    return {
                        ...destination,
                        name: value
                    };
                }
                return destination;
            });
            onChange({
                target: {
                    name: "destinations",
                    value: newDestinations
                }
            });
        }
    };

    const handleEnableScript = async () => {
        if (isScript) {
            let clear = true;
            if (typeof parameters[param.key] === "string" && parameters[param.key].length > 0) {
                clear = await confirmAsync({
                    description: "Ao continuar você irá remover o script já adicionado.",
                    confirmationText: "Confirmar",
                    cancellationText: "Cancelar",
                });
            }
            if (clear) {
                onChange({
                    target: {
                        name: param.key,
                        value: []
                    }
                });
                setIsScript(false);
            }
        } else {
            let clear = true;
            if (Array.isArray(parameters[param.key]) && parameters[param.key].length > 0) {
                clear = await confirmAsync({
                    description: "Ao continuar você irá remover as opções já adicionadas.",
                    confirmationText: "Confirmar",
                    cancellationText: "Cancelar",
                });
            }
            if (clear) {
                onChange({
                    target: {
                        name: param.key,
                        value: ""
                    }
                });
                setIsScript(true);
            }
        }
    };

    const items = parameters[param.key] || [];
    return <List sx={{width: "100%", border: "1px solid #CCCCCC", borderRadius: "4px"}}>
        <ListItem>
            <Typography fontWeight={"bold"}>{param.label}:</Typography>
            <Button sx={{marginLeft: "auto", minWidth: "auto"}} variant={isScript ? "contained" : "outlined"} onClick={handleEnableScript}><ElectricBolt/></Button>
        </ListItem>
        <Divider/>


        {isScript ?
            <ListItem>
                <RenderText nameForKeyword={nameForKeyword} param={param} parameters={parameters} onChange={onChange} onFocus={onFocus}/>
            </ListItem> :
            <>
                {items.map((item, index) => <React.Fragment key={item.uuid}>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => handleOpenItem(item.uuid)}>
                            <ListItemText primary={`Item ${index + 1}`}/>
                            {openedItems.indexOf(item.uuid) !== -1 ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={openedItems.indexOf(item.uuid) !== -1}>
                        {param.subitems?.map(param => (
                            <RenderParam
                                key={param.key}
                                workflow={workflow}
                                onFocus={(focusItem) => handleFocus(item.uuid, focusItem)}
                                step={step}
                                parameters={item}
                                param={param}
                                onChange={(event) => handleSubitemChange(item.uuid, event)}/>
                        ))}
                        <ListItem>
                            <Button variant={"outlined"} color={"error"} sx={{marginLeft: "auto"}} onClick={() => handleRemoveItem(item)}>Remover item</Button>
                        </ListItem>
                    </Collapse>
                    <Divider/>
                </React.Fragment>)}
                <ListItem>
                    <Button variant={"outlined"} fullWidth onClick={handleNewItem}>Novo item</Button>
                </ListItem>
            </>
        }
    </List>;
}