import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Box} from "@mui/material";
import {addEdge, ReactFlowProvider, useEdgesState, useNodesState} from "reactflow";
import lodash from "lodash";

import {orderWorkflowSteps} from "~/utils/workflow";

import ReactFlowWrapper from "./ReactFlorWrapper";


const WorkflowViewer = forwardRef(({workflow, definition, onStepClicked, onStepUpdated, onStepRemoved, onAddStep}, ref) => {

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [initialized, setInitialized] = useState(false);
    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

    const reactFlowBox = useRef();
    const reactFlowWrapper = useRef();
    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    const [components, setComponents] = useState(null);

    useEffect(() => {
        if (definition) {
            let comps = {};
            definition.forEach((def) => comps[def.type] = def);
            if (!components || !lodash.isEqual(comps, components)) {
                setComponents(comps);
            }
        }
    }, [definition, components]);

    function mountNode(step, position, selected) {
        const componentDefinition = components[step.type];
        return {
            data: {label: step.name, typeIcon: componentDefinition.icon, uuid: step.uuid, destinations: step.parameters?.destinations},
            id: step.uuid,
            type: componentDefinition.nodeType,
            position,
            selected
        };
    }

    function mountWorkflow(workflow, selectedUuid) {
        setInitialized(false);
        let nodes = [];
        let links = [];

        const steps = orderWorkflowSteps(workflow.steps);
        if (!steps || steps.length === 0) {
            setNodes(nodes);
            setEdges(links);
            setInitialized(true);
            return;
        }

        const COMPONENT_WIDTH = 250;
        const COMPONENT_Y_SPACING = 100;

        let ignoredStepsWidth = [];
        function calculateWidth(step, allSteps) {
            const componentDefinition = components[step.type];
            ignoredStepsWidth.push(step.uuid);
            let width;
            if (componentDefinition.nodeType === "multi_destination") {
                let childrenWidth = 0;
                if (step.parameters?.destinations) {
                    for (const destinationCondition of step.parameters.destinations) {
                        const links = step.nextStepsLinks.filter((link) => link.originConditionUuid === destinationCondition.uuid);
                        for (const link of links) {
                            if (!ignoredStepsWidth.includes(link.destinationUuid)) {
                                const nextStep = allSteps.find((step) => step.uuid === link.destinationUuid);
                                childrenWidth += calculateWidth(nextStep, allSteps);
                            }
                        }
                    }
                }
                width = Math.max(childrenWidth, COMPONENT_WIDTH);
            } else {
                let childrenWidth = 0;
                for (const stepLink of step.nextStepsLinks) {
                    if (!ignoredStepsWidth.includes(stepLink.destinationUuid)) {
                        const nextStep = allSteps.find((step) => step.uuid === stepLink.destinationUuid);
                        childrenWidth += calculateWidth(nextStep, allSteps);
                    }
                }
                width = Math.max(childrenWidth, COMPONENT_WIDTH);
            }
            step.width = width;
            return width;
        }



        let ignoredStepsPosition = [];
        let lastY = 0;
        function calculatePosition(step, allSteps) {
            function defineXYForStep(step, x, y) {
                step.x = x;
                step.y = y;
                if (step.y > lastY) {
                    lastY = step.y;
                }
            }

            ignoredStepsPosition.push(step.uuid);
            const componentDefinition = components[step.type];
            let childrenCount = step.nextStepsLinks.length;
            if (childrenCount === 1) {
                const destinationUuid = step.nextStepsLinks[0].destinationUuid;
                if (!ignoredStepsPosition.includes(destinationUuid)) {
                    const nextStep = allSteps.find((s) => s.uuid === destinationUuid);
                    defineXYForStep(nextStep, step.x, step.y + COMPONENT_Y_SPACING);
                    calculatePosition(nextStep, allSteps);
                }
            } else if (childrenCount > 1) {
                let nextX = step.x - (step.width / 2 - COMPONENT_WIDTH / 2);
                if (componentDefinition.nodeType === "multi_destination") {
                    for (const destinationCondition of step.parameters.destinations) {
                        const links = step.nextStepsLinks.filter((link) => link.originConditionUuid === destinationCondition.uuid);
                        for (const link of links) {
                            if (!ignoredStepsPosition.includes(link.destinationUuid)) {
                                const nextStep = allSteps.find((step) => step.uuid === link.destinationUuid);
                                defineXYForStep(nextStep, nextX, step.y + COMPONENT_Y_SPACING);
                                calculatePosition(nextStep, allSteps);
                                nextX += nextStep.width;
                            }
                        }
                    }
                } else {
                    for (const stepLink of step.nextStepsLinks) {
                        if (!ignoredStepsPosition.includes(stepLink.destinationUuid)) {
                            const nextStep = allSteps.find((step) => step.uuid === stepLink.destinationUuid);
                            defineXYForStep(nextStep, nextX, step.y + COMPONENT_Y_SPACING);
                            calculatePosition(nextStep, allSteps);
                            nextX += nextStep.width;
                        }
                    }
                }
            }
        }
        calculateWidth(steps[0], steps);

        const firstItem = steps[0];
        firstItem.x = firstItem.width / 2 - COMPONENT_WIDTH / 2;
        firstItem.y = 50;
        calculatePosition(firstItem, steps);

        let hasMore = true;
        while (ignoredStepsPosition.length > steps.length && hasMore) {
            const newSteps = steps.filter((step) => !ignoredStepsPosition.includes(step.uuid));
            if (newSteps.length > 0) {
                const nextStep = newSteps[0];
                nextStep.x = firstItem.x;
                nextStep.y = lastY + COMPONENT_Y_SPACING;
                lastY = nextStep.y;
                calculatePosition(nextStep, steps);
            } else {
                hasMore = false;
            }
        }


        for (const step of steps) {
            if (step.x !== undefined && step.y !== undefined) {
                nodes.push(mountNode(step, {x: step.x, y: step.y}, selectedUuid === step.uuid));
            }
        }

        for (const step of workflow.steps) {
            const multiDestination = (components[step.type].nodeType === "multi_destination");
            for (const stepLink of step.nextStepsLinks) {
                const internalUuid = step.uuid + "-" + stepLink.destinationUuid;
                let link = {id: internalUuid, source: step.uuid, target: stepLink.destinationUuid};
                if (multiDestination && stepLink.originConditionUuid) {
                    link.sourceHandle = stepLink.originConditionUuid;
                }
                links.push(link);
            }
        }

        setNodes(nodes);
        setEdges(links);

        setInitialized(true);
    }


    //ADD The initial steps and links
    useEffect(() => {
        if (!workflow?.uuid || !components) {
            return;
        }
        mountWorkflow(workflow);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflow.uuid, components]);


    //If a node is deleted, we need to delete the corresponding step.
    useEffect(() => {
        if (!initialized) {
            return;
        }

        const stepsToDelete = workflow.steps
            .filter(step => !nodes.find((node) => node.id === step.uuid));

        if (stepsToDelete.length > 0) {
            stepsToDelete.forEach(step => onStepRemoved(step));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized, workflow.steps, nodes]);


    //If an edge is added or deleted, we need to reflect that on the next step links
    useEffect(() => {
        if (!initialized) {
            return;
        }

        for (const step of workflow.steps) {
            let newLinks = [...step.nextStepsLinks];
            const componentDefinition = components[step.type];
            const multiDestination = (componentDefinition.nodeType === "multi_destination");

            const stepEdges = edges.filter(edge => edge.source === step.uuid);

            const filterValidatorEdgeAndLink = (edge, link) =>
                edge.source === link.originUuid &&
                edge.target === link.destinationUuid &&
                (!multiDestination || edge.sourceHandle === link.originConditionUuid);

            const filterValidatorLinkAndLink = (linkA, linkB) =>
                linkA.originUuid === linkB.originUuid &&
                linkA.destinationUuid === linkB.destinationUuid &&
                (!multiDestination || linkA.originConditionUuid === linkB.originConditionUuid);



            const edgesToCreate = stepEdges.filter(edge => !newLinks.find(link => filterValidatorEdgeAndLink(edge, link)));
            edgesToCreate.forEach(edge => newLinks.push({
                originUuid: edge.source,
                destinationUuid: edge.target,
                originConditionUuid: multiDestination ? edge.sourceHandle : undefined
            }));

            const linksToRemove = newLinks.filter(link => !edges.find(edge => filterValidatorEdgeAndLink(edge, link)));
            newLinks = newLinks.filter(link => !linksToRemove.find(remove => filterValidatorLinkAndLink(remove, link)));

            if (!lodash.isEqual(newLinks, step.nextStepsLinks)) {
                onStepUpdated({
                    ...step,
                    nextStepsLinks: newLinks
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized, workflow.steps, edges]);


    useImperativeHandle(ref, () => ({
        addStep(step, position) {
            let nodePosition = position;

            if (!nodePosition) {
                let bottomNode = null;
                nodes.forEach((node) => {
                    if (!bottomNode || node.position.y > bottomNode.position.y) {
                        bottomNode = node;
                    }
                });
                let newY = bottomNode ? bottomNode.position.y + bottomNode.height + 40 : 50;
                nodePosition = {x: 50, y: newY};
            }

            const node = mountNode(step, nodePosition);
            setNodes([...nodes, node]);
        },
        updateStep(step) {
            const newNodes = [...nodes];
            const index = newNodes.findIndex((node) => node.id === step.uuid);
            if (index !== -1) {
                const node = newNodes[index];
                newNodes[index] = {
                    ...node,
                    data: {
                        ...node.data,
                        label: step.name,
                        destinations: step.parameters?.destinations
                    }
                };
                setNodes(newNodes);
                reactFlowWrapper.current?.updateNodeInternals(node.id);
            }
        },
        updateAllWorkflow(workflow, selectedUuid) {
            mountWorkflow(workflow, selectedUuid);
        }
    }));

    const handleSelectionChange = ({nodes}) => {
        if (nodes && nodes.length > 0) {
            const step = workflow.steps.find((step) => step.uuid === nodes[0].data.uuid);
            onStepClicked(step);
        } else {
            onStepClicked(null);
        }
    };


    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();
            const step = JSON.parse(event.dataTransfer.getData("application/babel-workflow"));
            if (typeof step === "undefined" || !step) {
                return;
            }

            const reactFlowBounds = reactFlowBox.current.getBoundingClientRect();
            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });
            onAddStep(step, position);
        },
        [reactFlowInstance, onAddStep]
    );


    return <ReactFlowProvider>
        <Box sx={{width: "100%"}} ref={reactFlowBox}>
            <ReactFlowWrapper
                ref={reactFlowWrapper}
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onDrop={onDrop}
                onInit={setReactFlowInstance}
                onDragOver={onDragOver}
                onSelectionChange={handleSelectionChange}
                onConnect={onConnect}/>
        </Box>
    </ReactFlowProvider>;

});

export default WorkflowViewer;