import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Collapse, List, ListItem, ListItemText } from "@mui/material";
import { DatasetRounded, ExpandLess, ExpandMore, FeedRounded, KeyRounded } from "@mui/icons-material";

import { useDialog } from "~/providers/dialog";
import styles from "./WorkflowToolbox.module.css";
import api from "~/api";
import { LoadingCentered } from "~/components";
import { useToast } from "~/providers/dialog";
import { useSetSidebarFormsAtom } from "~/providers/sidebar-forms.atom";
import {formatDateTime} from "~/utils/date";

const LibraryTab = ({ hidden, workflow, hasChanges, reloadWorkflow }) => {
    const [openForms, setOpenForms] = useState(false);
    const [openVersions, setOpenVersions] = useState(false);
    const [openDatasets, setOpenDatasets] = useState(false);
    const [openCredentials, setOpenCredentials] = useState(false);

    const [datasets, setDatasets] = useState(null);
    const [loadingDatasets, setLoadingDatasets] = useState(false);

    const [credentials, setCredentials] = useState(null);
    const [loadingCredentials, setLoadingCredentials] = useState(false);
    const setSidebarFormsAtom = useSetSidebarFormsAtom();

    const showToast = useToast();
    const showDialog = useDialog();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchDatasets() {
            try {
                setLoadingDatasets(true);
                const datasets = await api.datasets.list();
                setDatasets(datasets);
                setLoadingDatasets(false);
            } catch (e) {
                setLoadingDatasets(false);
            }
        }

        async function fetchCredentials() {
            setLoadingCredentials(true);
            try {
                const credentials = await api.credentials.list();
                setCredentials(credentials);
                setLoadingCredentials(false);
            } catch (e) {
                console.log(e);
                setLoadingCredentials(false);
            }
        }

        fetchDatasets().then();
        fetchCredentials().then();
    }, []);

    const editForm = (form) => {
        if (hasChanges) {
            showDialog("Atenção!", "Você precisa salvar o fluxo antes de editar o formulário.");
        } else {
            navigate(`/forms/${form.uuid}/edit`);
        }
    };

    const changeVersion  = async (uuid, versionUuid) => {
        if (hasChanges) {
            showDialog("Atenção!", "Você precisa salvar o fluxo antes de mudar a versão.");
        } else {
            try{
                await api.workflows.changeWorkflowVersion(uuid, versionUuid);
                reloadWorkflow();
            }catch (e) {
                showDialog("Não foi possível atualizar a versão", e);
            }
        }
    };


    function viewDataset(dataset) {
        if (hasChanges) {
            showDialog("Atenção!", "Você precisa salvar o fluxo antes de editar o formulário.");
        } else {
            navigate(`/datasets/${dataset.uuid}`);
        }
    }
    const viewCredential = (credential) => {
        if (hasChanges) {
            showDialog("Atenção!", "Você precisa salvar o fluxo antes de editar o formulário.");
        } else {
            navigate(`/credentials/${credential.uuid}`);
        }
    };

    const toggleForms = () => {
        setOpenForms(!openForms);
    };
    const toggleVersions = () => {
        setOpenVersions(!openVersions);
    };
    const toggleDatasets = () => {
        setOpenDatasets(!openDatasets);
    };
    const toggleCredentials = () => {
        setOpenCredentials(!openCredentials);
    };

    const handleNewDataset = () => {
        if (hasChanges) {
            showDialog("Atenção!", "Você precisa salvar o fluxo antes de criar um novo dataset.");
        } else {
            navigate("/datasets/new");
        }
    };
    const handleNewCredential = () => {
        if (hasChanges) {
            showDialog("Atenção!", "Você precisa salvar o fluxo antes de criar uma nova credencial.");
        } else {
            navigate("/credentials/new");
        }
    };

    const handleImportForm = () => {
        //Open dialog to select json file
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".json";
        input.onchange = async (event) => {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = async (event) => {
                try {
                    const json = JSON.parse(event.target.result);
                    if (json.voxit_version === "1.0") {
                        const form = await api.forms.create({
                            ...json,
                            workflowUuid: workflow.uuid
                        });
                        setSidebarFormsAtom((forms) => [...forms, form]);
                        reloadWorkflow();
                        showToast("Formulário importado com sucesso!");
                    } else {
                        showDialog("Erro ao importar o formulário.", "O arquivo selecionado é inválido.");
                    }
                } catch (e) {
                    showDialog("Erro ao importar o formulário", e);
                }
            };
            reader.readAsText(file);
        };
        input.click();
    };

    const sectionProps = { color: "primary", variant: "h6", sx: { userSelect: "none" } };

    return <List hidden={hidden} sx={{ overflowY: "auto" }}>
        <ListItem onClick={toggleForms} sx={{ background: "#F6F6F6" }}>
            <FeedRounded color={"primary"} sx={{ mr: 1 }} />
            <ListItemText primaryTypographyProps={sectionProps} primary={"Formulários"} />
            {openForms ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openForms} timeout="auto" unmountOnExit>
            {workflow?.forms?.map(form =>
                <ListItem sx={{ pl: 5 }} className={styles.workflowComponent} key={form.uuid}>
                    <ListItemText primary={form.title} />
                    <Button variant={"outlined"} sx={{ flexShrink: 0 }} onClick={() => editForm(form)}>Editar</Button>
                </ListItem>)}
            <ListItem className={styles.workflowComponent}>
                <Button variant={"outlined"} fullWidth onClick={handleImportForm}>
                    Importar Formulário
                </Button>
            </ListItem>
        </Collapse>
        <ListItem onClick={toggleVersions} sx={{ background: "#F6F6F6" }}>
            <FeedRounded color={"primary"} sx={{ mr: 1 }} />
            <ListItemText primaryTypographyProps={sectionProps} primary={"Versões"} />
            {openVersions ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openVersions} timeout="auto" unmountOnExit>
            {workflow?.versions?.map(version =>
                <ListItem sx={{ pl: 5 }} className={styles.workflowComponent} key={version.uuid}>
                    <ListItemText primary={`${version.version} - ${formatDateTime(version.createdAt)}`} />
                    <Button disabled={version.enabled} variant={"outlined"} sx={{ flexShrink: 0 }} onClick={() => changeVersion(workflow.uuid, version.uuid)}>Usar</Button>
                </ListItem>)}
        </Collapse>
        <ListItem onClick={toggleDatasets} sx={{ background: "#F6F6F6" }}>
            <DatasetRounded color={"primary"} sx={{ mr: 1 }} />
            <ListItemText primaryTypographyProps={sectionProps} primary={"Datasets"} />
            {openDatasets ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openDatasets}>
            {loadingDatasets && <LoadingCentered />}
            {datasets?.map(dataset =>
                <ListItem sx={{ pl: 5 }} className={styles.workflowComponent} key={dataset.uuid}>
                    <ListItemText primary={dataset.name} />
                    <Button variant={"outlined"} sx={{ flexShrink: 0 }} onClick={() => viewDataset(dataset)}>Visualizar</Button>
                </ListItem>)}
            <ListItem>
                <Button variant={"outlined"} fullWidth onClick={handleNewDataset}>
                    Novo dataset
                </Button>
            </ListItem>
        </Collapse>
        <ListItem onClick={toggleCredentials} sx={{ background: "#F6F6F6" }}>
            <KeyRounded color={"primary"} sx={{ mr: 1 }} />
            <ListItemText primaryTypographyProps={sectionProps} primary={"Credenciais"} />
            {openCredentials ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openCredentials}>
            {loadingCredentials && <LoadingCentered />}
            {credentials?.map(credential =>
                <ListItem sx={{ pl: 5 }} className={styles.workflowComponent} key={credential.uuid}>
                    <ListItemText primary={credential.name} />
                    <Button variant={"outlined"} sx={{ flexShrink: 0 }} onClick={() => viewCredential(credential)}>Visualizar</Button>
                </ListItem>)}
            <ListItem>
                <Button variant={"outlined"} fullWidth onClick={handleNewCredential}>
                    Nova credencial
                </Button>
            </ListItem>
        </Collapse>
    </List>;
};

export default LibraryTab;