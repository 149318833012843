import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
    TableHead,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button
} from "@mui/material";
import {AddRounded, Delete, Visibility} from "@mui/icons-material";

import api from "~/api";
import {MainPage, Navbar, LoadingBar} from "~/components";
import {useDialog, useToast} from "~/providers/dialog";
import {formatDateTime} from "~/utils/date";
import {useConfirm} from "material-ui-confirm";

const CompanyList = () => {
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(false);
    const showDialog = useDialog();
    const showToast = useToast();
    const confirm = useConfirm();

    const fetchData = async () => {
        try {
            setLoading(true);
            const data = await api.companies.list();
            setCompanies(data);
        } catch (e) {
            showDialog("Não foi possível obter as empresas.", e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRemove = (uuid) => {
        confirm(
            {
                description: "Tem certeza que deseja remover esta empresa?",
                confirmationText: "Excluir",
                cancellationText: "Cancelar",
                confirmationButtonProps: {color: "error"},
            }).then(async () => {
            try {
                await api.companies.remove(uuid);
                showToast("Empresa removida com sucesso!");
                fetchData().then();
            } catch (e) {
                showDialog("Não foi possível remover a empresa.", e);
            }
        }).catch(() => {
        });
    };

    return <MainPage>
        {
            loading ? <LoadingBar/> :
                <>
                    <Navbar title="Empresas">
                        <Button component={Link} to={"/companies/new"} variant={"outlined"} color={"white"}>
                            <AddRounded sx={{mr: 1}}/>
                            Nova empresa
                        </Button>
                    </Navbar>
                    <TableContainer>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Data de criação</TableCell>
                                    <TableCell align="right">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {companies.map((obj) => (
                                    <TableRow
                                        key={obj.uuid}
                                        sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                    >
                                        <TableCell>{obj.name}</TableCell>
                                        <TableCell>{formatDateTime(obj.createdAt)}</TableCell>
                                        <TableCell align="right">
                                            <Button startIcon={<Delete/>} sx={{mr: 2}} color={"warning"} onClick={() => handleRemove(obj.uuid)} variant="outlined">Remover</Button>
                                            <Button startIcon={<Visibility/>} component={Link} to={`/companies/${obj.uuid}`} variant="outlined">Editar</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
        }</MainPage>;
};
export default CompanyList;