import {Box, Button, Card, CardContent, CardHeader, Collapse, Divider, List, ListItem, ListItemText, Typography} from "@mui/material";
import {Crop32, CropPortrait, ExpandLess, ExpandMore, Minimize} from "@mui/icons-material";
import React, {useEffect, useRef, useState} from "react";

import {orderWorkflowSteps} from "~/utils/workflow";

import {WorkflowToolboxWidth} from "../index";
import styles from "./DynamicInfoPanel.module.css";
import Grid from "@mui/material/Grid";
import {SIDEBAR_DRAWER_WIDTH} from "~/components/Sidebar";
import useAppBarHeight from "~/utils/appbar";
import {getValueFromKeyPath, returnObjectWithValueAtKeyPath} from "~/utils/keypath";
import {FlexBox} from "~/components";
import {RenderScript} from "./RenderScript";

function CollapsableObject({name, items, sx, onInsert, path}) {
    const [isCollapsed, setCollapsed] = useState(false);
    const handleToggleCollapse = () => {
        setCollapsed(!isCollapsed);
    };

    const handleInsert = (event) => {
        event.stopPropagation();
        onInsert(path);
    };

    return <>
        <ListItem tabIndex="0" name={"dynamicItem"} onClick={handleToggleCollapse} sx={sx} className={styles.item}>
            <ListItemText primary={name}/>
            <Button onClick={handleInsert} name={"insertButton"} className={styles.itemAddButton} variant={"outlined"} size={"small"} sx={{ml: 3}}>Adicionar</Button>
            {items && items.properties && (isCollapsed ? <ExpandLess color={"primary"}/> : <ExpandMore color={"primary"}/>)}
        </ListItem>
        <Collapse in={isCollapsed}>
            {items?.properties && Object.keys(items.properties).map((key => {
                return <CollapsableObject sx={{pl: (sx?.pl || 2) + 3}} path={`${path}|${key}`} key={key} name={key} items={items.properties[key]} onInsert={onInsert}/>;
            }))}
        </Collapse>
    </>;
}

const DynamicInfoPanelSize = {
    collapsed: "collapsed", normal: "normal", expanded: "expanded"
};

export function DynamicInfoPanel({currentParameter, visible, selectedStep, workflow, onChange, nameForKeyword}) {
    const [panelSize, setPanelSize] = useState(DynamicInfoPanelSize.normal);
    const [addingScript, setAddingScript] = useState(false);
    const [orderedSteps, setOrderedSteps] = useState([]);
    const [previousSteps, setPreviousSteps] = useState([]);
    const [isEditingScript, setEditingScript] = useState(false);
    const [, setCurrentScriptSelection] = useState([-1, -1]);
    const [script, setScript] = useState("");
    const [currentParameterCache, setCurrentParameterCache] = useState(null);
    const appBarHeight = useAppBarHeight();
    const isExpanded = panelSize === DynamicInfoPanelSize.expanded;

    const scriptEditorRef = useRef();


    useEffect(() => {
        if (currentParameter) {
            setCurrentParameterCache(currentParameter);
            let currentValue = selectedStep.parameters ? selectedStep.parameters[currentParameter.key] : null;
            if (currentParameter.keyPath) {
                currentValue = getValueFromKeyPath(currentParameter.keyPath, selectedStep.parameters);
            }

            if (currentValue && currentValue.startsWith("{{SCRIPT}}")) {
                setPanelSize(DynamicInfoPanelSize.expanded);
                setAddingScript(true);
                setScript(currentValue.substring(10));
            } else {
                setEditingScript(false);
                setAddingScript(false);
                setScript("");
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentParameter]);


    useEffect(() => {
        if (workflow.steps) {
            const steps = orderWorkflowSteps(workflow.steps);
            setOrderedSteps(steps);
        }
    }, [workflow.steps]);

    useEffect(() => {
        if (orderedSteps && selectedStep) {
            const currentStepOrder = orderedSteps.find(step => step.uuid === selectedStep.uuid);
            setPreviousSteps(currentStepOrder ? orderedSteps.filter((step) => step.order < currentStepOrder.order) : []);
        }
    }, [orderedSteps, selectedStep]);

    if (!selectedStep) {
        return <></>;
    }
    const internalOnChange = (value) => {


        if (currentParameterCache.keyPath) {
            const current = currentParameterCache.keyPath.split(".")[0];
            const objectName = current.endsWith("[]") ? current.substring(0, current.length - 2) : current;
            const object = returnObjectWithValueAtKeyPath(currentParameterCache.keyPath, selectedStep.parameters, value);
            onChange({
                target: {
                    name: objectName, value: object
                }
            });
        } else {
            onChange({
                target: {
                    name: currentParameterCache.key, value: value
                }
            });
        }
    };

    const handleInsert = (variable) => {
        if (isEditingScript) {
            //UseForwardRef to send to dispatch
            if (scriptEditorRef.current) {
                scriptEditorRef.current.insert(`{{${variable}}}`);
            }
            // const [start, end] = currentScriptSelection;
            // setScript((script) => `${script.substring(0, start)}${script.substring(end)}`);
        } else {
            //TODO: Insert where user has current caret positioned, and not at the end of the text
            const newText = (selectedStep?.parameters[currentParameterCache.key] || "") + `{{${variable}}}`;
            internalOnChange(newText);
        }
    };

    const handleAddScript = () => {
        setPanelSize(DynamicInfoPanelSize.expanded);
        setAddingScript(true);
    };
    const handleChangePanelSize = (size) => {
        setPanelSize(size);
        if (size !== DynamicInfoPanelSize.expanded) {
            setAddingScript(false);
        }
    };

    /*
    SCRIPT stuff // perhaps should be isolated
     */

    function renderVariables() {
        const showCardActions = !isExpanded || !addingScript;
        return <>
            <CardHeader
                title={"Variáveis Dinâmicas"}
                titleTypographyProps={{variant: "body", sx: {fontWeight: "bold"}}}
                action={showCardActions && <CardActions panelSize={panelSize} onChange={setPanelSize}/>}>
            </CardHeader>
            <Divider/>
            <Collapse sx={{flexGrow: 1, display: "flex"}} in={panelSize !== DynamicInfoPanelSize.collapsed} timeout="auto">
                <Box sx={{display: "flex", flexDirection: "column", height: "100%"}}>
                    <CardContent sx={{
                        minHeight: "280px", maxHeight: isExpanded ? undefined : "80vh", flexGrow: 1, overflowY: "auto", padding: "0"
                    }}>
                        {previousSteps.length === 0 && <Typography sx={{textAlign: "center", mt: 2}}>Nenhuma etapa anterior.</Typography>}
                        <List>
                            {previousSteps.map(step => {
                                return <CollapsableObject
                                    key={step.uuid}
                                    path={step.uuid}
                                    name={`${step.order}. ${step.name}`}
                                    items={step.outputSchema}
                                    onInsert={handleInsert}/>;
                            })}
                        </List>
                    </CardContent>
                    {!addingScript && <Button variant={"contained"} sx={{margin: "10px", width: "calc(100% - 20px)"}} name="dynamicPanel" onClick={handleAddScript}>
                        Adicionar script
                    </Button>}
                </Box>
            </Collapse>
        </>;
    }

    return <Card
        elevation={10}
        sx={{
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            bottom: 20,
            top: isExpanded ? appBarHeight + 20 : undefined,
            pointerEvents: visible || isEditingScript ? "" : "none",
            opacity: visible || isEditingScript ? 1 : 0,
            right: WorkflowToolboxWidth + 20,
            minWidth: isExpanded ? `calc(100% - ${WorkflowToolboxWidth + 20 + SIDEBAR_DRAWER_WIDTH + 20}px)` : "300px",
            transition: "opacity 250ms"
        }}
        name="dynamicPanel"
        tabIndex={0}>
        <FlexBox grow horizontal>
            <FlexBox grow={(!isExpanded || !addingScript)} vertical>
                {renderVariables()}
            </FlexBox>
            {isExpanded && addingScript && <>
                <Divider sx={{flexShrink: 0, width: "10px"}}/>
                <FlexBox grow vertical>
                    <CardHeader
                        title={"Script"}
                        titleTypographyProps={{variant: "body", sx: {fontWeight: "bold"}}}
                        action={<CardActions panelSize={panelSize} onChange={handleChangePanelSize}/>}>
                    </CardHeader>
                    <Divider/>
                    <RenderScript
                        ref={scriptEditorRef}
                        setAddingScript={setAddingScript}
                        script={script}
                        setScript={setScript}
                        setCurrentScriptSelection={setCurrentScriptSelection}
                        setEditingScript={setEditingScript}
                        nameForKeyword={nameForKeyword}
                        onChange={internalOnChange}/>
                </FlexBox>
            </>}
        </FlexBox>
    </Card>;
}


function CardActions({panelSize, onChange}) {
    function renderButton(status, icon) {
        return <Button
            variant={panelSize === status ? "contained" : "outlined"}
            onClick={() => onChange(status)}
            sx={{minWidth: 0, padding: 0}}
            name="dynamicPanel">
            {icon}
        </Button>;
    }


    return <Grid container gap={1}>
        {renderButton(DynamicInfoPanelSize.expanded, <CropPortrait/>)}
        {renderButton(DynamicInfoPanelSize.normal, <Crop32/>)}
        {renderButton(DynamicInfoPanelSize.collapsed, <Minimize/>)}
    </Grid>;
}