import React, {useState} from "react";
import {Button, CardActions, CardContent, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField, Typography} from "@mui/material";

const RenderApproval = ({step, onSave}) => {

    const [info, setInfo] = useState({
        approved: "",
        reason: ""
    });

    const handleSave = () => {
        if (info.approved !== "") {
            onSave(info);
        }
    };

    const handleChange = (event) => {
        setInfo({
            ...info,
            [event.target.name]: event.target.value
        });
    };

    return <>
        <CardContent>
            <Stack spacing={3}>
                <CardContent>
                    <Stack>
                        <Typography variant={"h6"}>
                            {step?.parameters?.message}
                        </Typography>

                        <FormControl fullWidth sx={{mt: 3}}>
                            <FormLabel>Aprovação</FormLabel>
                            <RadioGroup row name="approved" value={info.approved} onChange={handleChange}>
                                <FormControlLabel value="false" control={<Radio/>} label="Não aprovado"/>
                                <FormControlLabel value="true" control={<Radio/>} label="Aprovado"/>
                            </RadioGroup>
                        </FormControl>


                        <TextField sx={{mt: 3}} fullWidth label="Motivo" placeholder="Motivo" name="reason" onChange={handleChange} value={info.reason} multiline minRows={5} type="text"/>
                    </Stack>
                </CardContent>
            </Stack>
        </CardContent>
        <Divider/>
        <CardActions sx={{justifyContent: "flex-end"}}>
            <Button variant="contained" onClick={handleSave}>Salvar</Button>
        </CardActions>
    </>;
};
export default RenderApproval;