import React, {useEffect, useState} from "react";
import {Button, CircularProgress, FormControl, TextField} from "@mui/material";

import api from "~/api";
import {useDialog} from "~/providers/dialog";

export function RenderButtonWithResult({step, onChange, param, parameters, showResult}) {
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const showDialog = useDialog();

    useEffect(() => {
        if (parameters[param.key]) {
            setResult(JSON.stringify(parameters[param.key], null, "    "));
        }
    }, [param, parameters]);

    async function handleAction() {
        setLoading(true);
        try {
            const stepWithParams = {
                ...step,
                action: param.key,
                parameters
            };
            const result = await api.workflows.runAction(stepWithParams);
            const keys = Object.keys(result);
            for (const key of keys) {
                onChange({
                    target: {
                        name: key,
                        value: result[key]
                    }
                });
            }
        } catch (e) {
            showDialog("Não foi possível executar a ação.", e.message || e);
        }
        setLoading(false);
    }

    return (
        <FormControl fullWidth>
            <Button onClick={handleAction} variant={"outlined"}>{param.label}</Button>
            {loading && <CircularProgress sx={{alignSelf: "center", mt: 2}} size={"30px"}/>}
            {showResult && result &&
                <TextField
                    inputProps={{sx: {fontSize: "0.9rem", lineHeight: "1.3rem"}}}
                    sx={{mt: 2}}
                    multiline={true}
                    minRows={5}
                    label="Resultado"
                    value={result}
                    type="text"/>
            }
        </FormControl>);

}