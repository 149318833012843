import React, {useCallback, useEffect, useState} from "react";
import {FormControl, List, ListItem, TextField} from "@mui/material";
import lodash from "lodash";
import {RenderParam} from "./fieldRenders/renderParam";
import {DynamicInfoPanel} from "./dynamicInfoPanel";
import {orderWorkflowSteps} from "~/utils/workflow";

export default function PropertiesTab({workflow, hidden, selectedStep, definition, onStepUpdated}) {

    // const [hasChanges, setHasChanges] = useState(false);
    const [selectedStepLocalCopy, setSelectedStepLocalCopy] = useState(null);
    const [currentParameter, setCurrentParameter] = useState(null);
    const [orderedStepsMap, setOrderedStepsMap] = useState({});
    useEffect(() => {
        if (workflow?.steps) {
            const ordered = orderWorkflowSteps(workflow.steps);
            let map = {};
            if (ordered) {
                for (const step of ordered) {
                    map[step.uuid] = `${step.order}. ${step.name}`;
                }
            }
            setOrderedStepsMap(map);
        }
    }, [workflow?.steps]);

    const onStepUpdatedLocal = useCallback((step) => {
        onStepUpdated(step);
    }, [onStepUpdated]);

    useEffect(() => {
        if (selectedStep) {
            setSelectedStepLocalCopy({...selectedStep});
        }
    }, [selectedStep]);


    useEffect(() => {
        if (selectedStepLocalCopy && selectedStep) {
            if (!lodash.isEqual(selectedStepLocalCopy, selectedStep)) {
                const token = setTimeout(() => {
                    onStepUpdatedLocal(selectedStepLocalCopy);
                }, 300);
                return () => clearTimeout(token);
            }
        }
    }, [selectedStepLocalCopy, selectedStep, onStepUpdatedLocal]);

    const handleStepParameterChange = (event) => {
        const {name, value} = event.target;
        if (name === "step_name") {
            setSelectedStepLocalCopy((selectedStepLocalCopy) => {
                return {...selectedStepLocalCopy, "name": value};
            });
        } else {
            setSelectedStepLocalCopy((selectedStepLocalCopy) => {
                const parameters = {
                    ...selectedStepLocalCopy.parameters,
                };
                if (value && ((value instanceof Object) || value.length > 0)) {
                    parameters[name] = value;
                } else {
                    delete parameters[name];
                }
                return {
                    ...selectedStepLocalCopy,
                    parameters: {...parameters}
                };
            });
        }
    };

    const handleNameForKeyword = useCallback((keyword) => {
        const splitted = keyword.split("|");
        const uuid = splitted.shift();
        const stepName = orderedStepsMap[uuid] || uuid;
        return [stepName, ...splitted].join(" - ");
    }, [orderedStepsMap]);

    const currentDefinition = selectedStepLocalCopy ? definition.find((def) => def.type === selectedStepLocalCopy.type) : {};
    if (!currentDefinition) {
        console.log("Este workflow está utilizando um componente não disponível: " + selectedStepLocalCopy.type);
        return <></>;
    }
    return <>
        <List hidden={hidden} sx={{overflowY: "auto"}}>
            {selectedStepLocalCopy &&
                <>
                    <ListItem>
                        <FormControl fullWidth>
                            <TextField
                                type="text"
                                label="Nome da etapa"
                                name={"step_name"}
                                value={selectedStepLocalCopy.name}
                                onChange={handleStepParameterChange}/>
                        </FormControl>
                    </ListItem>

                    {currentDefinition.parameters?.map(param => (
                        <RenderParam
                            workflow={workflow}
                            onFocus={setCurrentParameter}
                            key={param.key}
                            nameForKeyword={handleNameForKeyword}
                            step={selectedStepLocalCopy}
                            parameters={selectedStepLocalCopy.parameters}
                            param={param}
                            onChange={handleStepParameterChange}/>))}
                </>}
        </List>
        <DynamicInfoPanel
            currentParameter={currentParameter}
            visible={!!selectedStep && !!currentParameter}
            selectedStep={selectedStepLocalCopy}
            workflow={workflow}
            nameForKeyword={handleNameForKeyword}
            onChange={handleStepParameterChange}/>
    </>;
}


