import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

import api from "~/api";
import {Navbar, MainPage, LoadingBar} from "~/components";
import {useDialog} from "~/providers/dialog";
import {formatDateTime} from "~/utils/date";
import {useConfirm} from "material-ui-confirm";

const DatasetDetail = () => {
    const {uuid} = useParams();
    const [dataset, setDataset] = useState(null);
    const [properties, setProperties] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    const showDialog = useDialog();
    const confirm = useConfirm();

    useEffect(() => {
        if (uuid) {
            const loadData = async () => {
                setLoading(true);
                try {
                    const data = await api.datasets.detail(uuid);
                    setDataset(data);
                    setProperties(data.schema?.items?.properties);
                } catch (e) {
                    showDialog("Não foi possível carregar o dataset.", e);
                }
                setLoading(false);
            };
            loadData().then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const handleClearData = async () => {
        confirm({
            description: "Tem certeza que deseja limpar os dados?",
            confirmationText: "Limpar",
            cancellationText: "Cancelar",
            confirmationButtonProps: { color: "error" },
        }).then(async () => {
            setSaveLoading(true);
            try {
                await api.datasets.clear(uuid);
                setDataset({...dataset, data: []});
            } catch (e) {
                showDialog("Não foi possível limpar os dados do dataset.", e);
            }
            setSaveLoading(false);
        }).catch(() => {
        });
    };


    return <MainPage>
        {loading && <LoadingBar/>}
        {!loading && dataset &&
            <>
                <Navbar
                    title={dataset.name} sx={{zIndex: (theme) => theme.zIndex.drawer + 100}}
                    subtitle={dataset.dataUpdatedAt && `Última atualização: ${formatDateTime(dataset.dataUpdatedAt)}`}>
                    <Button sx={{ml: 1}} onClick={handleClearData} variant={"contained"} color={"info"}>
                        Limpar dados {saveLoading ? <CircularProgress sx={{color: "#FFFFFF", ml: 2}} size={30}/> : ""}
                    </Button>
                </Navbar>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {properties && Object.keys(properties).map((key) => (
                                    <TableCell key={key}>{key}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataset.data?.map((data, index) => (
                                <TableRow
                                    key={index}
                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                    {properties && Object.keys(properties).map((key) => (
                                        <TableCell key={key}>
                                            {typeof data[key] === "string" && data[key]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>}
    </MainPage>;
};

export default DatasetDetail;