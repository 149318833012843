const users = (apiClient, handleError) => {
    return {
        list: async () => {
            try {
                const {data} = await apiClient.get("/v1/users");
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        detail: async (uuid) => {
            try {
                const {data} = await apiClient.get(`/v1/users/${uuid}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        create: async (user) => {
            try {
                const {data} = await apiClient.post("/v1/users", user);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        update: async (uuid, user) => {
            try {
                const {data} = await apiClient.put(`/v1/users/${uuid}`, user);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        remove: async (uuid) => {
            try {
                await apiClient.delete(`/v1/users/${uuid}`);
            } catch (error) {
                handleError(error);
            }
        },
    };
};
export default users;