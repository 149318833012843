import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Box,
    Button,
    Collapse,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
} from "@mui/material";
import {
    ExpandLess,
    ExpandMore,
    AccountTree,
    People,
    Notifications,
    LockRounded,
    KeyRounded,
    Groups,
    Business,
    Person,
} from "@mui/icons-material";
import api from "~/api";
import { currentVersion } from "~/version";
import VoxitLogo from "~/assets/logo.svg";
import { useDialog } from "~/providers/dialog";
import useUser from "~/providers/user/useUser";

import OverflowTip from "../OverflowTip";
import "./sidebar.css";
import { useSidebarWorkflowsAtom } from "../../providers/sidebar-workflow.atom";
import { useConfirm } from "material-ui-confirm";

export const SIDEBAR_DRAWER_WIDTH = 320;

const Sidebar = () => {
    const [workflows, setWorkflows] = useSidebarWorkflowsAtom();
    const [openWorkflows, setOpenWorkflows] = React.useState(false);
    const [user, saveUser] = useUser();
    const showDialog = useDialog();
    const confirm = useConfirm();

    useEffect(() => {
        const loadWorkflows = async () => {
            try {
                const data = await api.workflows.list(true);
                const sorted = data.sort((a, b) => a.title.localeCompare(b.title));
                setWorkflows(sorted);
            } catch (e) {
                showDialog("Não foi possível carregar os workflows.", e);
            }
        };
        if (user) {
            loadWorkflows();
        }
    }, [user?.token]);

    const handleClickWorkflows = () => {
        setOpenWorkflows(!openWorkflows);
    };

    const handleLogout = () => {
        confirm({
            description: "Tem certeza que deseja sair?",
            cancellationText: "Cancelar",
            confirmationText: "Sair",
            confirmationButtonProps: { color: "error" },
        })
            .then(async () => {
                saveUser(null);
            })
            .catch(() => {});
    };

    return ( 
        <Drawer
            sx={{
                width: user ? SIDEBAR_DRAWER_WIDTH : 0,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: user ? SIDEBAR_DRAWER_WIDTH : 0,
                    boxSizing: "border-box",
                },
            }}
            variant="permanent"
            anchor="left"
        >
            <Toolbar>
                <List>
                    <ListItem component={Link} to={"/"} sx={{ paddingLeft: "50%" }}>
                        <img
                            src={VoxitLogo}
                            alt="voxit.ai logo"
                            style={{ margin: "0 auto" }}
                            width={128}
                        />
                    </ListItem>
                </List>
            </Toolbar>
            <Divider />
            <List>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to={"/pendingTasks"}>
                        <ListItemIcon>
                            <Notifications />
                        </ListItemIcon>
                        <ListItemText primary="Tarefas Pendentes" />
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                    <ListItemButton onClick={handleClickWorkflows}>
                        <ListItemIcon>
                            <AccountTree />
                        </ListItemIcon>
                        <ListItemText primary="Workflows" />
                        {openWorkflows ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={openWorkflows} timeout="auto" unmountOnExit>
                    {user?.is_superadmin && (
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 5 }}
                                component={Link}
                                to={"/workflows/new"}
                            >
                                <Button variant={"outlined"} fullWidth>
                  Novo Workflow
                                </Button>
                            </ListItemButton>
                        </List>
                    )}
                    {workflows.map((workflow) => (
                        <List key={workflow.uuid} component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 5 }}
                                component={Link}
                                to={`/workflows/${workflow.uuid}`}
                            >
                                <ListItemText
                                    primary={<OverflowTip>{workflow.title}</OverflowTip>}
                                />
                            </ListItemButton>
                        </List>
                    ))}
                </Collapse>
                <Divider />
                {(user?.is_superadmin || user?.is_companyadmin) && (
                    <>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to={"/credentials"}>
                                <ListItemIcon>
                                    <KeyRounded />
                                </ListItemIcon>
                                <ListItemText primary="Credenciais" />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to={"/users"}>
                                <ListItemIcon>
                                    <People />
                                </ListItemIcon>
                                <ListItemText primary="Usuários" />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to={"/groups"}>
                                <ListItemIcon>
                                    <Groups />
                                </ListItemIcon>
                                <ListItemText primary="Grupos" />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                    </>
                )}
                {user?.is_superadmin && (
                    <>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to={"/companies"}>
                                <ListItemIcon>
                                    <Business />
                                </ListItemIcon>
                                <ListItemText primary="Empresas" />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                    </>
                )}
                <ListItem disablePadding>
                    <ListItemButton component={Link} to={"/users/me"}>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText primary="Meu Usuário" />
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                    <ListItemButton onClick={handleLogout}>
                        <ListItemIcon>
                            <LockRounded />
                        </ListItemIcon>
                        <ListItemText primary="Sair" />
                    </ListItemButton>
                </ListItem>
            </List>

            <Box sx={{ textAlign: "center", marginTop: "auto", mb: 2 }}>
                <Typography variant={"subtitle2"}>Versão: {currentVersion}</Typography>
            </Box>
        </Drawer>
    );
};
export default Sidebar;
