const pendingTasks = (apiClient, handleError) => {
    return {
        list: async () => {
            try {
                const {data} = await apiClient.get("/v1/pendingTasks");
                return data;
            } catch (error) {
                handleError(error);
            }
        },
    };
};
export default pendingTasks;