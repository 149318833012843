import React, {useEffect, useState} from "react";
import {Collapse, Divider, FormControl, List, ListItem, ListItemText} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

import {InterpolableTextField} from "~/components";


function CollapsableObject({nameForKeyword, name, item, sx, showDivider, onFocus, onChange, parameters}) {
    const [isCollapsed, setCollapsed] = useState(false);

    const parameterKey = "form." + item["$id"];

    const handleToggleCollapse = () => {
        setCollapsed(!isCollapsed);
    };

    const handleBlur = () => {
        onFocus(null);
    };

    const handleFocus = (key) => {
        const keyName = typeof key === "string" || key instanceof String ? key : parameterKey;
        onFocus && onFocus({
            key: keyName,
        });
    };
    const handleChange = (event) => {
        onChange && onChange(event);
    };


    const isSelectField = item["$formType"] === "select" || item["$formType"] === "multiselect";
    const optionsKey = `${parameterKey}_options`;
    if (isSelectField) {
        return <>
            <ListItem tabIndex="0" style={{outline: "none"}} onClick={handleToggleCollapse} sx={sx}>
                <ListItemText primary={name}/>
                {isCollapsed ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>

            {showDivider && isCollapsed && <Divider/>}
            <Collapse in={isCollapsed}>
                <ListItem tabIndex="0" style={{outline: "none"}} sx={sx}>
                    <FormControl sx={{pl: 2}} fullWidth>
                        <InterpolableTextField
                            nameForKeyword={nameForKeyword}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={parameters[parameterKey] || ""}
                            name={parameterKey}
                            label={"Valor padrão"}/>
                    </FormControl></ListItem>
                <ListItem tabIndex="0"  style={{outline: "none"}} sx={sx}>
                    <FormControl sx={{pl: 2}} fullWidth>
                        <InterpolableTextField
                            nameForKeyword={nameForKeyword}
                            onFocus={() => handleFocus(optionsKey)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={parameters[optionsKey] || ""}
                            name={optionsKey}
                            label={"Opções"}/>
                    </FormControl></ListItem>
            </Collapse>
        </>;

    } else {
        return <>
            <ListItem tabIndex="0" style={{outline: "none"}} onClick={handleToggleCollapse} sx={sx}>
                {item.type !== "object" && item.type !== "array" && <FormControl fullWidth>
                    <InterpolableTextField
                        nameForKeyword={nameForKeyword}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={parameters[parameterKey] || ""}
                        name={parameterKey}
                        label={name}/>
                </FormControl>}

                {item.properties && <>
                    <ListItemText primary={name}/>
                    {isCollapsed ? <ExpandLess/> : <ExpandMore/>}
                </>}
            </ListItem>

            {showDivider && isCollapsed && <Divider/>}

            {item.properties && <Collapse in={isCollapsed}>
                {Object.keys(item.properties).map((key) => {
                    if (item?.properties[key]?.items) {
                        return <CollapsableObject
                            sx={{pl: (sx?.pl || 1.5) + 1}}
                            key={key}
                            name={key}
                            item={item?.properties[key].items}
                            parameters={parameters}
                            onChange={onChange}
                            onFocus={onFocus}
                            nameForKeyword={nameForKeyword}/>;
                    } else {
                        return <CollapsableObject
                            sx={{pl: (sx?.pl || 1.5) + 1}}
                            key={key}
                            name={key}

                            item={item.properties[key]}
                            parameters={parameters}
                            onChange={onChange}
                            onFocus={onFocus}
                            nameForKeyword={nameForKeyword}/>;
                    }
                })}
            </Collapse>
            }
        </>;
    }
}

export function RenderFormMap({nameForKeyword, workflow, step, parameters, onFocus, onChange}) {
    const [schema, setSchema] = useState(null);

    useEffect(() => {
        const schema = parameters.formUuid ? workflow.forms.find(form => form.uuid === parameters.formUuid).schema : null;
        setSchema(schema);
    }, [parameters.formUuid, workflow.forms]);


    if (!schema) {
        return <></>;
    }

    return <List sx={{width: "100%", border: "1px solid #CCCCCC", borderRadius: "4px"}}>
        <CollapsableObject
            key={step.uuid}
            name="Campos do Formulário"
            nameForKeyword={nameForKeyword}
            showDivider={true}
            parameters={parameters}
            onFocus={onFocus}
            onChange={onChange}
            item={schema}/>
    </List>;
}
