const groups = (apiClient, handleError) => {
    return {
        list: async () => {
            try {
                const {data} = await apiClient.get("/v1/groups");
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        create: async (group) => {
            try {
                const {data} = await apiClient.post("/v1/groups", group);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        detail: async (uuid) => {
            try {
                const {data} = await apiClient.get(`/v1/groups/${uuid}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        update: async (uuid, group) => {
            try {
                const {data} = await apiClient.put(`/v1/groups/${uuid}`, group);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        remove: async (uuid) => {
            try {
                const {data} = await apiClient.delete(`/v1/groups/${uuid}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
    };
};
export default groups;