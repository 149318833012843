import axios from "axios";
import { baseUrl } from "./baseUrl";
import workflows from "./requests/workflows";
import forms from "./requests/forms";
import pendingTasks from "./requests/pendingTasks";
import users from "./requests/users";
import groups from "./requests/groups";
import auth from "./requests/auth";
import datasets from "./requests/datasets";
import credentials from "./requests/credentials";
import storage from "./requests/storage";
import companies from "./requests/companies";
import { decodeToken } from "react-jwt";


const user_initial_state = JSON.parse(localStorage.getItem("user"));
let onLogout = null;
let onUpdateUser = null;
let refreshingToken = false;
const apiClient = axios.create({
    baseURL: baseUrl,
    headers: {
        "Authorization": "Bearer " + user_initial_state?.token
    }
});



const updateToken = (token) => {
    apiClient.defaults.headers["Authorization"] = "Bearer " + token;
};

const refreshToken = async (refreshToken) => {
    refreshingToken = true;
    try {
        const result = await api.auth.refresh(refreshToken);
        const payload = await decodeToken(result.token);
        onUpdateUser && await onUpdateUser({ ...payload, refreshToken: result.refreshToken, token: result.token });
        return result.token;
    } catch (error) {
        refreshingToken = false;
        throw error;
    }
};

const handleError = (error) => {
    if (error?.response?.status === 401) {
        onLogout && onLogout();
        throw "Você precisa autenticar novamente.";
    } else {
        if (error?.response?.data?.message && typeof error?.response?.data?.message === "string") {
            throw error.response.data.message;
        } else {
            throw error.message;
        }
    }
};

async function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}

async function waitRefreshToken(token) {
    if (refreshingToken) {
        while (refreshingToken) {
            await sleep(300);
        }
        return apiClient.defaults.headers["Authorization"].replace("Bearer ", "");
    } else {
        return await refreshToken(token);
    }

}

apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const token = await waitRefreshToken(user_initial_state.refreshToken);
                updateToken(token);
                originalRequest.headers.Authorization = "Bearer " + token;

                const result = await apiClient(originalRequest);

                return Promise.resolve(result);
            } catch (newError) {
                console.log(newError);
                return Promise.reject(handleError(error));
            }
        }
        return Promise.reject(error);
    }
);

const addOnLogout = (handler) => {
    onLogout = handler;
};

const addOnUpdateUser = (handler) => {
    onUpdateUser = handler;
};

const api = {
    auth: auth(apiClient, handleError),
    workflows: workflows(apiClient, handleError),
    forms: forms(apiClient, handleError),
    pendingTasks: pendingTasks(apiClient, handleError),
    users: users(apiClient, handleError),
    datasets: datasets(apiClient, handleError),
    credentials: credentials(apiClient, handleError),
    storage: storage(apiClient, handleError),
    groups: groups(apiClient, handleError),
    companies: companies(apiClient, handleError),
    updateToken,
    addOnLogout,
    addOnUpdateUser
};

export default api;