import React, {useEffect, useState} from "react";
import {
    Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Container, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField
} from "@mui/material";
import api from "~/api";
import {MainPage, Navbar} from "~/components";
import {useDialog, useToast} from "~/providers/dialog";
import {useNavigate} from "react-router-dom";

const CredentialsNew = () => {
    const [currentType, setCurrentType] = useState({
        type: ""
    });
    const [name, setName] = useState("");
    const [infos, setInfos] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [types, setTypes] = useState(null);

    const showDialog = useDialog();
    const showToast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        async function loadTypes() {
            setLoading(true);
            try {
                const types = await api.credentials.types();
                setTypes(types);
            } catch (e) {
                showDialog("Não foi possível carregar as informações.", e);
            }
            setLoading(false);
        }

        loadTypes().then();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = async (e) => {
        e.preventDefault();
        if (loadingSave) {
            return;
        }
        setLoadingSave(true);
        try {
            await api.credentials.create({
                type: currentType.type,
                name: name,
                credentials: infos
            });
            navigate("/credentials");
            showToast("Credencial criada com sucesso!");
        } catch (e) {
            showDialog("Não foi possível criar a credencial.", e);
        }
        setLoadingSave(false);

    };
    const handleChangeType = (e) => {
        const type = types.find((type) => type.type === e.target.value);
        let obj = {};
        type.attributes.forEach((field) => {
            obj[field.name] = "";
        });
        setInfos(obj);
        setCurrentType(type);
    };

    const handleChange = (e) => {
        setInfos({
            ...infos,
            [e.target.name]: e.target.value
        });
    };

    const handleChangeName = (e) => {
        setName(e.target.value);
    };

    return <MainPage>
        <Navbar title="Nova Credencial"/>
        <Box
            component="main"
            sx={{
                flexGrow: 1, py: 8
            }}
        >
            <Container maxWidth="lg">
                <Stack spacing={3}>
                    <Card>
                        <CardHeader
                            title="Informações da credencial"
                        />
                        <Divider/>
                        <form onSubmit={handleSave}>
                            <CardContent>
                                <Stack
                                    spacing={3}
                                    sx={{maxWidth: 400}}
                                >
                                    <Box sx={{display: "flex", alignItems: "center"}}>

                                        <FormControl fullWidth>
                                            <InputLabel>Tipo de credencial</InputLabel>
                                            <Select label="Tipo de credencial" value={currentType?.type} onChange={handleChangeType}>
                                                {types?.map((type) => <MenuItem key={type.type} value={type.type}>{type.type}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        {loading && <CircularProgress sx={{ml: 1, flexShrink: 0}} size={30}/>}
                                    </Box>
                                    <TextField fullWidth label={"Nome"} onChange={handleChangeName} required value={name}/>
                                    {Object.keys(infos).length > 0 && currentType?.attributes?.map((field) => <TextField
                                        key={field.name}
                                        fullWidth
                                        label={field.label}
                                        name={field.name}
                                        onChange={handleChange}
                                        required
                                        value={infos[field.name] || ""}/>)}
                                </Stack>
                            </CardContent>
                            <Divider/>
                            <CardActions sx={{justifyContent: "flex-end"}}>
                                <Button disabled={loadingSave} variant="contained" type="submit">
                                    {!loadingSave && "Salvar"}
                                    {loadingSave && <>
                                        Salvando...
                                        <CircularProgress size={20} sx={{ml: 2}}/>
                                    </>}
                                </Button>
                            </CardActions>
                        </form>
                    </Card>
                </Stack>
            </Container>
        </Box>
    </MainPage>;
};

export default CredentialsNew;