import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Button} from "@mui/material";
import {FlexBox} from "~/components";

import styles from "./DynamicInfoPanel.module.css";
import {EditorState} from "@codemirror/state";
import {EditorView, Decoration, MatchDecorator, WidgetType, ViewPlugin} from "@codemirror/view";
import {javascript} from "@codemirror/lang-javascript";
import {basicSetup} from "codemirror";

class KeywordWidget extends WidgetType {
    constructor(keyword, nameForKeyword) {
        super();
        this.keyword = keyword;
        this.label = nameForKeyword(keyword.replace(/(^{{)|(}}$)/g, ""));
    }

    toDOM() {
        const span = document.createElement("span");
        span.className = styles.customKeyword;
        span.textContent = this.label;
        return span;
    }

    ignoreEvent() {
        return false;
    }
}

export const RenderScript = forwardRef(({setAddingScript, setScript, script, setCurrentScriptSelection, setEditingScript, onChange, nameForKeyword}, ref) => {
    const editorRef = useRef();
    const [editorView, setEditorView] = useState(null);

    const myTheme = EditorView.theme({
        "&": {
            height: "100%",
        }, ".cm-scroller": {
            overflow: "auto", height: "100%",
        }
    });


    useEffect(() => {
        if (!editorRef.current) {
            return;
        }

        const startState = EditorState.create({
            doc: script, extensions: [basicSetup, keywordParser, myTheme, javascript(), EditorState.tabSize.of(2), EditorView.updateListener.of(update => {
                if (update.docChanged) {
                    const script = update.state.doc.toString();
                    setScript(script);
                }
            }),],
        });

        const view = new EditorView({
            state: startState, parent: editorRef.current,
        });
        setEditorView(view);

        return () => {
            view.destroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorRef]);

    useEffect(() => {
        if (editorView && script !== editorView.state.doc.toString()) {
            console.log("Updating script", script);
            editorView.dispatch({
                changes: {
                    from: 0, to: editorView.state.doc.length, insert: script
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [script]);

    useImperativeHandle(ref, () => ({
        insert(text) {
            if (editorView) {
                const selection = editorView?.state.selection.main;
                editorView.dispatch({
                    changes: {
                        from: selection.from, to: selection.to, insert: text
                    }
                });
            }
        }
    }));

    const handleScriptBlur = (event) => {
        if (event.relatedTarget?.getAttribute("name") === "insertButton" || event.relatedTarget?.getAttribute("name") === "dynamicItem" || event.relatedTarget?.getAttribute("name") === "dynamicPanel") {
            const selection = editorView?.state.selection.main;
            setCurrentScriptSelection([selection.from, selection.to]);
            event.target.focus();
        } else {
            setEditingScript(false);
        }
    };

    const handleScriptFocus = () => {
        setEditingScript(true);
    };

    const handleScriptChange = (event) => {
        setScript(event.target.value);
    };

    const handleScriptCancel = () => {
        setAddingScript(false);
        setScript("");
    };

    const handleScriptSave = () => {
        onChange(`{{SCRIPT}}${script}`);
    };

    const keywordMatcher = new MatchDecorator({
        regexp: /({{.[^{}]*?}})/g,
        decoration: match => Decoration.replace({
            widget: new KeywordWidget(match[1], nameForKeyword),
        })
    });
    const keywordParser = ViewPlugin.fromClass(class {
        constructor(view) {
            this.placeholderSets = keywordMatcher.createDeco(view);
        }

        update(update) {
            this.placeholderSets = keywordMatcher.updateDeco(update, this.placeholderSets);
        }
    }, {
        decorations: instance => instance.placeholderSets, provide: plugin => EditorView.atomicRanges.of(view => {
            return view.plugin(plugin)?.placeholderSets || Decoration.none;
        })
    });


    return (<FlexBox grow vertical>
        <FlexBox grow horizontal>
            <div
                ref={editorRef}
                name={"dynamicScript"}
                className={styles.scriptArea}
                onFocus={handleScriptFocus}
                onBlur={handleScriptBlur}
                onChange={handleScriptChange}></div>
        </FlexBox>
        <FlexBox horizontal>
            <Button variant={"contained"} sx={{margin: "10px"}} fullWidth name="dynamicPanel" color={"warning"} onClick={handleScriptCancel}>
                Cancelar
            </Button>
            <Button variant={"contained"} sx={{margin: "10px"}} fullWidth name="dynamicPanel" onClick={handleScriptSave}>
                Salvar
            </Button>
        </FlexBox>
    </FlexBox>);
});