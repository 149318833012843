import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
    Box,
    Button,
    Tooltip,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from "@mui/material";
import { ErrorRounded, Info } from "@mui/icons-material";

import api from "~/api";
import { LoadingBar, Navbar, MainPage } from "~/components";
import { useDialog } from "~/providers/dialog";
import { translateStatus } from "~/utils/workflow";
import { formatDateTime } from "~/utils/date";

const WorkflowDetail = () => {
    const { uuid } = useParams();
    const [loading, setLoading] = useState(true);
    const [workflow, setWorkflow] = useState(null);
    const [executions, setExecutions] = useState(null);
    const showDialog = useDialog();

    useEffect(() => {
        const fetchWorkflow = async () => {
            setLoading(true);
            try {
                const data = await api.workflows.detail(uuid, false, false);
                setWorkflow(data);

                const executions = await api.workflows.executions(uuid);
                setExecutions(executions);


                setLoading(false);
            } catch (e) {
                showDialog("Não foi possível carregar o workflow.", e);
            }
        };
        fetchWorkflow().then();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);


    return <MainPage>
        {
            loading ? <LoadingBar />
                :
                <>
                    <Navbar title={workflow.title} sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }}>
                        {workflow.enabled && <Button component={Link} to={`/workflows/${uuid}/executions`} variant={"outlined"} color={"white"}>Iniciar fluxo</Button>}
                        <Button component={Link} to={`/workflows/${uuid}/flow`} variant={"outlined"} color={"white"} sx={{ ml: 4 }}>Ver fluxo</Button>
                    </Navbar>
                    <Box sx={{ display: "flex", height: "100%" }}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Etapa atual</TableCell>
                                        <TableCell>Situação da etapa atual</TableCell>
                                        <TableCell>Data da etapa atual</TableCell>
                                        <TableCell>Usuário</TableCell>
                                        <TableCell>Data</TableCell>
                                        <TableCell align="right">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        executions?.map(execution => {
                                            const hasVersion = !!execution?.version;

                                            const lastStep = execution.steps[execution.steps.length - 1];
                                            let name = lastStep.workflowStep.name;
                                            let versionEnabled = true;
                                            if (hasVersion) {
                                                versionEnabled = execution.version.enabled;
                                                name = execution?.version?.definition.steps[execution.version.definition.steps.length - 1].name;
                                            }
                                            return <TableRow key={execution.uuid}>
                                                
                                                {!versionEnabled ?
                                                    <TableCell>
                                                        <Tooltip title='Esse workflow foi executado em uma versão diferente da atual' disableHoverListener={false}>
                                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                {execution.steps.length} - {name}
                                                                <Info color={"warning"} sx={{ ml: 1 }} />
                                                            </Box>
                                                        </Tooltip>  </TableCell> :
                                                    <TableCell>
                                                        {execution.steps.length} - {name}
                                                    </TableCell>
                                                }
                                                <TableCell>
                                                    {lastStep.status === "error" ?
                                                        <Tooltip title={lastStep.output.error} disableHoverListener={false}>
                                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                {translateStatus(lastStep.status)}
                                                                <ErrorRounded color={"error"} sx={{ ml: 1 }} />
                                                            </Box>
                                                        </Tooltip>
                                                        :
                                                        translateStatus(lastStep.status)}
                                                </TableCell>
                                                <TableCell>{formatDateTime(lastStep.createdAt)}</TableCell>
                                                <TableCell>{execution.user.firstName}</TableCell>
                                                <TableCell>{formatDateTime(execution.createdAt)}</TableCell>
                                                <TableCell align="right">
                                                    <Button
                                                        component={Link}
                                                        to={`/workflows/${uuid}/results/${execution.uuid}`}
                                                        variant={"outlined"}
                                                        color={"info"}>Detalhes</Button>
                                                </TableCell>
                                            </TableRow>;
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
        }
    </MainPage>;
};
export default WorkflowDetail;