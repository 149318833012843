import React, {createContext, useEffect, useState} from "react";
import api from "~/api";

export const UserContext = createContext();
export const UserProvider = ({children}) => {
    const user_initial_state = JSON.parse(localStorage.getItem("user"));
    const [user, setUser] = useState(user_initial_state);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            setUser({...user});
            api.updateToken(user.token);
        }
        api.addOnLogout(() => saveUser(null));
        api.addOnUpdateUser((user) => saveUser(user));
    }, []);

    const saveUser = (user) => {
        if (user) {
            localStorage.setItem("user", JSON.stringify(user));
            setUser({...user});
            api.updateToken(user.token);
        } else {
            localStorage.removeItem("user");
            setUser(null);
        }
    };

    return <UserContext.Provider value={{user, saveUser}}>
        {children}
    </UserContext.Provider>;
};