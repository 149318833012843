import {useFileUploadAtom} from "./fileupload.atom";
import {useEffect} from "react";
import api from "../api";

export function useFileUpload() {
    const [fileUpload, setFileUpload] = useFileUploadAtom();

    useEffect(() => {
        const pendingFiles = fileUpload.filter((file) => file.status === "not_uploaded");
        for (const pending of pendingFiles) {
            uploadFile(pending);
        }
    }, [fileUpload]);

    function updateFile(file) {
        setFileUpload((files) => {
            return files.map((insideFile) => {
                if (insideFile.name === file.name) {
                    return file;
                }
                return insideFile;
            });
        });
    }

    async function uploadFile(file) {
        try {
            updateFile({...file, status: "uploading"});

            const {url, getUrl} = await api.storage.getUrl(file.type);
            await api.storage.upload(url, file.file, file.type);

            updateFile({...file, status: "uploaded", url: getUrl});
        } catch (e) {
            updateFile({...file, status: "error"});
        }
    }
}