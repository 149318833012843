const forms = (apiClient, handleError) => {
    return {
        list: async () => {
            try {
                const {data} = await apiClient.get("/v1/forms");
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        create: async (form) => {
            try {
                const {data} = await apiClient.post("/v1/forms", form);
                return data;
            } catch (error) {
                handleError(error);
            }
        },

        detail: async (uuid) => {
            try {
                const {data} = await apiClient.get(`/v1/forms/${uuid}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        update: async (uuid, form) => {
            try {
                const {data} = await apiClient.put(`/v1/forms/${uuid}`, form);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        responses: async (uuid) => {
            try {
                const {data} = await apiClient.get(`/v1/forms/${uuid}/responses`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        responseDetail: async (formUuid, responseUuid) => {
            try {
                const {data} = await apiClient.get(`/v1/forms/${formUuid}/responses/${responseUuid}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
    };
};
export default forms;