import React from "react";
import {Avatar, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import styles from "./WorkflowToolbox.module.css";
import {iconFromName} from "~/components/Icons";

const ComponentsTab = ({hidden, definition, onAddStep}) => {

    const onDragStart = (event, step) => {
        event.dataTransfer.setData("application/babel-workflow", JSON.stringify(step));
        event.dataTransfer.effectAllowed = "move";
    };

    return <List hidden={hidden} sx={{overflowY: "auto"}}>
        {definition?.map(component =>
            <ListItem
                className={styles.workflowComponent}
                onDragStart={(event) => onDragStart(event, component)} draggable
                sx={{cursor: "pointer"}} key={component.type} onClick={() => onAddStep(component)}>
                <ListItemAvatar>
                    <Avatar>
                        {iconFromName(component.icon)}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={component.name} secondary={component.description}/>
            </ListItem>)}
    </List>;
};

export default ComponentsTab;