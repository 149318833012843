import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Divider,
    Stack,
    TextField
} from "@mui/material";

import api from "~/api";
import {MainPage, Navbar} from "~/components";
import {useDialog, useToast} from "~/providers/dialog";

const DatasetNew = () => {
    const [infos, setInfos] = useState({
        name: "",
        ttl: 240,
    });


    const [loadingSave, setLoadingSave] = useState(false);

    const showDialog = useDialog();
    const showToast = useToast();

    const navigate = useNavigate();


    const handleChange = (event) => {
        const {name, value} = event.target;
        setInfos({
            ...infos,
            [name]: value
        });
    };

    const handleSave = (e) => {
        e.preventDefault();
        if (loadingSave) {
            return;
        }

        if (!infos.name || infos.name.length === 0) {
            showDialog("Atenção", "O nome não foi preenchida.");
        } else if (!infos.ttl || infos.ttl < 1) {
            showDialog("Atenção", "O TTL precisa ser no mínimo 1 hora.");
        } else {
            (async () => {
                setLoadingSave(true);
                try {
                    await api.datasets.create(infos);
                    navigate("/datasets");
                    showToast("Dataset salvo com sucesso!");
                } catch (e) {
                    showDialog("Não foi possível salvar.", e);
                }
                setLoadingSave(false);
            })();
        }
    };

    return <MainPage>
        <Navbar title="Novo Dataset"/>
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="lg">
                <Stack spacing={3}>
                    <form onSubmit={handleSave}>
                        <Card>
                            <CardHeader title="Informações do dataset"/>
                            <Divider/>
                            <CardContent>
                                <Stack
                                    spacing={3}
                                    sx={{maxWidth: 400}}>
                                    <TextField
                                        fullWidth
                                        label="Nome"
                                        name="name"
                                        type="text"
                                        required
                                        onChange={handleChange}
                                        value={infos.name}
                                    />

                                    <TextField
                                        fullWidth
                                        label="TTL (em horas)"
                                        name="ttl"
                                        type="text"
                                        required
                                        onChange={handleChange}
                                        value={infos.ttl}
                                    />
                                </Stack>
                            </CardContent>
                            <Divider/>
                            <CardActions sx={{justifyContent: "flex-end"}}>
                                <Button disabled={loadingSave} variant="contained" type="submit">
                                    {!loadingSave && "Salvar"}
                                    {loadingSave && <>
                                        Salvando...
                                        <CircularProgress size={20} sx={{ml: 2}}/>
                                    </>}
                                </Button>
                            </CardActions>
                        </Card>
                    </form>

                </Stack>
            </Container>
        </Box>
    </MainPage>;
};

export default DatasetNew;