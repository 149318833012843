import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import Grid from "@mui/material/Grid";
import {Button, Card, CardActions, CardContent, Divider, Typography} from "@mui/material";
import {RenderItem} from "./index";
import {useConfirm} from "material-ui-confirm";

export function RenderRepeater({item, infos, onChange}) {
    const confirm = useConfirm();
    const [items, setItems] = useState([]);
    const [defaultItem, setDefaultItem] = useState(null);
    useEffect(() => {
        if (item) {
            let itemDef = {};
            for (const field of item.items) {
                itemDef[field.uuid] = field.default || "";
            }
            setDefaultItem({...itemDef});
        }
    }, [item]);

    useEffect(() => {
        if (infos) {
            setItems(infos[item.uuid] || []);
        }
    }, [infos, item]);

    const handleChange = (obj, event) => {
        const {name, value} = event.target;
        const newEvent = {
            target: {
                name: item.uuid,
            }
        };
        onChange(newEvent, (parent) => {
            return parent.map((item) => {
                if (item === obj) {
                    return {...item, [name]: value};
                } else {
                    return item;
                }
            });
        });
    };

    const handleAdd = () => {
        let newItems = [
            {...defaultItem, uuid: uuidv4()},
            ...items
        ];
        onChange({
            target: {
                name: item.uuid,
                value: newItems
            }
        });
    };

    const handleRemove = (obj) => {
        let newItems = [...items];
        const index = newItems.indexOf(obj);
        newItems.splice(index, 1);
        onChange({
            target: {
                name: item.uuid,
                value: [...newItems]
            }
        });
    };

    const handleConfirmRemove = (obj) => {
        confirm(
            {
                description: "Tem certeza que deseja remover este item?",
                confirmationText: "Excluir",
                cancellationText: "Cancelar",
                confirmationButtonProps: {color: "error"},
            }).then(async () => {
                handleRemove(obj);
            
        }).catch(() => {
        });
    };


    return <Grid item xs={12}>
        <Typography variant={"h5"}>{item.label}</Typography>
        <Divider/>
        <CardActions sx={{justifyContent: "flex-end"}}>
            <Button variant="contained" onClick={handleAdd}>
                Adicionar
            </Button>
        </CardActions>

        {items.map(childItem => <Card sx={{mt: 3}} key={childItem}>
            <CardContent>
                <Grid container columnSpacing={3} rowSpacing={3}>
                    {item.items.map(insideItem => <RenderItem
                        key={insideItem.uuid}
                        item={insideItem}
                        infos={childItem}
                        onChange={(event) => handleChange(childItem, event)}/>)}
                </Grid>
                <Divider/>
                <CardActions sx={{justifyContent: "flex-end"}}>
                    <Button variant="contained" onClick={() => handleConfirmRemove(childItem)}>
                        Remover
                    </Button>
                </CardActions>
            </CardContent>
        </Card>)}

    </Grid>;
}
