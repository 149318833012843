import {
    Divider,
    Drawer,
    Tab,
    Tabs,
    Toolbar
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {WidgetsRounded, TuneRounded, CategoryRounded} from "@mui/icons-material";

import ComponentsTab from "./ComponentsTab";
import PropertiesTab from "./PropertiesTab";
import LibraryTab from "./LibraryTab";

export const WorkflowToolboxWidth = 360;

export default function WorkflowToolbox(
    {
        workflow,
        selectedStep,
        definition,
        onAddStep,
        onStepUpdated,
        hasChanges,
        reloadWorkflow
    }) {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (_, index) => {
        setSelectedTab(index);
    };

    useEffect(() => {
        if (selectedStep) {
            setSelectedTab(1);
        } else {
            setSelectedTab(0);
        }
    }, [selectedStep]);

    const handleAddStep = (component) => {
        onAddStep(component);
    };

    const tabInfo = {
        fontSize: "0.6rem",
        padding: "10px 5px",
        svg: {
            fontSize: "32px"
        }
    };

    return <Drawer
        sx={{
            width: WorkflowToolboxWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
                width: WorkflowToolboxWidth,
                boxSizing: "border-box",
            },
        }}
        anchor="right"
        variant={"permanent"}>
        <Toolbar/>
        <Tabs centered value={selectedTab} onChange={handleChange} aria-label="tab workflow opções" sx={{flexShrink: 0}}>
            <Tab sx={tabInfo} icon={<WidgetsRounded/>} label="COMPONENTES"/>
            <Tab sx={tabInfo} disabled={selectedStep === null} icon={<TuneRounded/>} label="PROPRIEDADES"/>
            <Tab sx={tabInfo} icon={<CategoryRounded/>} label="BIBLIOTECA"/>
        </Tabs>
        <Divider/>
        <ComponentsTab
            hidden={selectedTab !== 0}
            definition={definition}
            onAddStep={handleAddStep}/>
        <PropertiesTab
            hidden={selectedTab !== 1}
            onStepUpdated={onStepUpdated}
            definition={definition}
            selectedStep={selectedStep}
            workflow={workflow}/>
        <LibraryTab
            hidden={selectedTab !== 2}
            hasChanges={hasChanges}
            reloadWorkflow={reloadWorkflow}
            workflow={workflow}/>
    </Drawer>;
}
