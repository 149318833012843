import {TextField} from "@mui/material";
import React from "react";
import {defaultFieldSx} from "./index";

export function RenderText({item, infos, onChange}) {
    return <TextField
        sx={defaultFieldSx}
        label={item.label}
        placeholder={item.hint}
        name={item.uuid}
        onChange={item.readonly ? null : onChange}
        value={infos[item.uuid]}
        type="text"
        required={item.required}
    />;
}
