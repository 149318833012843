import React, {useEffect, useRef} from "react";
import {Box, Button} from "@mui/material";
import styles from "../RenderForm.module.css";
import SignatureCanvas from "react-signature-canvas";

export function RenderSignature({item, infos, onChange}) {
    const canvasRef = useRef();
    const handleChange = async () => {
        function getCanvasBlob(canvas) {
            return new Promise(function (resolve) {
                canvas.toBlob(function (blob) {
                    resolve(blob);
                }, "image/png");
            });
        }

        const blob = await getCanvasBlob(canvasRef.current?.getTrimmedCanvas());

        const data = canvasRef.current?.toData();
        onChange({
            target: {
                name: item.uuid + "_signature_raw",
                value: data
            }
        });
        onChange({
            target: {
                name: item.uuid,
                value: blob
            }
        });
    };
    const clearCanvas = () => {
        canvasRef.current?.clear();
        onChange({
            target: {
                name: item.uuid,
                value: null
            }
        });
        onChange({
            target: {
                name: item.uuid + "_signature_raw",
                value: null
            }
        });
    };
    useEffect(() => {
        if (infos && infos[item.uuid + "_signature_raw"]) {
            canvasRef.current?.fromData(infos[item.uuid + "_signature_raw"]);
        }
    }, [item.uuid, infos, canvasRef]);

    return <Box className={styles.signatureCanvasContainer}>
        <fieldset className={styles.signatureCanvasFieldset}>
            <legend id="1"><span className={styles.signatureCanvasLabelSpan}>{item.label} {item.required ? "*" : ""}</span></legend>
        </fieldset>
        <div className={styles.signatureCanvasLine}/>
        <SignatureCanvas onEnd={handleChange} minDistance={0.1} ref={canvasRef} penColor='black' canvasProps={{className: styles.signatureCanvas}}/>
        <Button onClick={clearCanvas} sx={{position: "absolute", bottom: -5, right: 0}}>Limpar</Button>
    </Box>;
}
