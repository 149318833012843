import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
} from "@mui/material";
import {
    AddCircle,
    RemoveCircle,
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    Delete,
    InfoRounded,
} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import {useConfirm} from "material-ui-confirm";
import {v4 as uuidv4} from "uuid";

import FlexBox from "~/components/FlexBox";
import {useDialog} from "~/providers/dialog";

import styles from "./FormViewer.module.css";

const formTypeMap = {
    section: "Seção",
    repeater: "Lista",
    text: "Campo de texto",
    textarea: "Campo de texto multi-linha",
    number: "Campo numérico",
    date: "Campo de data",
    time: "Campo de hora",
    select: "Seleção",
    multiselect: "Seleção de multipla escolha",
    file: "Arquivo",
    signature: "Assinatura",
};

const FormViewer = ({form, onChange}) => {
    const handleChange = (group) => {
        let newItems;
        newItems = [...form.items];
        const index = newItems.findIndex((g) => g.uuid === group.uuid);
        newItems[index] = group;
        onChange && onChange({...form, items: newItems});
    };

    const reorderGroup = (group, direction) => {
        const {items} = form;
        const groupIndex = items.findIndex((item) => item.uuid === group.uuid);
        if (groupIndex === -1) {
            return;
        }
        const otherItemIndex = items.findIndex(
            (item) => item.order === group.order + direction
        );  
        const newItems = [...items];
        const otherItem = newItems[otherItemIndex];
        const updatedGroup = {
            ...group,
            order: group.order + direction,
        };
        delete updatedGroup.isOpen;
        newItems[groupIndex] = updatedGroup;
        if (otherItemIndex !== -1) {
            newItems[otherItemIndex] = {
                ...otherItem,
                order: otherItem.order - direction,
            };
        }

        onChange && onChange({...form, items: newItems});
    };

    const removeGroup = (group) => {
        let newItems = [...form.items];
        const itemIndex = newItems.findIndex((i) => i.uuid === group.uuid);
        newItems.splice(itemIndex, 1);
        onChange && onChange({
            ...form,
            items: newItems
        });

    };

    const handleAddGroup = () => {
        const sorted = sortedItems(form.items);
        const newField = {
            uuid: uuidv4(),
            type: "page",
            order: sorted.length > 0 ? sorted[sorted.length - 1].order + 1 : 1,
            label: null,
            hint: null,
            default: null,
            mask: null,
            required: true,
            filterable: false,
            readonly: false,
            items: [],
            isOpen: true
        };

        onChange && onChange({
            ...form,
            items: [
                ...sorted,
                newField
            ]
        });
    };

    return (
        <Box className={styles.formContainer}>
            {sortedItems(form.items).map((group, index) => (
                <RenderGroup
                    key={group.uuid}
                    group={group}
                    onChange={handleChange}
                    isFirst={index === 0}
                    isLast={index === form.items.length - 1}
                    reorderGroup={reorderGroup}
                    removeGroup={removeGroup}
                />
            ))}
            <RenderAddItem onAdd={handleAddGroup} onlyPage={true}/>
        </Box>
    );
};

function sortedItems(items) {
    return items.sort((a, b) => (a.order > b.order ? 1 : -1));
}

function RenderAddItem({onAdd, onlyPage}) {
    const [isAdding, setIsAdding] = useState(false);
    const [type, setType] = useState("");

    const toggleAdding = () => {
        setType("");
        setIsAdding(!isAdding);
    };

    const handleSetType = (event) => {
        setType(event.target.value);
    };

    const handleAdd = () => {
        if (type !== "") {
            onAdd && onAdd(type);
            toggleAdding();
        }
    };

    return (
        <FlexBox vertical fullWidth sx={{m: 2}} centerHorizontal>
            <Button onClick={toggleAdding}>
                <AddCircle
                    fontSize={"large"}
                    sx={{
                        rotate: isAdding ? "45deg" : "0",
                        transition: "rotate 250ms",
                    }}
                />
            </Button>
            {isAdding && (
                <FlexBox
                    horizontal
                    fullWidth
                    sx={{m: 1, maxWidth: "400px"}}
                >
                    <FormControl fullWidth sx={{mr: 1}}>
                        <InputLabel required>Tipo do novo campo</InputLabel>
                        <Select
                            label={"Tipo do novo campo"}
                            value={type}
                            onChange={handleSetType}
                        >
                            {onlyPage ?
                                <MenuItem key={"page"} value={"Página"}>
                                    {"Página"}
                                </MenuItem>
                                :
                                Object.keys(formTypeMap).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        {formTypeMap[key]}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <Button variant={"outlined"} onClick={handleAdd}>
                        Adicionar
                    </Button>
                </FlexBox>
            )}
        </FlexBox>
    );
}


function RenderGroup({item, group, onChange, onMove, onRemove, isFirst, isLast, reorderGroup, removeGroup}) {
    const handleChangeLabel = (event) => {
        onChange && onChange({
            ...group,
            label: event.target.value
        });
    };

    const handleChange = (item) => {
        let newItem = item;
        let newItems = [...group.items];
        const index = newItems.findIndex((i) => i.uuid === item.uuid);
        delete newItem.isOpen;
        newItems[index] = newItem;

        onChange && onChange({
            ...group,
            items: newItems
        });
    };

    /**
     * @param item: Form Item
     * @param direction: integer -- 1: DOWN | -1: UP
     */
    const handleMove = (item, direction) => {
        let newItems = [...group.items];
        const itemIndex = newItems.findIndex((i) => i.uuid === item.uuid);
        const otherItemIndex = newItems.findIndex((i) => i.order === item.order + direction);
        const otherItem = newItems[otherItemIndex];

        let newItem = {
            ...item,
            order: item.order + direction
        };
        delete newItem.isOpen;
        newItems[itemIndex] = newItem;

        newItems[otherItemIndex] = {
            ...otherItem,
            order: otherItem.order - direction
        };

        onChange && onChange({
            ...group,
            items: newItems
        });
    };

    const handleChangeGroup = (event) => {
        if (event.target.type === "checkbox") {
            const newItem = {
                ...item,
                [event.target.name]: event.target.checked
            };
            onChange && onChange(newItem);
        } else {
            const newItem = {
                ...item,
                [event.target.name]: Number(event.target.value)
            };
            onChange && onChange(newItem);
        }

    };

    const handleRemove = (item) => {
        let newItems = [...group.items];
        const itemIndex = newItems.findIndex((i) => i.uuid === item.uuid);
        newItems.splice(itemIndex, 1);
        onChange && onChange({
            ...group,
            items: newItems
        });
    };

    const handleAddField = (type) => {
        const sorted = sortedItems(group.items);
        const newField = {
            uuid: uuidv4(),
            type: type,
            order: sorted.length > 0 ? sorted[sorted.length - 1].order + 1 : 1,
            label: null,
            hint: null,
            default: null,
            mask: null,
            required: true,
            filterable: false,
            readonly: false,
            items: [],
            isOpen: true
        };

        onChange && onChange({
            ...group,
            items: [
                ...sorted,
                newField
            ]
        });
    };

    return <Box className={styles.group}>
        <div className={styles.fieldTypeContainer}>
            <span className={styles.fieldType}>{formTypeMap[group.type]}</span>
        </div>
        <TextField
            sx={{m: 2}}
            inputProps={{sx: {textAlign: "center", fontSize: "1.4rem"}}}
            variant={"standard"}
            onChange={handleChangeLabel}
            value={group.label}/>
        <Divider sx={{flexShrink: 0, width: "100%"}}/>
        {group.items && sortedItems(group.items).map((item, index) => {
            if (item.type === "repeater" || item.type === "section") {
                return <RenderGroup
                    key={item.uuid}
                    group={item}
                    item={item}
                    onChange={handleChange}
                    isFirst={index === 0}
                    isLast={index === group.items.length - 1}
                    onMove={handleMove}
                    onRemove={handleRemove}
                />;
            } else if (item.type === "select" || item.type === "multiselect") {
                return <RenderSelectField
                    key={item.uuid}
                    item={item}
                    onMove={handleMove}
                    onRemove={handleRemove}
                    isFirst={index === 0}
                    isLast={index === group.items.length - 1}
                    onChange={handleChange}
                />;
            } else {
                return <RenderTextField
                    key={item.uuid}
                    item={item}
                    onMove={handleMove}
                    onRemove={handleRemove}
                    isFirst={index === 0}
                    isLast={index === group.items.length - 1}
                    onChange={handleChange}
                />;

            }
        })
        }
        <RenderAddItem onAdd={handleAddField}/>
        {group.type === "repeater" && <>
            <FormControl sx={{mt: 2}}>
                <FormControlLabel
                    sx={{userSelect: "none"}}
                    label={"Obrigatório"}
                    control={<Checkbox name={"required"} checked={group.required} onChange={handleChangeGroup}/>}/>
            </FormControl>
            <FormControl sx={{mt: 2}}>
                <FormLabel>Mínimo de repetições:</FormLabel>
                <TextField value={group.min || ""} name={"min"} onChange={handleChangeGroup} size={"small"}/>
            </FormControl>
            <FormControl sx={{mt: 2}}>
                <FormLabel>Máximo de repetições:</FormLabel>
                <TextField value={group.max || ""} name={"max"} onChange={handleChangeGroup} size={"small"}/>
            </FormControl>
        </>
        }
        {<RenderReorder
            sx={{marginButtom: 5}} item={group.type === "page" ? group : item} isFirst={isFirst}
            isLast={isLast} onMove={group.type === "page" ? reorderGroup : onMove}
            onRemove={group.type === "page" ? removeGroup : onRemove}/>}
    </Box>;
}

function RenderReorder({item, isFirst, isLast, onMove, onRemove}) {
    const confirm = useConfirm();

    const handleDelete = () => {
        confirm(
            {
                description: "Tem certeza que deseja remover este campo?",
                confirmationText: "Excluir",
                cancellationText: "Cancelar",
                confirmationButtonProps: {color: "error"},
            }).then(async () => {
            onRemove && onRemove(item);
        }).catch(() => {
        });
    };
    const handleMoveUp = () => {
        onMove && onMove(item, -1);
    };
    const handleMoveDown = () => {
        onMove && onMove(item, 1);
    };

    return <FlexBox horizontal spaceBetween centerVertical sx={{mt: 1}}>
        {onRemove && <Button onClick={handleDelete} sx={{minWidth: "auto", pl: 0.5, pr: 0.5}}>
            <Delete color={"warning"}/>
        </Button>
        }
        <Box>
            {!isFirst && <Button
                variant={"text"} sx={{minWidth: "auto", pl: 0.5, pr: 0.5}}
                onClick={handleMoveUp}><ArrowUpwardRounded/></Button>}
            {!isLast && <Button
                variant={"text"} sx={{minWidth: "auto", pl: 0.5, pr: 0.5}}
                onClick={handleMoveDown}><ArrowDownwardRounded/></Button>}
        </Box>
    </FlexBox>;
}

function RenderTextField({item, isFirst, isLast, onChange, onMove, onRemove}) {
    const [isOpen, setOpen] = useState(false);
    const showDialog = useDialog();

    useEffect(() => {
        if (item.isOpen) {
            setOpen(item.isOpen);
        }
    }, [item]);

    const toggleOpen = () => {
        setOpen(!isOpen);
    };

    const handleClick = (event) => {
        event.stopPropagation();
    };


    const handleChange = (event) => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        const newItem = {
            ...item,
            [event.target.name]: value
        };

        if (newItem.readonly && newItem.required) {
            showDialog("Atenção!", "O campo não pode ser obrigatório e somente leitura.");
        } else {
            onChange && onChange(newItem);
        }
    };

    return <Box onClick={toggleOpen} key={item.uuid} className={styles.field}>
        <div className={styles.fieldTypeContainer}>
            <span className={styles.fieldType}>{formTypeMap[item.type]}</span>
        </div>
        <FlexBox hidden={isOpen} fullWidth>
            <span>{item.label}</span>
        </FlexBox>
        <FlexBox hidden={!isOpen} vertical fullWidth onClick={handleClick}>
            <FlexBox horizontal spaceBetween centerVertical>
                <FormControl>
                    <FormLabel>Nome do campo:</FormLabel>
                    <TextField size={"small"} value={item.label || ""} name={"label"} onChange={handleChange}/>
                    {item.type === "file" &&
                        <>
                            <FormLabel>Mínimo :</FormLabel>
                            <TextField value={item.min || ""} name={"min"} onChange={handleChange} size={"small"}/>
                        </>
                    }
                </FormControl>

                <FormControl>
                    <FormLabel>Dica para preenchimento:</FormLabel>
                    <TextField size={"small"} value={item.hint || ""} name="hint" onChange={handleChange}/>
                    {item.type === "file" &&
                        <>
                            <FormLabel>Máximo:</FormLabel>
                            <TextField value={item.max || ""} name={"max"} onChange={handleChange} size={"small"}/>
                        </>
                    }
                </FormControl>
            </FlexBox>


            {item.type === "text" &&
                <FlexBox horizontal spaceBetween centerVertical sx={{mt: 1}}>
                    <FormControl>
                        <FormLabel>Máscara de preenchimento:</FormLabel>
                        <TextField size={"small"} value={item.mask || ""} name={"mask"} onChange={handleChange}/>
                    </FormControl>
                </FlexBox>
            }

            <FlexBox horizontal spaceBetween sx={{mt: 1}}>
                <FormControl>
                    <FormControlLabel
                        sx={{userSelect: "none"}}
                        label={"Obrigatório"}
                        control={<Checkbox name={"required"} checked={item.required} onChange={handleChange}/>}/>
                </FormControl>
                <FormControl>
                    <FormControlLabel
                        sx={{userSelect: "none"}}
                        label="Somente leitura"
                        control={<Checkbox name={"readonly"} checked={item.readonly} onChange={handleChange}/>}/>
                </FormControl>
                <FormControl>
                    <FormControlLabel
                        sx={{userSelect: "none"}}
                        label="Campo filtrável"
                        control={<Checkbox name={"filterable"} checked={item.filterable} onChange={handleChange}/>}/>
                </FormControl>
            </FlexBox>
            <RenderReorder item={item} isFirst={isFirst} isLast={isLast} onMove={onMove} onRemove={onRemove}/>
        </FlexBox>
    </Box>;

}

function RenderItemOptions({options, onOptionChange, onOptionAdd, onOptionDelete}) {
    const [textFieldValue, setTextFieldValue] = useState({});
    const handleTextFieldChange = (uuid, newValue, originalValue) => {
        if (!newValue.trim().length) {
            setTextFieldValue(prevState => ({...prevState, [uuid]: originalValue}));
        } else {
            setTextFieldValue(prevState => ({...prevState, [uuid]: newValue}));
            onOptionChange(uuid, newValue);
        }
    };

    return (
        <>
            {options.map((option, index) => (
                <div key={option.uuid}>
                    <FlexBox horizontal spaceBetween fullWidth centerVertical sx={{mb: "5px"}}>
                        <FormLabel sx={{ml: "-11px", mr: "3px"}}>{index + 1}</FormLabel>
                        <TextField
                            fullWidth size={"small"}
                            value={textFieldValue[option.uuid] || option.description || `Opção ${index + 1}`}
                            name={"label"}
                            onBlur={(e) => handleTextFieldChange(option.uuid, e.target.value, `Opção ${index + 1}`)}
                            onChange={(e) => handleTextFieldChange(option.uuid, e.target.value, `Opção ${index + 1}`)}/>
                        <Button
                            sx={{minWidth: "auto", pl: 0.5, pr: 0.5}} color="error"
                            onClick={() => onOptionDelete(option.uuid)}>
                            <RemoveCircle/>
                        </Button>
                    </FlexBox>
                </div>
            ))}
            <Button size="small" onClick={onOptionAdd}>+ Nova opção</Button>
        </>
    );
}

function RenderSelectField({item, isFirst, isLast, onChange, onMove, onRemove}) {
    const [isOpen, setOpen] = useState(false);
    const showDialog = useDialog();

    const toggleOpen = () => {
        setOpen(!isOpen);
    };

    const handleClick = (event) => {
        event.stopPropagation();
    };

    const handleChange = (event) => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        const newItem = {
            ...item,
            [event.target.name]: value
        };

        if (newItem.readonly && newItem.required) {
            showDialog("Atenção!", "O campo não pode ser obrigatório e somente leitura.");
        } else {
            onChange && onChange(newItem);
        }
    };

    const initialOption = item.options && item.options.length > 0
        ? item.options
        : [];

    const [options, setOptions] = useState(initialOption);

    const handleOptionChange = (uuid, description) => {
        setOptions(options.map(option =>
            option.uuid === uuid ? {...option, description} : option
        ));
        const newItem = {
            ...item,
            options
        };
        onChange && onChange(newItem);
    };

    const handleOptionAdd = () => {
        const newOption = {
            uuid: uuidv4(),
            description: `Opção ${options.length + 1}`,
            order: options.length + 1
        };
        const newOptions = [...options, newOption];
        setOptions(newOptions);

        const newItem = {
            ...item,
            options: newOptions
        };
        onChange && onChange(newItem);
    };

    const handleOptionDelete = (uuid) => {
        setOptions(options.filter(option => option.uuid !== uuid));
    };

    return <Box onClick={toggleOpen} key={item.uuid} className={styles.field}>
        <div className={styles.fieldTypeContainer}>
            <span className={styles.fieldType}>{formTypeMap[item.type]}</span>
        </div>
        <FlexBox hidden={isOpen} fullWidth>
            <span>{item.label}</span>
        </FlexBox>
        <FlexBox hidden={!isOpen} vertical fullWidth onClick={handleClick}>
            <FlexBox horizontal spaceBetween centerVertical>
                <FormControl>
                    <FormLabel>Nome do campo:</FormLabel>
                    <TextField size={"small"} value={item.label || ""} name={"label"} onChange={handleChange}/>
                </FormControl>

                <FormControl>
                    <FormLabel>Dica para preenchimento:</FormLabel>
                    <TextField size={"small"} value={item.hint || ""} name="hint" onChange={handleChange}/>
                </FormControl>
            </FlexBox>

            <FlexBox vertical fullWidth spaceBetween sx={{mt: 1}}>
                <RenderItemOptions
                    options={options}
                    onOptionChange={handleOptionChange}
                    onOptionAdd={handleOptionAdd}
                    onOptionDelete={handleOptionDelete}
                />
            </FlexBox>

            <FlexBox horizontal spaceBetween sx={{mt: 1}}>
                <FormControl>
                    <FormControlLabel
                        sx={{userSelect: "none"}}
                        label={"Obrigatório"}
                        control={<Checkbox name={"required"} checked={item.required} onChange={handleChange}/>}/>
                </FormControl>
                <FormControl>
                    <FormControlLabel
                        sx={{userSelect: "none"}}
                        label="Somente leitura"
                        control={<Checkbox name={"readonly"} checked={item.readonly} onChange={handleChange}/>}/>
                </FormControl>
                <FlexBox horizontal centerVertical>
                    <FormControl>
                        <FormControlLabel
                            sx={{userSelect: "none"}}
                            label="Campo filtrável"
                            control={<Checkbox name={"filterable"} checked={item.filterable} onChange={handleChange}/>}/>

                    </FormControl>
                    <Tooltip
                        title={"Ao marcar este campo como filtrável, ele será utilizado na listagem de respostas como um campo para buscas."}
                        disableHoverListener={false}>
                        <InfoRounded color={"primary"} sx={{m: 1}}/>
                    </Tooltip>
                </FlexBox>
            </FlexBox>
            <FlexBox>

            </FlexBox>
            <RenderReorder item={item} isFirst={isFirst} isLast={isLast} onMove={onMove} onRemove={onRemove}/>
        </FlexBox>
    </Box>;

}

export default FormViewer;
