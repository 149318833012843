import React from "react";
import {FormControl, TextField} from "@mui/material";

import {InterpolableTextField} from "~/components";

export function RenderText({nameForKeyword, onChange, param, parameters, onFocus}) {
    const handleBlur = () => {
        onFocus(null);
    };

    const handleFocus = () => {
        if (param.interpolable) {
            onFocus(param);
        }
    };

    return <FormControl fullWidth>
        {param.interpolable && param.type !== "number" ?
            <InterpolableTextField
                nameForKeyword={nameForKeyword}
                onFocus={handleFocus}
                onBlur={handleBlur}
                multiline={param.type === "multiline_text"}
                onChange={onChange}
                value={parameters[param.key] || ""}
                name={param.key}
                label={param.label}
            />
            :
            <TextField
                onFocus={handleFocus}
                onBlur={handleBlur}
                name={param.key}
                multiline={param.type === "multiline_text"}
                minRows={5}
                label={param.label}
                size={"small"}
                onChange={onChange}
                value={parameters[param.key] || ""}
                type={param.type === "number" ? "number" : "text"}/>
        }
    </FormControl>;
}
