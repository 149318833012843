export function getValueFromKeyPath(keyPath, parent) {
    function getValueFromNextKeyPath(nextKeyPath, item) {
        const splitted = nextKeyPath.split(".");
        if (splitted.length > 1) {
            return getValueFromKeyPath(nextKeyPath, item);
        } else {
            return item[splitted[0]];
        }
    }

    const paths = keyPath.split(".");
    const currentKey = paths[0];
    const isArray = currentKey.endsWith("[]");

    if (isArray && paths.length < 2) {
        throw new Error("Invalid keyPath: " + keyPath);
    }

    const objectName = isArray
        ? currentKey.substring(0, currentKey.length - 2)
        : currentKey;
    let currentObject = parent[objectName];
    let nextKeyPath;
    if (isArray) {
        const uuid = paths[1];
        currentObject = currentObject.find((item) => item.uuid === uuid);
        nextKeyPath = paths.slice(2).join(".");
    } else {
        nextKeyPath = paths.slice(1).join(".");
    }
    return getValueFromNextKeyPath(nextKeyPath, currentObject);
}

export function returnObjectWithValueAtKeyPath(keyPath, parent, value) {
    function runNextKeyPath(nextKeyPath, item, currentKey, value) {
        if (nextKeyPath.length > 0) {
            return {
                ...item,
                ...returnObjectWithValueAtKeyPath(nextKeyPath, item, value),
            };
        } else {
            return {
                ...item,
                [currentKey]: value,
            };
        }
    }

    const paths = keyPath.split(".");
    const currentKey = paths[0];
    const isArray = currentKey.endsWith("[]");

    if (isArray && paths.length < 2) {
        console.error("Invalid keyPath: ", keyPath);
        throw new Error("Invalid keyPath: " + keyPath);
    }

    const objectName = isArray
        ? currentKey.substring(0, currentKey.length - 2)
        : currentKey;
    const currentObject = parent[objectName];

    if (isArray) {
        const uuid = paths[1];
        return currentObject.map((item) => {
            if (item.uuid === uuid) {
                const nextKeyPath = paths.slice(2).join(".");
                return runNextKeyPath(nextKeyPath, item, objectName, value);
            } else {
                return item;
            }
        });
    } else {
        const nextKeyPath = paths.slice(1).join(".");
        return runNextKeyPath(nextKeyPath, currentObject, objectName, value);
    }
}
