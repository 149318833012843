export function translateStatus(status) {
    switch (status) {
        case "finished":
            return "Finalizado";
        case "error":
            return "Erro";
        case "waiting":
            return "Aguardando";
        case "processing":
            return "Processando";
        default:
            return status;
    }
}

export function colorForStatus(status) {
    switch (status) {
        case "finished":
            return "#d3ebd3";
        case "error":
            return "#ffd7db";
        case "waiting":
            return "#f0f0f0";
        case "processing":
            return "#fffacf";
        default:
            return "#f0f0f0";
    }
}


export function orderWorkflowSteps(steps) {
    if (!steps || steps.length === 0) {
        return null;
    }

    let stepsMap = {};
    for (const step of steps) {
        stepsMap[step.uuid] = step;
    }

    function findNextSteps(previousStep) {
        const nextSteps = [];
        for (const nextStepLink of previousStep.nextStepsLinks) {
            const step = stepsMap[nextStepLink.destinationUuid];
            if (step) {
                delete stepsMap[nextStepLink.destinationUuid];
                step.order = previousStep.order + 1;
                nextSteps.push(step, ...findNextSteps(step));
            }
        }
        return nextSteps;
    }

    const linksCache = steps.map(step => step.nextStepsLinks).flat(3);
    const previousStep = steps.find((step) => linksCache.findIndex(obj => obj.destinationUuid === step.uuid) === -1);
    if (previousStep) {
        previousStep.order = 1;
        return [previousStep, ...findNextSteps(previousStep)];
    } else {
        return null;
    }
}
