import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {Visibility} from "@mui/icons-material";

import api from "~/api";
import {LoadingBar, Navbar, MainPage} from "~/components";
import {useDialog} from "~/providers/dialog";
import {formatDateTime} from "~/utils/date";

const FormResponseList = () => {
    const {uuid} = useParams();
    const [form, setForm] = useState(null);
    const [responses, setResponses] = useState([]);
    const showDialog = useDialog();
    useEffect(() => {
        const fetchForm = async () => {
            try {
                const data = await api.forms.detail(uuid);
                setForm(data);
            } catch (e) {
                showDialog("Não foi possível carregar o formulário.", e);
            }
        };
        const fetchResponses = async () => {
            try {
                const data = await api.forms.responses(uuid);
                setResponses(data);
            } catch (e) {
                showDialog("Não foi possível carregar as respostas.", e);
            }
        };
        fetchForm();
        fetchResponses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const listFilterable = (item) => {
        let items = [];
        if (item.items && item.items.length > 0) {
            for (const currentItem of item.items) {
                items.push(listFilterable(currentItem));
            }
        }
        if (item.filterable) {
            items.push(item);
        }
        return items;
    };

    const itemsToDisplay = form ? listFilterable(form).flat(100) : [];


    return <MainPage>
        {(form && responses) ? <>
            <Navbar title={form?.title}/>
            <TableContainer>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>

                            {form && itemsToDisplay.map((item) => <TableCell key={item.uuid}>{item.label}</TableCell>)}
                            <TableCell>Usuário</TableCell>
                            <TableCell>Data de criação</TableCell>
                            <TableCell align="right">Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {responses.map((response) => (
                            <TableRow
                                key={response.uuid}
                                sx={{"&:last-child td, &:last-child th": {border: 0}}}
                            >

                                {
                                    itemsToDisplay.map(item =>
                                        <TableCell key={item.uuid}>{response.answers.find(a => a.formItemUuid === item.uuid)?.value}</TableCell>
                                    )
                                }
                                <TableCell>{response.user.firstName}</TableCell>
                                <TableCell>{formatDateTime(response.createdAt)}</TableCell>
                                <TableCell align="right">
                                    <Button
                                        startIcon={<Visibility/>}
                                        component={Link}
                                        to={`/forms/${uuid}/responses/${response.uuid}`}
                                        variant="outlined">Detalhes</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


        </> : <LoadingBar/>}
    </MainPage>;
};
export default FormResponseList;