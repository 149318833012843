import React from "react";
import {Navbar, MainPage} from "~/components";

const Dashboard = () => {
    return <MainPage>
        <Navbar/>
        <h1>Dashboard</h1>
    </MainPage>;
};

export default Dashboard;