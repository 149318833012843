import React, { forwardRef } from "react";
import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {alpha, Card, CardContent, CardHeader, Typography} from "@mui/material";

// header style
const headerSX = {
    p: 2.5,
    "& .MuiCardHeader-action": { m: "0px auto", alignSelf: "center" }
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentSX = {},
            darkTitle,
            elevation,
            secondary,
            shadow,
            sx = {},
            title,
            ...others
        },
        ref
    ) => {

        const theme = useTheme();
        boxShadow = theme.palette.mode === "dark" ? boxShadow || true : boxShadow;

        return (
            <Card
                elevation={elevation || 0}
                ref={ref}
                {...others}
                sx={{
                    border: border ? "1px solid" : "none",
                    borderRadius: 2,
                    borderColor: theme.palette.mode === "dark" ? theme.palette.divider : theme.palette.grey.A800,
                    boxShadow: boxShadow && (!border || theme.palette.mode === "dark") ? shadow || `0px 2px 8px ${alpha(theme.palette.grey[900], 0.15)}` : "inherit",
                    ":hover": {
                        boxShadow: boxShadow ? shadow || `0px 2px 8px ${alpha(theme.palette.grey[900], 0.15)}` : "inherit"
                    },
                    "& pre": {
                        m: 0,
                        p: "16px !important",
                        fontFamily: theme.typography.fontFamily,
                        fontSize: "0.75rem"
                    },
                    ...sx
                }}
            >
                {/* card header and action */}
                {!darkTitle && title && (
                    <CardHeader sx={headerSX} titleTypographyProps={{ variant: "subtitle1" }} title={title} action={secondary} />
                )}
                {darkTitle && title && <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />}

                {/* card content */}
                {content && <CardContent sx={contentSX}>{children}</CardContent>}
                {!content && children}

            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    divider: PropTypes.bool,
    elevation: PropTypes.number,
    secondary: PropTypes.node,
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    content: PropTypes.bool,
    children: PropTypes.node
};

export default MainCard;
