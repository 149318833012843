const credentials = (apiClient, handleError) => {
    return {
        list: async () => {
            try {
                const {data} = await apiClient.get("/v1/credentials");
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        types: async () => {
            try {
                const {data} = await apiClient.get("/v1/credentials/types");
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        create: async (payload) => {
            try {
                const {data} = await apiClient.post("/v1/credentials", payload);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        detail: async (uuid) => {
            try {
                const {data} = await apiClient.get(`/v1/credentials/${uuid}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        update: async (uuid, payload) => {
            try {
                const {data} = await apiClient.put(`/v1/credentials/${uuid}`, payload);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
    };
};
export default credentials;