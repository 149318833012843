import React, {useCallback, useEffect, useRef, useState} from "react";
import NotchedOutline from "@mui/material/OutlinedInput/NotchedOutline";
import {FormControl, InputLabel, useFormControl} from "@mui/material";
import ContentEditable from "react-contenteditable";

import styles from "./InterpolableTextField.module.css";

const InterpolableTextField = ({multiline, nameForKeyword, onFocus, onBlur, onChange, label, name, value}) => {

    const handleFocus = (event) => {
        onFocus && onFocus(event);
    };

    const handleBlur = (event) => {
        onBlur && onBlur(event);
    };

    const handleChange = (text) => {
        onChange && onChange({
            target: {
                name: name,
                value: text
            }
        });
    };
    let style = !multiline ? {minHeight: "auto"} : undefined;


    return <div>
        <FormControl
            fullWidth
            className={styles.editableDivContainer}
            style={style}>
            <CustomNotchedOutline label={label}/>
            <InputLabel>{label}</InputLabel>
            <EditableDiv
                multiline={multiline}
                nameForKeyword={nameForKeyword}
                value={value}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}/>
        </FormControl>
    </div>;
};

function CustomNotchedOutline({label}) {
    const formControl = useFormControl();
    return <NotchedOutline
        className={`${styles.outline} ${formControl.focused ? styles.outlineFocused : ""}`}
        label={label}
        notched={Boolean(formControl.adornedStart || formControl.filled || formControl.focused)}/>;
}

function EditableDiv(
    {
        multiline,
        nameForKeyword,
        onFocus,
        onBlur,
        onChange,
        value
    }) {
    const formControl = useFormControl();

    const [inputValue, setInputValue] = useState("");
    const editableDiv = useRef();


    const parseHtmlToCheckKeywords = useCallback((text) => {
        if (text.startsWith("{{SCRIPT}}")) {
            return `<span class="${styles.chip}" contenteditable="false">Script</span>`;
        }
        let html = text;
        let matches = html.matchAll(/({{.[^{}]*?}})/g);
        let match = matches.next();

        let offset = 0;
        while (!match.done) {
            const matchedValue = match.value[0];
            const matchedIndex = match.value.index;
            if (matchedIndex <= 9 || text.substring(matchedIndex - 9, matchedIndex - 2) !== "x-field") {
                let cleared = nameForKeyword(matchedValue.replace(/(^{{)|(}}$)/g, ""));
                const newValue = `<span x-field="${matchedValue}" class="${styles.chip}" contenteditable="false"> ${cleared}</span>`;
                html = html.substring(0, matchedIndex + offset) + newValue + html.substring(matchedIndex + offset + matchedValue.length);
                offset += newValue.length - matchedValue.length;
            }
            match = matches.next();
        }
        return html;
    }, [nameForKeyword]);

    const handleFocus = (event) => {
        formControl.onFocus(event);
        onFocus && onFocus(event);

    };

    const handleBlur = (event) => {
        if (event.relatedTarget?.getAttribute("name") === "insertButton" ||
            event.relatedTarget?.getAttribute("name") === "dynamicItem" ||
            event.relatedTarget?.getAttribute("name") === "dynamicPanel") {
            event.target.focus();
        } else {
            formControl.onBlur(event);
            onBlur && onBlur(event);
        }
    };

    const handleChange = (event) => {
        const htmlOriginal = event.target.value;
        if (value.startsWith("{{SCRIPT}}")) {
            return;
        }
        const html = parseHtmlToCheckKeywords(htmlOriginal);
        setInputValue(html);

        const el = document.createElement("div");
        el.innerHTML = html;
        for (const child of el.children) {
            if (child.hasAttribute("x-field")) {
                child.innerText = child.getAttribute("x-field");
            }
        }
        el.innerHTML = el.innerHTML.replaceAll("<br>", "\n");
        onChange && onChange(el.innerText);
    };

    const handleKeyDown = (event) => {
        if (multiline === false && event.key === "Enter") {
            event.stopPropagation();
            event.preventDefault();
            return true;
        }
        return false;
    };


    useEffect(() => {
        const html = parseHtmlToCheckKeywords(value.replaceAll("\n", "<br>"));
        setInputValue(html);
    }, [parseHtmlToCheckKeywords, value]);

    useEffect(() => {
        if (formControl) {
            if (inputValue?.length > 0) {
                formControl.onFilled();
            } else {
                formControl.onEmpty();
            }
        }
    }, [inputValue, formControl]);

    return <ContentEditable
        innerRef={editableDiv}
        className={styles.editableDiv}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        html={inputValue}
        disabled={false}
        onChange={handleChange}
        tagName={"span"}
    />;
}

export default InterpolableTextField;