import {
    WidgetsRounded,
    TuneRounded,
    FeedRounded,
    ForkRightRounded,
    CheckCircleRounded,
    CloudRounded,
    FormatAlignJustifyRounded,
    PictureAsPdfRounded,
    GridOnRounded,
    PlaylistAddCircleRounded,
    PlaylistAddCheckCircleRounded,
    MailRounded,
    PlaylistAddCheckCircle,
    PlaylistAddCircle,
    AltRoute, Update, Launch, AccessTime,
} from "@mui/icons-material";
import WK from "./images/wk.png";
import React, {createElement} from "react";
import {Icon} from "@mui/material";


const Icons = {
    WidgetsRounded,
    TuneRounded,
    FeedRounded,
    ForkRightRounded,
    CheckCircleRounded,
    CloudRounded,
    FormatAlignJustifyRounded,
    PictureAsPdfRounded,
    MailRounded,
    GridOnRounded,
    PlaylistAddCircleRounded,
    PlaylistAddCheckCircleRounded,
    PlaylistAddCheckCircle,
    PlaylistAddCircle,
    AltRoute,
    Update,
    Launch,
    AccessTime
};

const Images = {
    WK
};

export function hasIconFromName(name) {
    return (Icons[name + "Rounded"] || Icons[name] || Images[name]);
}
export function iconFromName(name) {
    if (Icons[name + "Rounded"]) {
        return createElement(Icons[name + "Rounded"]);
    } else  if (Icons[name]) {
        return createElement(Icons[name]);
    }  else  if (Images[name]) {
        return <img src={Images[name]} style={{maxWidth: "92%", filter: "brightness(0) invert(1)"}} alt={name}/>;
    } else {
        return <Icon/>;
    }
}
export default Icons;







