import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader, CircularProgress,
    Container,
    Divider, Stack,
    TextField
} from "@mui/material";

import api from "~/api";
import {Navbar, MainPage ,TransferList} from "~/components";
import {useDialog, useToast} from "~/providers/dialog";

const GroupNew = () => {
    const [infos, setInfos] = useState({
        name: "",
        users: [],
    });

    const [users, setUsers] = useState(null);

    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const showDialog = useDialog();
    const showToast = useToast();

    const navigate = useNavigate();

    useEffect(() => {
        const fetchGroups = async () => {
            setLoading(true);
            try {
                const data = await api.users.list();
                setUsers(data);
            } catch (e) {
                showDialog("Não foi possível carregar os usuários.", e);
            }
            setLoading(false);
        };
        fetchGroups().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setInfos({
            ...infos,
            [name]: value
        });
    };

    const handleSave = (e) => {
        e.preventDefault();
        if (loadingSave) {
            return;
        }

        if (!infos.name || infos.name.length === 0) {
            showDialog("Atenção", "O nome não foi preenchida.");
        } else {
            (async () => {
                setLoadingSave(true);
                try {
                    await api.groups.create(infos);
                    navigate(`/groups/`);
                    showToast("Grupo salvo com sucesso!");
                } catch (e) {
                    showDialog("Não foi possível salvar o grupo.", e);
                }
                setLoadingSave(false);
            })();
        }
    };

    const handleUsersChange = (selectedUsers) => {
        setInfos((infos) => {
            return {
                ...infos,
                users: selectedUsers || []
            };
        });
    };

    return <MainPage>
        <Navbar title="Novo Grupo"/>
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="lg">
                <Stack spacing={3}>
                    <form onSubmit={handleSave}>
                        <Card>
                            <CardHeader title="Informações do novo grupo"/>
                            <Divider/>
                            <CardContent>
                                <Stack
                                    spacing={3}
                                    sx={{maxWidth: 400}}>
                                    <TextField
                                        fullWidth
                                        label="Nome"
                                        name="name"
                                        type="text"
                                        required
                                        onChange={handleChange}
                                        value={infos.name}
                                    />


                                </Stack>
                            </CardContent>
                            <CardHeader title="Usuários" sx={{mt: 4}}/>
                            <Divider/>
                            <CardContent>
                                <Stack
                                    sx={{justifyContent: "flex-start", mt: 2}}>
                                    {loading && <CircularProgress sx={{ml: 1, flexShrink: 0, margin: "0 auto"}} size={30}/>}
                                    <TransferList
                                        options={users} onSelectionChanged={handleUsersChange}
                                        renderItem={(item) => `${item.firstName} ${item.lastName}`}/>
                                </Stack>
                            </CardContent>
                            <Divider/>
                            <CardActions sx={{justifyContent: "flex-end"}}>
                                <Button disabled={loadingSave} variant="contained" type="submit">
                                    {!loadingSave && "Salvar"}
                                    {loadingSave && <>
                                        Salvando...
                                        <CircularProgress size={20} sx={{ml: 2}}/>
                                    </>}
                                </Button>
                            </CardActions>
                        </Card>
                    </form>

                </Stack>
            </Container>
        </Box>
    </MainPage>;
};

export default GroupNew;