const datasets = (apiClient, handleError) => {
    return {
        list: async () => {
            try {
                const {data} = await apiClient.get("/v1/datasets");
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        detail: async (uuid) => {
            try {
                const {data} = await apiClient.get(`/v1/datasets/${uuid}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        create: async (dataset) => {
            try {
                const {data} = await apiClient.post("/v1/datasets", dataset);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        clear: async (uuid) => {
            try {
                const {data} = await apiClient.delete(`/v1/datasets/${uuid}/data`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        remove: async (uuid) => {
            try {
                await apiClient.delete(`/v1/datasets/${uuid}`);
            } catch (error) {
                handleError(error);
            }
        },
    };
};
export default datasets;