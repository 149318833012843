import {Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {DialogContext} from "~/providers/dialog";

const DialogHandler = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const {dialogInfo, clearDialog} = useContext(DialogContext);

    const [dialogCache, setDialogCache] = useState(null);

    const handleCloseDialog = () => {
        setDialogOpen(false);
        clearDialog();
    };

    useEffect(() => {
        if (dialogInfo) {
            setDialogCache(dialogInfo);
            setDialogOpen(true);
        } else {
            setDialogOpen(false);
        }
    }, [dialogInfo]);


    const {toastInfo} = useContext(DialogContext);

    const [snackVisible, setSnackVisible] = useState(false);


    useEffect(() => {
        if (toastInfo) {
            setSnackVisible(true);
        }
    }, [toastInfo]);

    const handleClose = () => {
        setSnackVisible(false);
    };


    const anchor = {vertical: "top", horizontal: "right"};


    let description = dialogCache ? dialogCache.description : "";
    if (description.message) {
        description = description.message;
    }

    return <>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>
                {dialogCache?.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description?.split("\n").map((text, i) => <Typography component={"span"} key={i}>{text}<br/></Typography>)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCloseDialog}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>

        <Snackbar open={snackVisible} autoHideDuration={4000} anchorOrigin={anchor} onClose={handleClose}>
            <Alert onClick={handleClose} severity={toastInfo?.severity || "success"}>{toastInfo?.message}</Alert>
        </Snackbar>
    </>;
};
export default DialogHandler;