import React from "react";
import {Box} from "@mui/material";

const FlexBox = (
    {
        fullWidth,
        horizontal,
        vertical,
        centerVertical,
        centerHorizontal,
        spaceBetween,
        spaceAround,
        flexEnd,
        children,
        className,
        noShrink,
        grow,
        hidden,
        sx,
        onClick
    }) => {

    let justifyContent = ((vertical && centerVertical) || (horizontal && centerHorizontal)) ? "center" : undefined;
    let alignItems = ((vertical && centerHorizontal) || (horizontal && centerVertical)) ? "center" : undefined;

    if (spaceBetween) {
        justifyContent = "space-between";
    } else if (spaceAround) {
        justifyContent = "space-around";
    } else if (flexEnd) {
        justifyContent = "flex-end";
    }

    const finalSx = {
        ...(sx || {}),
        display: "flex",
        flexDirection: horizontal ? "row" : "column",
        justifyContent: justifyContent,
        alignItems: alignItems,
        width: fullWidth ? "100%" : undefined,
        flexShrink: noShrink ? "0" : undefined,
        flexGrow: grow ? "1" : undefined,
    };
    return <>
        {!hidden && <Box sx={finalSx} className={className} onClick={onClick}>{children}</Box>}
    </>;
};

export default FlexBox;
