import React, {useEffect} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Box, createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {ConfirmProvider} from "material-ui-confirm";

import "moment/locale/pt-br";

import Sidebar from "./components/Sidebar";
import DialogHandler from "./components/DialogHandler";
import ProtectedRoute from "./components/ProtectedRoute";

import {DialogProvider} from "./providers/dialog";
import {UserProvider} from "./providers/user/userContext";

import Login from "./pages/Login";
import Recover from "./pages/Recover";
import Dashboard from "./pages/Dashboard";

import FormsEdit from "./pages/Forms/Edit";
import FormResponseList from "./pages/Forms/Responses/List";
import FormResponseDetail from "./pages/Forms/Responses/Detail";

import PendingTasks from "./pages/PendingTasks";

import WorkflowNew from "./pages/Workflow/New";
import WorkflowFlow from "./pages/Workflow/Flow";
import WorkflowDetail from "./pages/Workflow/Detail";

import WorkflowExecution from "./pages/Workflow/Execution";
import WorkflowExecutionDetail from "./pages/Workflow/ExecutionDetail";

import UserList from "./pages/User/List";
import UserNew from "./pages/User/New";
import UserEdit from "./pages/User/Edit";

import GroupList from "./pages/Group/List";
import GroupNew from "./pages/Group/New";
import GroupEdit from "./pages/Group/Edit";

import DatasetDetail from "./pages/Dataset/Detail";
import DatasetNew from "./pages/Dataset/New";

import CredentialsNew from "./pages/Credentials/New";
import CredentialsList from "./pages/Credentials/List";
import CredentialsEdit from "./pages/Credentials/Edit";

import CompanyList from "./pages/Company/List";
import CompanyEdit from "./pages/Company/Edit";
import CompanyNew from "./pages/Company/New";
import {useFileUpload} from "./providers/fileupload.hook";

function App() {
    const theme = createTheme({
        palette: {
            white: {
                main: "#FFFFFF",
            },
            yellow: {
                main: "#FFAA00",
            },
        },
    });

    useFileUpload();


    return (
        <LocalizationProvider adapterLocale="pt-br" dateAdapter={AdapterMoment}>
            <DialogProvider>
                <UserProvider>
                    <ConfirmProvider defaultOptions={{title: "Confirmação"}}>
                        <BrowserRouter>
                            <ThemeProvider theme={theme}>
                                <Box sx={{display: "flex"}}>
                                    <DialogHandler/>
                                    <CssBaseline/>
                                    <Sidebar/>
                                    <Routes>
                                        <Route path="/login" element={<Login/>}/>
                                        <Route path="/recover" element={<Recover/>}/>
                                        <Route
                                            path="/"
                                            element={<ProtectedRoute element={<Dashboard/>}/>}
                                        />

                                        <Route
                                            path="/workflows/new"
                                            element={<ProtectedRoute element={<WorkflowNew/>}/>}
                                        />
                                        <Route
                                            path="/workflows/:uuid"
                                            element={
                                                <ProtectedRoute element={<WorkflowDetail/>}/>
                                            }
                                        />
                                        <Route
                                            path="/workflows/:uuid/flow"
                                            element={<ProtectedRoute element={<WorkflowFlow/>}/>}
                                        />
                                        <Route
                                            path="/workflows/:workflowUuid/results/:executionUuid"
                                            element={
                                                <ProtectedRoute element={<WorkflowExecutionDetail/>}/>
                                            }
                                        />

                                        <Route
                                            path="/workflows/:workflowUuid/executions"
                                            element={
                                                <ProtectedRoute element={<WorkflowExecution/>}/>
                                            }
                                        />
                                        <Route
                                            path="/workflows/:workflowUuid/executions/:workflowExecutionUuid/steps/:executionStepUuid"
                                            element={
                                                <ProtectedRoute element={<WorkflowExecution/>}/>
                                            }
                                        />

                                        <Route
                                            path="/forms/:uuid/edit"
                                            element={<ProtectedRoute element={<FormsEdit/>}/>}
                                        />
                                        <Route
                                            path="/forms/:uuid/responses"
                                            element={
                                                <ProtectedRoute element={<FormResponseList/>}/>
                                            }
                                        />
                                        <Route
                                            path="/forms/:formUuid/responses/:responseUuid"
                                            element={
                                                <ProtectedRoute element={<FormResponseDetail/>}/>
                                            }
                                        />

                                        <Route
                                            path="/pendingTasks"
                                            element={<ProtectedRoute element={<PendingTasks/>}/>}
                                        />

                                        <Route
                                            path="/users"
                                            element={<ProtectedRoute element={<UserList/>}/>}
                                        />
                                        <Route
                                            path="/users/new"
                                            element={<ProtectedRoute element={<UserNew/>}/>}
                                        />
                                        <Route
                                            path="/users/:uuid"
                                            element={<ProtectedRoute element={<UserEdit/>}/>}
                                        />

                                        <Route
                                            path="/groups"
                                            element={<ProtectedRoute element={<GroupList/>}/>}
                                        />
                                        <Route
                                            path="/groups/new"
                                            element={<ProtectedRoute element={<GroupNew/>}/>}
                                        />
                                        <Route
                                            path="/groups/:uuid"
                                            element={<ProtectedRoute element={<GroupEdit/>}/>}
                                        />

                                        <Route
                                            path="/companies"
                                            element={<ProtectedRoute element={<CompanyList/>}/>}
                                        />
                                        <Route
                                            path="/companies/new"
                                            element={<ProtectedRoute element={<CompanyNew/>}/>}
                                        />
                                        <Route
                                            path="/companies/:uuid"
                                            element={<ProtectedRoute element={<CompanyEdit/>}/>}
                                        />

                                        <Route
                                            path="/datasets/new"
                                            element={<ProtectedRoute element={<DatasetNew/>}/>}
                                        />
                                        <Route
                                            path="/datasets/:uuid"
                                            element={<ProtectedRoute element={<DatasetDetail/>}/>}
                                        />

                                        <Route
                                            path="/credentials"
                                            element={<ProtectedRoute element={<CredentialsList/>}/>}
                                        />
                                        <Route
                                            path="/credentials/new"
                                            element={<ProtectedRoute element={<CredentialsNew/>}/>}
                                        />
                                        <Route
                                            path="/credentials/:uuid"
                                            element={<ProtectedRoute element={<CredentialsEdit/>}/>}
                                        />
                                    </Routes>
                                </Box>
                            </ThemeProvider>
                        </BrowserRouter>
                    </ConfirmProvider>
                </UserProvider>
            </DialogProvider>
        </LocalizationProvider>
    );
}

export default App;
