import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";

import api from "~/api";
import {MainPage,Navbar} from "~/components";
import {useDialog, useToast} from "~/providers/dialog";

const UserNew = () => {
    const [infos, setInfos] = useState({
        firstName: "",
        lastName: "",
        email: "",
        access_level: "",
        groups: [],
        password: "",
        password_confirm: ""
    });

    const [groups, setGroups] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const showDialog = useDialog();
    const showToast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGroups = async () => {
            setLoading(true);
            try {
                const data = await api.groups.list();
                setGroups(data);
            } catch (e) {
                showDialog("Não foi possível carregar os grupos.", e);
            }
            setLoading(false);
        };
        fetchGroups().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setInfos({
            ...infos,
            [name]: value
        });
    };

    const handleSave = (e) => {
        e.preventDefault();
        if (loadingSave) {
            return;
        }

        if (infos.password !== infos.password_confirm) {
            showDialog("Atenção", "As senhas não conferem.");
        } else if (!infos.password || infos.password.length === 0) {
            showDialog("Atenção", "A senha não foi preenchida.");
        } else {
            (async () => {
                setLoadingSave(true);
                try {
                    const user = await api.users.create(infos);
                    navigate(`/users/${user.uuid}`);
                    showToast("Usuário salvo com sucesso!");
                } catch (e) {
                    showDialog("Não foi possível salvar o usuário.", e);
                }
                setLoadingSave(false);
            })();
        }
    };

    return <MainPage>
        <Navbar title="Novo Usuário"/>
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="lg">
                <Stack spacing={3}>
                    <Card>
                        <CardHeader
                            title="Informações do novo usuário"
                        />
                        <Divider/>
                        <form onSubmit={handleSave}>
                            <CardContent>
                                <Stack
                                    spacing={3}
                                    sx={{maxWidth: 400}}
                                >
                                    <TextField
                                        fullWidth
                                        label="Nome"
                                        name="firstName"
                                        type="text"
                                        required
                                        onChange={handleChange}
                                        value={infos.firstName}
                                    />
                                    <TextField
                                        fullWidth
                                        required
                                        label="Sobrenome"
                                        name="lastName"
                                        type="text"
                                        onChange={handleChange}
                                        value={infos.lastName}
                                    />
                                    <TextField
                                        fullWidth
                                        required
                                        label="E-mail"
                                        name="email"
                                        type="email"
                                        onChange={handleChange}
                                        value={infos.email}
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel required>Nível de acesso</InputLabel>
                                        <Select required label="Nível de acesso" name="access_level" value={infos.access_level} onChange={handleChange}>
                                            <MenuItem value="company_admin">Administrador</MenuItem>
                                            <MenuItem value="user">Usuário</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Box sx={{display: "flex", alignItems: "center"}}>

                                        <FormControl fullWidth>
                                            <InputLabel>Grupos</InputLabel>
                                            <Select multiple label="Grupos" name="groups" value={infos.groups} onChange={handleChange}>
                                                {groups?.map((group) => <MenuItem key={group.uuid} value={group.uuid}>{group.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        {loading && <CircularProgress sx={{ml: 1, flexShrink: 0}} size={30}/>}
                                    </Box>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Senha"
                                        name="password"
                                        type="password"
                                        onChange={handleChange}
                                        value={infos.password}
                                    />
                                    <TextField
                                        required
                                        fullWidth
                                        label="Confirme a senha"
                                        name="password_confirm"
                                        type="password"
                                        onChange={handleChange}
                                        value={infos.password_confirm}
                                    />
                                </Stack>
                            </CardContent>
                            <Divider/>
                            <CardActions sx={{justifyContent: "flex-end"}}>
                                <Button disabled={loadingSave} variant="contained" type="submit">
                                    {!loadingSave && "Salvar"}
                                    {loadingSave && <>
                                        Salvando...
                                        <CircularProgress size={20} sx={{ml: 2}}/>
                                    </>}
                                </Button>
                            </CardActions>
                        </form>
                    </Card>
                </Stack>
            </Container>
        </Box>
    </MainPage>;
};

export default UserNew;