import React, {createContext, useCallback, useContext, useState} from "react";

export const DialogContext = createContext();
export const DialogProvider = ({children}) => {
    const [dialogInfo, setDialogInfo] = useState(null);

    const showDialog = useCallback((title, description) => {
        setDialogInfo({
            title,
            description
        });
    }, []);
    const clearDialog = () => {
        setDialogInfo(null);
    };


    const [toastInfo, setToastInfo] = useState(null);

    const showToast = (message, severity) => {
        setToastInfo({
            message,
            severity
        });
    };

    return <DialogContext.Provider value={{dialogInfo, showDialog, clearDialog, toastInfo, showToast}}>
        {children}
    </DialogContext.Provider>;
};

export function useDialog() {
    const {showDialog} = useContext(DialogContext);
    return showDialog;
}

export function useToast() {
    const {showToast} = useContext(DialogContext);
    return showToast;
}
