import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from "@mui/material";

import {AddRounded, Visibility} from "@mui/icons-material";

import api from "~/api";
import {Navbar, LoadingBar, MainPage} from "~/components";
import {useDialog} from "~/providers/dialog";
import {formatDateTime} from "~/utils/date";

const CredentialsList = () => {
    const [credentials, setCredentials] = useState([]);
    const [loading, setLoading] = useState(false);
    const showDialog = useDialog();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const data = await api.credentials.list();
                setCredentials(data);
            } catch (e) {
                showDialog("Não foi possível obter as credentials.", e);
            }
            setLoading(false);
        };
        fetchUsers().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <MainPage>
        {
            loading ? <LoadingBar/> :
                <>
                    <Navbar title="Credenciais">
                        <Button component={Link} to={"/credentials/new"} variant={"outlined"} color={"white"}>
                            <AddRounded sx={{mr: 1}}/>
                            Nova credencial
                        </Button>
                    </Navbar>
                    <TableContainer>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Data de criação</TableCell>
                                    <TableCell align="right">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {credentials.map((credentials) => (
                                    <TableRow
                                        key={credentials.uuid}
                                        sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                    >
                                        <TableCell>{credentials.name}</TableCell>
                                        <TableCell>{credentials.type}</TableCell>
                                        <TableCell>{formatDateTime(credentials.createdAt)}</TableCell>
                                        <TableCell align="right">
                                            <Button startIcon={<Visibility/>} component={Link} to={`/credentials/${credentials.uuid}`} variant="outlined">Editar</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
        }</MainPage>;
};
export default CredentialsList;