import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

export function RenderSelect({onChange, param, parameters}) {
    const multiple = (param.type === "select_multiple");
    const value = multiple ? [...(parameters[param.key] || [])] : parameters[param.key] || "";
    return (
        <FormControl fullWidth size={"small"}>
            <InputLabel>{param.label}</InputLabel>
            <Select multiple={multiple} label={param.label} name={param.key} value={value} onChange={onChange}>
                {param.options.map(option => <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>)}
            </Select>
        </FormControl>);
}