import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useConfirm} from "material-ui-confirm";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";

import api from "~/api";
import {MainPage, Navbar} from "~/components";
import {useDialog, useToast} from "~/providers/dialog";
import useUser from "~/providers/user/useUser";

const UserEdit = () => {
    const {uuid} = useParams();
    const [infos, setInfos] = useState({
        firstName: "",
        lastName: "",
        email: "",
        access_level: "",
        groups: [],
        password: "",
        password_confirm: ""
    });

    const [groups, setGroups] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [me] = useUser();
    const showDialog = useDialog();
    const showToast = useToast();
    const navigate = useNavigate();
    const confirm = useConfirm();
    const isAdmin = me.is_superadmin || me.is_companyadmin;
    useEffect(() => {
        if (uuid) {
            const fetchInfos = async () => {
                setLoading(true);
                try {
                    if (isAdmin) {
                        const groups = await api.groups.list();
                        setGroups(groups);
                    }
                    const user = await api.users.detail(uuid);
                    setInfos(user);
                } catch (e) {
                    showDialog("Não foi possível carregar as informações.", e);
                }
                setLoading(false);
            };
            fetchInfos().then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setInfos({
            ...infos,
            [name]: value
        });
    };

    const handleSave = (e) => {
        e.preventDefault();
        if (loadingSave) {
            return;
        }

        if (infos.password !== infos.password_confirm) {
            showDialog("Atenção", "As senhas não conferem.");
        } else {
            (async () => {
                setLoadingSave(true);
                try {
                    await api.users.update(infos.uuid, infos);
                    isAdmin && navigate("/users");
                    showToast("Usuário salvo com sucesso!");
                } catch (e) {
                    showDialog("Não foi possível salvar o usuário.", e);
                }
                setLoadingSave(false);
            })();
        }
    };

    const handleRemoveUser = () => {
        confirm({
            description: "Tem certeza que deseja remover este usuário?",
            confirmationText: "Excluir",
            cancellationText: "Cancelar",
            confirmationButtonProps: {color: "error"},
        }).then(async () => {
            try {
                await api.users.remove(uuid);
                showToast("Usuário removido com sucesso!");
                navigate("/users");
            } catch (e) {
                showDialog("Não foi possível remover o usuário.", e);
            }
        }).catch(() => {
        });
    };

    return <MainPage>
        <Navbar title="Editar Usuário"/>
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="lg">
                <Stack spacing={3}>
                    <Card>
                        <CardHeader
                            title="Informações do usuário"
                        />
                        <Divider/>
                        <form onSubmit={handleSave}>
                            <CardContent>
                                <Stack
                                    spacing={3}
                                    sx={{maxWidth: 400}}
                                >
                                    <TextField
                                        fullWidth
                                        label="Nome"
                                        name="firstName"
                                        type="text"
                                        required
                                        onChange={handleChange}
                                        value={infos.firstName}
                                    />
                                    <TextField
                                        fullWidth
                                        required
                                        label="Sobrenome"
                                        name="lastName"
                                        type="text"
                                        onChange={handleChange}
                                        value={infos.lastName}
                                    />
                                    {isAdmin &&
                                        <>
                                            <TextField
                                                fullWidth
                                                required
                                                label="E-mail"
                                                name="email"
                                                type="email"
                                                onChange={handleChange}
                                                value={infos.email}
                                            />
                                            <FormControl fullWidth>
                                                <InputLabel required>Nível de acesso</InputLabel>

                                                <Select required label="Nível de acesso" name="access_level" value={infos.access_level} onChange={handleChange}>
                                                    <MenuItem value="company_admin">Administrador</MenuItem>
                                                    <MenuItem value="user">Usuário</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Box sx={{display: "flex", alignItems: "center"}}>

                                                <FormControl fullWidth>
                                                    <InputLabel>Grupos</InputLabel>
                                                    <Select multiple label="Grupos" name="groups" value={infos.groups} onChange={handleChange}>
                                                        {groups?.map((group) => <MenuItem key={group.uuid} value={group.uuid}>{group.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                                {loading && <CircularProgress sx={{ml: 1, flexShrink: 0}} size={30}/>}
                                            </Box>
                                        </>}
                                    <TextField
                                        fullWidth
                                        label="Senha"
                                        name="password"
                                        type="password"
                                        onChange={handleChange}
                                        value={infos.password}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Confirme a senha"
                                        name="password_confirm"
                                        type="password"
                                        onChange={handleChange}
                                        value={infos.password_confirm}
                                    />
                                </Stack>
                            </CardContent>
                            <Divider/>
                            <CardActions sx={{justifyContent: "space-between"}}>
                                {isAdmin &&
                                    <Button disabled={loadingSave} onClick={handleRemoveUser} variant="contained" color={"error"}>
                                        Remover usuário
                                    </Button>}
                                <Button disabled={loadingSave} variant="contained" type="submit">
                                    {!loadingSave && "Salvar"}
                                    {loadingSave && <>
                                        Salvando...
                                        <CircularProgress size={20} sx={{ml: 2}}/>
                                    </>}
                                </Button>
                            </CardActions>
                        </form>
                    </Card>
                </Stack>
            </Container>
        </Box>
    </MainPage>;
};

export default UserEdit;