const companies = (apiClient, handleError) => {
    return {
        list: async () => {
            try {
                const {data} = await apiClient.get("/v1/companies");
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        create: async (company) => {
            try {
                const {data} = await apiClient.post("/v1/companies", company);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        detail: async (uuid) => {
            try {
                const {data} = await apiClient.get(`/v1/companies/${uuid}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        update: async (uuid, company) => {
            try {
                const {data} = await apiClient.put(`/v1/companies/${uuid}`, company);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        remove: async (uuid) => {
            try {
                const {data} = await apiClient.delete(`/v1/companies/${uuid}`);
                return data;
            } catch (error) {
                handleError(error);
            }
        },
    };
};
export default companies;