import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import api from "~/api";
import {Navbar, LoadingBar, MainPage} from "~/components";
import {useDialog} from "~/providers/dialog";

import styles from "./formResponseDetail.module.css";

const FormResponseDetail = () => {
    const {formUuid, responseUuid} = useParams();
    const [form, setForm] = useState(null);
    const [response, setResponse] = useState(null);
    const showDialog = useDialog();

    useEffect(() => {
        const fetchForm = async () => {
            try {
                const data = await api.forms.detail(formUuid);
                setForm(data);
            } catch (e) {
                showDialog("Não foi possível carregar o formulário.", e);
            }
        };
        const fetchResponse = async () => {
            try {
                const data = await api.forms.responseDetail(formUuid, responseUuid);
                setResponse(data);
            } catch (e) {
                showDialog("Não foi possível carregar as respostas.", e);
            }
        };
        fetchForm();
        fetchResponse();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formUuid, responseUuid]);

    const renderItems = (items, level) => {
        return items.map(item => {
            return <div key={item.uuid} className={`${styles.inside} level-${level}`}>
                <p>{item.label}:
                    <span>{response.answers.filter((a) => a.formItemUuid === item.uuid)[0]?.value}</span>
                </p>
                {item.items && renderItems(item.items, level + 1)}
            </div>;
        });
    };

    return <MainPage>
        {
            (form && response) ? <>
                <Navbar title={form?.title}/>
                <p>Usuário: {response.user.firstName}</p>
                <p>Criado em: {response.createdAt}</p>
                {renderItems(form.items, 0)}
            </>
                : <LoadingBar/>
        }
    </MainPage>;
};
export default FormResponseDetail;