import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {Card, CardContent, Divider, Typography} from "@mui/material";
import {RenderItem} from "./index";

export function RenderSection({item, infos, onChange}) {
    const [internalInfos, setInternalInfos] = useState(null);
    useEffect(() => {
        if (item) {
            if (infos[item.uuid]) {
                setInternalInfos(infos[item.uuid] || {});
            } else {
                let itemDef = {};
                for (const field of item.items) {
                    itemDef[field.uuid] = field.default || "";
                }
                setInternalInfos({...itemDef});
            }
        }
    }, [infos, item]);

    const handleChange = (event) => {
        const {name, value} = event.target;
        const newInfos = {
            ...internalInfos,
            [name]: value
        };
        onChange({
            target: {
                name: item.uuid,
                value: newInfos
            }
        });
    };


    return <Grid item xs={12}>
        {internalInfos && <Card sx={{mt: 3}}>
            <CardContent>
                <Typography variant={"h5"}>{item.label}</Typography>
                <Divider sx={{mt: 1, mb: 1}}/>
                <Grid container columnSpacing={3} rowSpacing={3}>
                    {item.items.map(insideItem => <RenderItem key={insideItem.uuid} item={insideItem} infos={internalInfos} onChange={handleChange}/>)}
                </Grid>
            </CardContent>
        </Card>}
    </Grid>;
}
