import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Divider,
    Stack,
    TextField
} from "@mui/material";

import api from "~/api";
import {MainPage, Navbar} from "~/components";
import {useDialog, useToast} from "~/providers/dialog";
import {useSetSidebarWorkflowsAtom} from "~/providers/sidebar-workflow.atom";

const WorkflowNew = () => {
    const [infos, setInfos] = useState({
        title: "",
        description: "",
    });
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingImport, setLoadingImport] = useState(false);

    const setSidebarWorkflow = useSetSidebarWorkflowsAtom();
    const showDialog = useDialog();
    const showToast = useToast();
    const navigate = useNavigate();

    const handleChange = (event) => {
        const {name, value} = event.target;
        setInfos({
            ...infos,
            [name]: value
        });
    };

    const handleSave = (e) => {
        e.preventDefault();
        if (loadingSave) {
            return;
        }

        if (!infos.title) {
            showDialog("Atenção", "O nome do workflow é obrigatório.");
        } else {
            (async () => {
                setLoadingSave(true);
                try {
                    const data = await api.workflows.create(infos);
                    navigate(`/workflows/${data.uuid}`);
                    showToast("Workflow salvo com sucesso!");
                } catch (e) {
                    showDialog("Não foi possível salvar o workflow.", e);
                }
                setLoadingSave(false);
            })();
        }
    };

    const handleImport = async () => {
        //Open dialog to select json file
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".json";
        input.onchange = async (event) => {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = async (event) => {
                try {
                    const json = JSON.parse(event.target.result);
                    if (json.voxitVersion === "1.0" && json.voxitType === "workflow") {
                        setLoadingImport(true);
                        const data = await api.workflows.import(json);
                        setSidebarWorkflow((workflow) => [...workflow, data]);
                        navigate(`/workflows/${data.uuid}`);
                        showToast("Workflow importado com sucesso!");
                    } else {
                        showDialog("Erro ao importar o workflow.", "O arquivo selecionado é inválido.");
                    }
                } catch (e) {
                    showDialog("Erro ao importar o workflow", e);
                }
                setLoadingImport(false);
            };
            reader.readAsText(file);
        };
        input.click();
    };

    return <MainPage>
        <Navbar title="Novo Workflow">
            <Button disabled={loadingImport || loadingSave} sx={{ml: 1}} onClick={handleImport} variant={"contained"} color={"info"}>
                Importar {loadingImport ? <CircularProgress sx={{color: "#FFFFFF", ml: 2}} size={30}/> : ""}
            </Button>
        </Navbar>
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="lg">
                <Stack spacing={3}>
                    <Card>
                        <CardHeader
                            title="Informações do novo workflow"
                        />
                        <Divider/>
                        <form onSubmit={handleSave}>
                            <CardContent>
                                <Stack
                                    spacing={3}
                                    sx={{maxWidth: 400}}
                                >
                                    <TextField
                                        fullWidth
                                        label="Nome"
                                        name="title"
                                        type="text"
                                        required
                                        onChange={handleChange}
                                        value={infos.title}
                                    />
                                    <TextField
                                        fullWidth
                                        multiline={true}
                                        maxRows={5}
                                        minRows={5}
                                        label="Descrição"
                                        name="description"
                                        type="text"
                                        onChange={handleChange}
                                        value={infos.description}
                                    />
                                </Stack>
                            </CardContent>
                            <Divider/>
                            <CardActions sx={{justifyContent: "flex-end"}}>
                                <Button disabled={loadingImport || loadingSave} variant="contained" type="submit">
                                    {!loadingSave && "Salvar"}
                                    {loadingSave && <>
                                        Salvando...
                                        <CircularProgress size={20} sx={{ml: 2}}/>
                                    </>}
                                </Button>
                            </CardActions>
                        </form>
                    </Card>
                </Stack>
            </Container>
        </Box>
    </MainPage>;
};

export default WorkflowNew;