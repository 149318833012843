import React from "react";
import Grid from "@mui/material/Grid";
import {RenderSection} from "./RenderSection";
import {RenderRepeater} from "./RenderRepeater";
import {RenderSignature} from "./RenderSignature";
import {RenderText} from "./RenderText";
import {RenderTextarea} from "./RenderTextArea";
import {RenderNumber} from "./RenderNumber";
import {RenderSelect} from "./RenderSelect";
import {RenderSelectMultiple} from "./RenderSelectMultiple";
import {RenderDate} from "./RenderDate";
import {RenderFile} from "./RenderFile";

export const defaultFieldSx = {width: "100%", maxWidth: "400px"};

function RenderGrid({children}) {
    return <Grid item xs={12} sm={6} md={4}>{children}</Grid>;
}

export function RenderItem({item, infos, onChange}) {
    if (item.type === "text" || item.type === "time") {
        return <RenderGrid><RenderText item={item} infos={infos} onChange={onChange}/></RenderGrid>;
    }
    if (item.type === "date") {
        return <RenderGrid><RenderDate item={item} infos={infos} onChange={onChange}/></RenderGrid>;
    }
    if (item.type === "textarea") {
        return <RenderGrid><RenderTextarea item={item} infos={infos} onChange={onChange}/></RenderGrid>;
    }
    if (item.type === "number") {
        return <RenderGrid><RenderNumber item={item} infos={infos} onChange={onChange}/></RenderGrid>;
    }
    if (item.type === "select") {
        return <RenderGrid><RenderSelect item={item} infos={infos} onChange={onChange}/></RenderGrid>;
    }
    if (item.type === "multiselect") {
        return <RenderGrid><RenderSelectMultiple item={item} infos={infos} onChange={onChange}/></RenderGrid>;
    }
    if (item.type === "signature") {
        return <RenderGrid><RenderSignature item={item} infos={infos} onChange={onChange}/></RenderGrid>;
    }
    if (item.type === "repeater") {
        return <RenderRepeater key={item.uuid} item={item} infos={infos} onChange={onChange}/>;
    }
    if (item.type === "section" && item.items?.length > 0) {
        return <RenderSection key={item.uuid} item={item} infos={infos} onChange={onChange}/>;
    }
    if (item.type === "file" ) {
        return <RenderGrid><RenderFile item={item} infos={infos} onChange={onChange}/></RenderGrid>;
    }
    console.error("RenderItem: unknown item type", item);

}