const users = (apiClient, handleError) => {
    return {
        login: async (username, password) => {
            try {
                const {data} = await apiClient.post("/v1/auth", {
                    username,
                    password
                });
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        refresh: async (refreshToken) => {
            const {data} = await apiClient.post("/v1/auth/refresh", {
                refreshToken: refreshToken
            });
            return data;
        },
        recover: async (username) => {
            try {
                const {data} = await apiClient.post("/v1/auth/recover", {
                    username
                });
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        changePassword: async(password, token) => {
            try{
                const {data} = await apiClient.post("/v1/auth/recover/changePassword", {
                    password, token
                });
                return data;
            }catch(error){
                handleError(error);
            }
        }
    };
};
export default users;